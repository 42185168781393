import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit } from '@angular/core';
import { EventSidenavController } from '@shared/modules/event-sidenav/controllers/event-sidenav.controller';
import { CalendarEvent } from '@shared/modules/event-sidenav/models/calendar-event.model';
import { CalendarApiService } from '@modules/calendar/shared/services/calendar-api.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '@core/services/snackbar.service';
import { EParticipantStatus } from '@shared/modules/event-sidenav/enums/participant-status.enum';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { CalendarService } from '@modules/calendar/shared/services/calendar.service';
import { ECalendarAction } from '@modules/calendar/shared/enums/calendar-action.enum';

@Component({
  selector: 'notification-event',
  templateUrl: './notification-event.component.html',
  styleUrls: ['./notification-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationEventComponent implements OnInit {
  EParticipantStatus = EParticipantStatus;
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  text: string;
  event: CalendarEvent;
  status: EParticipantStatus = EParticipantStatus.STATUS_UNKNOWN;
  markAsReadEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  loading: boolean = false;

  constructor(
    private calendarApiService: CalendarApiService,
    private t: TranslateService,
    private s: SnackBarService,
    private changes: ChangeDetectorRef,
    private calendarService: CalendarService,
  ) {
  }

  ngOnInit(): void {
  }

  accept() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.calendarApiService.acceptEvent(this.event.id).subscribe({
      next: () => {
        this.status = EParticipantStatus.STATUS_ATTENDANCE_CONFIRMED;
        this.s.success(this.t.instant('Calendar.eventAccepted'));
        this.calendarService.subCalendarCtrl.calendarEmmiter.emit({ action: ECalendarAction.UPDATE_VIEW });
        this.changes.detectChanges();
      },
      error: () => {
        this.s.error(this.t.instant('Calendar.eventAcceptDeclinedError'));
      }
    }).add(() => {
      this.loading = false;
    });
    this.markAsReadEmitter.emit(true);
  }


  decline() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.calendarApiService.declineEvent(this.event.id).subscribe({
      next: () => {
        this.status = EParticipantStatus.STATUS_ATTENDANCE_REJECTED;
        this.s.success(this.t.instant('Calendar.eventDeclined'));
        this.calendarService.subCalendarCtrl.calendarEmmiter.emit({ action: ECalendarAction.UPDATE_VIEW });
        this.changes.detectChanges();
      },
      error: () => {
        this.s.error(this.t.instant('Calendar.eventAcceptDeclinedError'));
      }
    }).add(() => {
      this.loading = false;
    });
    this.markAsReadEmitter.emit(true);
  }

  preview() {
    const eventSidenavController = new EventSidenavController();
    eventSidenavController.openPreview(this.event.id);
    this.markAsReadEmitter.emit(true);
  }
}
