import { EventEmitter, Injector } from '@angular/core';
import { debounceTime, distinctUntilChanged, startWith, switchMap, tap, map } from 'rxjs/operators';
import { Subscription, combineLatest, Observable } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { CalendarApiService } from '../services/calendar-api.service';
import { AppInjector } from '@shared/services/app-injector.service';
import { PersonalCalendars } from '../models/personal-calendars.model';
import { Router, ActivatedRoute } from '@angular/router';
import { CalendarStorageService } from '../services/calendar-storage.service';

export enum ELeftCalendars {
  EMPLOYEES = 'EMPLOYEES',
  CALENDARS = 'CALENDARS',
}

export class CalendarLeftSectionController {
  readonly search: FormControl = new FormControl(null);

  private sub: Subscription;
  private formSub: Subscription;

  injector: Injector;
  router: Router;
  active: ActivatedRoute;
  apiService: CalendarApiService;
  calendarStorage: CalendarStorageService;

  firstLoad: boolean = true;
  personalCalendars: PersonalCalendars = new PersonalCalendars(); //create stub

  form: FormGroup = this.defaultFormState;

  get defaultFormState() {
    return new FormGroup({
      [ELeftCalendars.EMPLOYEES]: new FormGroup({}),
      [ELeftCalendars.CALENDARS]: new FormGroup({}),
    });
  }

  get emploeesForm(): FormGroup {
    return this.form.get(ELeftCalendars.EMPLOYEES) as FormGroup;
  }

  get myCalendarsForm(): FormGroup {
    return this.form.get(ELeftCalendars.CALENDARS) as FormGroup;
  }

  get isUrlParamsEmpty() {
    return !this.currentUrlParams?.calendars?.length && !this.currentUrlParams?.employees?.length;
  }

  get currentUrlParams() {
    let urlParams = Object.assign({}, this.active.snapshot.queryParams);
    urlParams['calendars'] = urlParams['calendars'] ? (JSON.parse(urlParams['calendars']) as number[]) : [];
    urlParams['employees'] = urlParams['employees'] ? (JSON.parse(urlParams['employees']) as number[]) : [];
    return urlParams;
  }

  get calendarsObservables() {
    return combineLatest([this.emploeesForm.valueChanges, this.myCalendarsForm.valueChanges]);
  }

  constructor() {
    this.initializeInjectors();
  }

  private initializeInjectors() {
    this.injector = AppInjector.getInjector();
    this.router = this.injector.get(Router);
    this.active = this.injector.get(ActivatedRoute);
    this.apiService = this.injector.get(CalendarApiService);
    this.calendarStorage = this.injector.get(CalendarStorageService);
  }

  initializeDefaultViewState() {
    this.firstLoad = true;
    if (
      !this.calendarStorage?.selectedCalendars?.length &&
      !this.calendarStorage?.selectedEmployeeCalendars?.length
    ) {
      this.form = this.defaultFormState;
    }

    this.initSearch();
  }

  subFormChanges() {
    this.formSub?.unsubscribe();
    this.formSub = this.calendarsObservables.subscribe((value) => this.pushHistoryState());
  }

  getCurrentCalendars(text, getFromStorage: boolean = false): Observable<PersonalCalendars> {
    return this.apiService.getCalendars(text === null ? '' : text).pipe(tap((i) => this.succesCalendars(i, getFromStorage)));
  }

  /**
   *Initialize search
   *subscribe to event changes
   * @memberof CalendarLeftSectionController
   */
  initSearch() {
    const stream$ = this.search.valueChanges
      .pipe(startWith(''), debounceTime(500), distinctUntilChanged())
      .pipe(switchMap((text: string) => this.getCurrentCalendars(text)));
    this.sub = stream$.subscribe();
  }

  succesCalendars(i, getFromStorage: boolean = false) {
    this.subFormChanges();
    this.personalCalendars = i;

    let selectedCalendars = [];
    let selectedEmployeeCalendars = [];

    if (this.isUrlParamsEmpty) {
      selectedCalendars = [...this.calendarStorage.selectedCalendars];
      selectedEmployeeCalendars = [...this.calendarStorage.selectedEmployeeCalendars];
    }

    this.personalCalendars.employees.forEach((i) => this.addControl(this.emploeesForm, i.id, false));
    this.personalCalendars.myCalendars.forEach((i) => this.addControl(this.myCalendarsForm, i.id, false));

    if (this.firstLoad) {
      //1. load data from Url Params
      //2. if there is no data get from storage

      this.isUrlParamsEmpty
        ? this.loadFromCalendarStorage(selectedCalendars, selectedEmployeeCalendars)
        : this.loadFromUrlParams();

      this.setCalendarsFromStorage();
      this.firstLoad = false;
    }

    if (!!getFromStorage) {
      this.loadFromCalendarStorage(
        this.calendarStorage.selectedCalendars,
        this.calendarStorage.selectedEmployeeCalendars,
      );
      this.setCalendarsFromStorage();
    }
  }

  setDefaultFormValues() {
    this.form = this.defaultFormState;
  }

  deleteControl(id: string) {
    this.myCalendarsForm.removeControl(id);
  }

  setCalendarsFromStorage() {
    //3. Set default calendars if storage calendars are not set
    if (!this.calendarStorage.isSelectedCalendarsSet) {
      this.setCalendarsSelected();
    }

    //4. Update values in storage
    this.emploeesForm.updateValueAndValidity();
    this.myCalendarsForm.updateValueAndValidity();
  }

  setCalendarsSelected() {
    this.loadFromSingleSource(Object.keys(this.myCalendarsForm.controls), this.myCalendarsForm);
  }

  loadFromUrlParams() {
    this.loadFromSingleSource(this.currentUrlParams?.calendars, this.myCalendarsForm);
    this.loadFromSingleSource(this.currentUrlParams?.employees, this.emploeesForm);
  }

  loadFromCalendarStorage(selectedCalendars, selectedEmployeeCalendars) {
    this.loadFromSingleSource(selectedCalendars, this.myCalendarsForm);
    this.loadFromSingleSource(selectedEmployeeCalendars, this.emploeesForm);
  }

  loadFromSingleSource(source: string[], group: FormGroup) {
    if (!source.length) return;

    Object.keys(group.controls).forEach((key: string) => {
      const control = group.get(key.toString());
      const index = (source || []).indexOf(key);
      control.setValue(index > -1, { onlySelf: true });
    });
  }

  //#region Reseting View
  resetViewState() {
    this.deleteSubscribers();
    this.resetFormValues();
    this.personalCalendars = new PersonalCalendars();
  }

  resetFormValues() {
    Object.keys(this.emploeesForm.controls).forEach((key: string) =>
      this.emploeesForm.get(key).setValue(false, { onlySelf: true }),
    );

    Object.keys(this.myCalendarsForm.controls).forEach((key: string) =>
      this.myCalendarsForm.get(key).setValue(false, { onlySelf: true }),
    );
  }

  deleteSubscribers() {
    this.sub?.unsubscribe();
    this.formSub?.unsubscribe();
  }
  //#endregion

  addControl(group: FormGroup, id: number, value: boolean) {
    const control = new FormControl(value);
    control.updateValueAndValidity({ onlySelf: true });
    group.addControl(id.toString(), control);
  }

  pushHistoryState() {
    const calendars = this.getFormIds(this.myCalendarsForm.value);
    const employees = this.getFormIds(this.emploeesForm.value);

    this.calendarStorage.updateSelectedCalendars(calendars, employees);

    let queryParams = {
      calendars: JSON.stringify(calendars),
      employees: JSON.stringify(employees),
    };

    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
      preserveFragment: true,
      replaceUrl: true,
    });
  }

  getFormIds(values: object): string[] {
    let ids = [];
    Object.keys(values).forEach((key) => (!!values[key] ? ids.push(String(key)) : null));
    return ids;
  }
}
