import { debounce } from '@shared/decorators/debounce.decorator';
import { BaseComponent } from '../base.component';
import { DefaultListConfig } from '@shared/modules/list/configs/default-list.config';
import { ListConfig } from '@shared/modules/list/interfaces/list-config.interface';
import { AfterViewInit, ChangeDetectorRef, Component, HostListener, NgZone, ViewChild } from '@angular/core';
import { CustomTableColumn } from '@shared/modules/list/interfaces/custom-table-column.interface';
import { ProtocolType } from '@shared/enums/protocol-type.enum';
import { WindowHelper } from '@shared/helpers/window.helper';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { ListComponent } from '@shared/modules/list/list.component';
import { cloneDeep } from 'lodash';
import { ListService } from '@shared/modules/list/services/list.service';
import { ListEvent, ListEventType } from '@shared/modules/list/model/list-event.model';
import { AppInjector } from '@shared/services/app-injector.service';

enum DeviceType {
  mobile = 'mobile',
  desktop = 'desktop',
}
@Component({ template: '' })
export class BaseListComponent extends BaseComponent implements AfterViewInit {
  @ViewChild('list') public list: ListComponent;
  DeviceType = DeviceType;

  config: ListConfig;
  initColumns: CustomTableColumn[];
  activeType: ProtocolType;
  selected: any[] = [];
  zone: NgZone;
  inited: boolean = false;
  lastWindowWidth: number = window.innerWidth;
  lastState: string = WindowHelper.isMobileWidth ? DeviceType.mobile : DeviceType.desktop;

  resizedTimerScroll: any = null;

  @HostListener('window:resize')
  @debounce(200)
  resize() {
    if (this.lastWindowWidth !== window.innerWidth) {
      this.lastWindowWidth = window.innerWidth;
      this.toggleColumnsOnMobile();
      this.toggleIsHorizontalScroll();
    }
  }

  constructor(public changes: ChangeDetectorRef, public listService: ListService) {
    super();
    this.zone = AppInjector.getInjector().get(NgZone);
  }

  ngAfterViewInit() {
    this.setCustomComponents();
    this.listenListEvents();
    this.toggleIsHorizontalScroll();
  }

  initListConfig(listConfig: ListConfig) {
    this.config = cloneDeep(DefaultListConfig);
    this.config = Object.assign(this.config, listConfig);

    this.config.columns =
      this.config?.columns?.map((i) => {
        i.desktopWidth = i.width;
        return i;
      }) || [];

    const columns = [...this.config.columns.slice()];
    this.initColumns = columns;
    this.configColumns(true);
  }

  /**
   * Let's configure columns base on mobile or desktop version of table
   *
   * @memberof BaseListComponent
   */
  configColumns(init?: boolean): void {
    this.zone.runOutsideAngular(() => {
      if (this.lastState === DeviceType.mobile) {
        this.setMobileRowHeight();
        this.setMobileVirtualization();
        this.configColumnsForMobile();
      } else {
        this.setDesktopRowHeight();
        this.setDesktopVirtualization();
        this.configColumnsForDesktop(init);
      }
    });
  }

  configColumnsForMobile(): void {
    const filteredColumns: CustomTableColumn[] = [];
    const filteredHideColumns: CustomTableColumn[] = [];
    const concatedColumns = this.config.columns.concat(this.config.hideColumns || []);
    for (let i = 0; i < concatedColumns.length; i++) {
      const column = concatedColumns[i];
      if (!column.hideOnMobile && !column.hide) {
        column.name = column.mobileName
          ? this.t.instant(column.mobileName)
          : column.name
          ? this.t.instant(column.name)
          : null;
        column.mobileWidth ? (column.width = column.mobileWidth) : null;
        column.notShow ? filteredHideColumns.push(column) : filteredColumns.push(column);
      }
    }
    this.config.columns = [...filteredColumns];
    this.config.hideColumns = [...filteredHideColumns];
    this.config.columnMode = ColumnMode.force;
    if (this.list) {
      this.list.table.columnMode = this.config.columnMode;
      this.list?.table?.recalculate();
    }
  }

  configColumnsForDesktop(init: boolean): void {
    const filteredColumns: CustomTableColumn[] = [];
    const filteredHideColumns: CustomTableColumn[] = [];
    const concatedColumns = this.config.columns.concat(this.config.hideColumns || []);
    for (let i = 0; i < concatedColumns.length; i++) {
      let column = concatedColumns[i];
      if (!column.hide) {
        column.name ? (column.name = this.t.instant(column.name)) : null;
        !init ? (column = this.setCustomComponent(column)) : null;
        column?.desktopWidth ? (column.width = column?.desktopWidth) : null;
        column.notShow ? filteredHideColumns.push(column) : filteredColumns.push(column);
      }
    }
    this.config.columns = [...filteredColumns.sort(this.columnsSort)];
    this.config.hideColumns = [...filteredHideColumns];
    this.config.columnMode = ColumnMode.standard;
    if (this.list) {
      this.list.table.columnMode = this.config.columnMode;
      this.list?.table?.recalculate();
    }
  }

  columnsSort(a: CustomTableColumn, b: CustomTableColumn) {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }

  setMobileVirtualization() {
    this.config.virtualization = false;
  }

  setDesktopVirtualization() {
    this.config.virtualization = true;
  }

  setMobileRowHeight() {
    this.config.rowHeight = 'auto';
  }

  setDesktopRowHeight() {
    if (this.config.desktopRowHeight) {
      this.config.rowHeight = this.config.desktopRowHeight;
    } else {
      this.config.rowHeight = 60;
    }
  }

  toggleIsHorizontalScroll() {
    if (this.list && this.list?.listContainer) {
      if (this.resizedTimerScroll) {
        clearTimeout(this.resizedTimerScroll);
        this.resizedTimerScroll = null;
      }

      this.resizedTimerScroll = setTimeout(() => {
        const container = this.list?.listContainer?.nativeElement.querySelector('.datatable-body');
        if (container?.scrollWidth > container?.clientWidth) {
          this.list.listContainer.nativeElement
            .querySelector('.ngx-datatable')
            .classList.add('is-scroll-horz');
        } else {
          this.list?.listContainer?.nativeElement
            .querySelector('.ngx-datatable')
            .classList.remove('is-scroll-horz');
        }
      }, 1000);
    }
  }

  /**
   * Method to take care of change columns for mobile and desktop version of table
   *
   * @returns
   * @memberof BaseListComponent
   */
  toggleColumnsOnMobile(): void {
    const currentState = WindowHelper.isMobileWidth ? DeviceType.mobile : DeviceType.desktop;
    if (this.lastState === currentState) {
      return;
    }
    this.lastState = currentState;
    const columns = [...this.initColumns.slice()];
    this.config.columns = columns;
    this.updateColumns();
  }

  updateColumns() {
    this.configColumns();
    if (this.list && this.list.service) {
      this.list.service.rows = [...this.list.service.rows];
      this.inited = false;
      setTimeout(() => {
        this.inited = true;
        this.changes?.detectChanges();
        this.list?.changes?.detectChanges();
      }, 600);
    }
  }

  /**
   * Method to put custom components for cells
   *
   * @memberof BaseListComponent
   */
  setCustomComponents(): void {
    for (let i = 0; i < this.config.columns.length; i++) {
      this.config.columns[i] = this.setCustomComponent(this.config.columns[i]);
    }
    this.inited = true;
    this.changes.detectChanges();
  }

  setCustomComponent(c: CustomTableColumn): CustomTableColumn {
    if (c.componentRef && this[c.componentRef] && !c.toggleMenu) {
      c.cellTemplate = this[c.componentRef].template;
    }

    if (c.headerCellComponentRef && this[c.headerCellComponentRef] && !c.toggleMenu) {
      c.headerTemplate = this[c.headerCellComponentRef].template;
    }

    if (c.toggleMenu && c.toggleMenuRef && this[c.toggleMenuRef].template) {
      c.toggleMenuTemplate = this[c.toggleMenuRef].template;
    }
    return c;
  }

  listenListEvents() {
    const sub = this.listService.eventEmitter.subscribe((e: ListEvent<any>) => {
      switch (e.type) {
        case ListEventType.CONFIG_COLUMNS:
          this.configColumns();
          break;
      }
    });
    this.sub.add(sub);
  }
}
