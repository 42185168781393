import { Protocol } from '../models/protocol';

export class ProtocolListRecord {
  protocol: Protocol;
  allTaskCount?: number;
  completedTaskCount?: number;
  treeStatus?: string;
  accessToEdit?: boolean;
  accessToPreview?: boolean;
  accessToAcceptance?: boolean;
  accessToSignature?: boolean;
  accessToRemove?: boolean;
  expanded?: boolean;


  constructor(data?: any) {
    data ? Object.assign(this, data) : '';
    this.protocol ? this.protocol = new Protocol(this.protocol) : '';
  }
}
