import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ListTaskEvent, ListTaskEventType, ListTaskService } from '@shared/modules/list/services/list-task.service';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseListFiltersComponentComponent } from '@shared/components/base-list-filters-component/base-list-filters-component.component';
import { Subscription } from 'rxjs';
import { ListSingleFilterSelectComponent } from '@shared/modules/list/components/list-filter/components/list-single-filter-select/list-single-filter-select.component';
import { ListService } from '@shared/modules/list/services/list.service';
import { TaskStatus } from '@shared/enums/task-status.enum';
import { Employee } from '@shared/models/employee.model';
import { Config } from '@shared/configs/config';
import { Project } from '@modules/projects/shared/models/project.model';
import { TaskType } from '@shared/enums/task-type.enum';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'my-work-filters',
  templateUrl: './my-work-filters.component.html',
  styleUrls: ['./my-work-filters.component.scss']
})
export class MyWorkFiltersComponent extends BaseListFiltersComponentComponent implements OnInit, OnDestroy {
  Config = Config;

  taskStatuses: any[] = [];
  taskTypes: any[] = [];
  isDelegated: boolean = false;
  sub: Subscription = new Subscription();

  @ViewChild('tagFilter') tagFilter: ListSingleFilterSelectComponent;

  constructor(public service: ListService, private listTaskService: ListTaskService, private route: ActivatedRoute, private changes: ChangeDetectorRef) {
    super(service);
  }


  ngOnInit(): void {
    this.createForm();
    this.setTaskStatuses();
    this.setTaskTypes();
    this.listenListTaskEvents();
    this.listenDelegatedTask();
  }

  listenDelegatedTask() {
    this.sub.add(this.route.queryParams.subscribe((params) => {
      this.isDelegated = params?.delegatedTasks === '1';
      this.changes.detectChanges();
    }));
  }

  listenListTaskEvents() {
    this.sub.add(this.listTaskService.emitter.subscribe((event: ListTaskEvent) => {
      switch (event.type) {
        case ListTaskEventType.ADD_TASK:
        case ListTaskEventType.UPDATE_TASK:
          this.reloadFilters();
          break;
      }
    }));
  }

  reloadFilters() {
    this.tagFilter.select.getItems();
  }

  createForm() {
    this.service.filtersForm = new FormGroup({
      '[creator][eq]': new FormControl(null),
      '[a-status][in][]': new FormControl(null),
      '[a-type][eq]': new FormControl(null),
      '[tag-name][in][]': new FormControl(null),
      '[a-project][in][]': new FormControl(null),
      '[show_completed]': new FormControl(null),
      '[showUnassigned]': new FormControl(null)
    });
  }


  setTaskStatuses() {
    this.taskStatuses = Object.values(TaskStatus).map((status) => {
      return {
        id: status,
        name: this.t.instant('Projects.TaskStatus.' + status),
      };
    });
  }

  setTaskTypes() {
    this.taskTypes = Object.values(TaskType).filter(val => val !== TaskType.TYPE_COMPANY_TASK && val !== TaskType.TYPE_CLIENT_TASK).map((type) => {
      return {
        id: type,
        name: this.t.instant('Tasks.TaskType.' + type),
      };
    });
  }

  projectTransform(project: Project) {
    return project.name;
  }

  onChangeFilter() {
    Object.keys(this.service.filtersForm.value).forEach((key: string) => {
      let value = this.service.filtersForm.value[key];
      Array.isArray(value) && !value.length ? value = null : null;
      if (key === '[deleted]') {
        value = value ? 1 : 0;
      }
      if (key === '[show_completed]') {
        value = value ? 1 : 0;
      }
      if (key === '[showUnassigned]') {
        value = value ? 1 : 0;
      }
      this.service.setFilter(key, value);
    });

    const statusesFiltrValue = this.service.filtersForm.get('[a-status][in][]').value;
    if (statusesFiltrValue && statusesFiltrValue.indexOf(TaskStatus.COMPLETED) !== -1) {
      this.service.setFilter('[show_completed]', 1);
    }

    this.service.getRows();
  }

  creatorName(row: Employee | { id: string, userPerson }) {
    if (typeof row === 'object' && row.userPerson) {
      const e = new Employee(row);
      return e.userPerson.previewName;
    }
    return row.id; //show email if it's only assigned email not employee
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    super.ngOnDestroy();
  }
}
