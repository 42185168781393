import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NavigateService } from '@shared/services/navigate.service';
import { Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'help-faq-content',
  templateUrl: './help-faq-content.component.html',
  styleUrls: ['./help-faq-content.component.scss']
})
export class HelpFaqContentComponent implements OnInit, OnDestroy {

  faqContentType: FaqContentType = FaqContentType.GLOBAL;
  sub: Subscription;

  constructor(private router: Router, private route: ActivatedRoute, private n: NavigateService) {
    this.listenChangeTab();
  }

  ngOnInit(): void {
  }

  listenChangeTab() {
    this.sub = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        mergeMap((route) => route.data),
      )
      .subscribe((routeData) => {
        this.faqContentType = routeData.faqContentType;
      });
  }

  replaceTags(content: string) {
    const host = `${window.location.protocol}//${window.location.host}`;
    content = content.replace(/{{sign-up-path}}/g, host + this.n.getPath('register'));
    content = content.replace(/{{forgot-pass-path}}/g, host + this.n.getPath('reset'));
    content = content.replace(/{{settings-team-path}}/g, host + this.n.getPath('team-settings'));
    content = content.replace(/{{protocols-list-path}}/g, host + this.n.getPath('protocols-list-all'));
    return content;
  }

  ngOnDestroy() {
    this.sub ? this.sub.unsubscribe() : null;
  }

}

export enum FaqContentType {
  GLOBAL = 'global',
  ACCOUNT = 'account',
  PROTOCOLS = 'protocols'
}
