<div class="settings settings-content">
  <content-title
    [title]="'Settings.User.title' | translate"
    [subtitle]="'Settings.User.yourPosition' | translate: { position: 'ROLES.' + employee.role | translate }"
  >
  </content-title>
  <h2 class="blue-500 settings-content-subtitle" [innerHTML]="'Settings.User.yourData' | translate"></h2>
  <div class="settings-content-section-forms">
    <user-base-data [form]="form"></user-base-data>
    <hr />
    <user-password></user-password>
    <hr />
    <user-language [form]="form"></user-language>
    <hr />
    <user-privacy></user-privacy>
    <hr />
    <user-tours></user-tours>

    <!-- <div class="text-right mt-4">
      <button class="btn btn-primary ml-md-1 btn-sm-block" (click)="submit()" [disabled]="loading">
        <span
          *ngIf="loading"
          class="spinner-border spinner-border-sm mr-2"
          role="status"
          aria-hidden="true"
        ></span>
        <img draggable="false"
          *ngIf="!loading"
          src="./assets/img/ic-arrow-right.svg"
          [alt]="'Settings.User.saveUserData' | translate"
        />
        {{ 'Settings.User.saveUserData' | translate }}
      </button>
    </div> -->
  </div>
</div>
