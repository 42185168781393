import {CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';
import {StorageService} from '@core/services/storage.service';
import {NavigateService} from '@shared/services/navigate.service';
import {AppTourTypes} from '@shared/modules/app-tour/shared/enums/app-tour-types.enum';
import {AppTourService} from '@shared/modules/app-tour/shared/services/app-tour.service';

@Injectable({ providedIn: 'root' })
export class ProtocolsGuard implements CanActivate {
  constructor(private store: StorageService, private n: NavigateService, private appTourService: AppTourService) {}

  canActivate() {
    if (!this.store?.UserPerson?.firstName || !this.store?.UserPerson?.lastName) {
      this.n.navigate('protocols-no-data');
      return false;
    }

    if (this.store.Employee.isEmployeeOwner && !this.store.Company.name) {
      this.n.navigate('protocols-no-data');
      return false;
    }

    if(!this.store.UserPerson.shownTutorials || !this.store.UserPerson.shownTutorials[AppTourTypes.PROTOCOLS_LIST])
    {
      this.appTourService.initTour(AppTourTypes.PROTOCOLS_LIST);
    }

    return true;
  }
}
