import { Component, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppTourService } from '@shared/modules/app-tour/shared/services/app-tour.service';

@Component({
  templateUrl: './protocols-panel-manage.component.html',
  styleUrls: ['./protocols-panel-manage.component.scss'],
})
export class ProtocolsPanelManageComponent implements AfterViewInit {
  constructor(private route: ActivatedRoute, private appTourService: AppTourService) {}

  ngAfterViewInit() {}
}
