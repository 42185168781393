<form
  [formGroup]="form"
  class="w-100 mb-2"
  [ngStyle]="{ 'min-height': employeeSelect.select.isOpen ? '200px' : 'initial' }"
>
  <ng-select
    #employeeSelect="ngSelectExtensionDir"
    ngSelectExtension
    [url]="URL"
    [initialValue]="initialValue"
    [ignoreLocalSearch]="true"
    [searchGetParam]="'search'"
    [dropdownPosition]="'bottom'"
    class="select select-default w-100"
    [clearAllText]="'clearAll' | translate"
    [addTagText]="'Protocols.addEmployee' | translate"
    [addTag]="addEmployee"
    [loadingText]="'Protocols.loading' | translate"
    [loading]="loadingEmployees"
    [placeholder]="'Protocols.assignEmployee' | translate"
    [notFoundText]="'Protocols.notFoundText' | translate"
    [responseTransform]="successOnLoadEmmployees.bind(this)"
    [tabIndex]="-1"
    formControlName="employee"
  >
    <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
      <span
        class="ng-arrow-option"
        [innerHTML]="item?.userPerson?.previewName || item?.label || item?.name"
      ></span>
    </ng-template>
    <ng-template ng-label-tmp let-item="item" let-clear="clear">
      <span class="ng-value-icon right" (click)="clear(item)">×</span>
      <span class="ng-value-label">
        {{ item?.userPerson?.previewName || item?.label || item?.name }}
      </span>
    </ng-template>
  </ng-select>
  <span
    *ngIf="EmployeeControl?.touched"
    class="d-flex form-control-error-msg my-1"
    [innerHTML]="EmployeeErrorMessages | translate"
  ></span>
</form>
