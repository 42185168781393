<div class="d-flex align-content-start align-md-content-center flex-column flex-md-row flex-wrap">

  <list-single-filter-select
    class="w-100 w-sm-auto"
    [name]="'ProjectContacts.Filters.function' | translate"
    [iconUrl]="'/assets/img/ic-tag.svg'"
    [form]="service?.filtersForm"
    [controlName]="'[function-name][eq]'"
    [bindLabel]="'name'"
    [bindValue]="'name'"
    [multiple]="false"
    [translateLabelPrefix]="'Contacts.Function.'"
    (change)="onChangeFilter()"
    [requiredIdUrl]="Config.API + '/tag/type?type=contact-function'"
  ></list-single-filter-select>

  <list-single-filter-select
    class="w-100 w-sm-auto"
    [name]="'ProjectContacts.Filters.type' | translate"
    [iconUrl]="'/assets/img/ic-user.svg'"
    [form]="service?.filtersForm"
    [controlName]="'[a-type][eq]'"
    [bindValue]="'id'"
    [bindLabel]="'name'"
    [multiple]="false"
    (change)="onChangeFilter()"
    [localItems]="contactTypes"
  ></list-single-filter-select>


  <list-single-clear-filters
    [form]="service?.filtersForm"
    (change)="onChangeFilter()"
    [shouldShowClearFilters]="shouldShowClearFilters.bind(this)"
    [clearFilters]="clearFilters.bind(this)"
  >
  </list-single-clear-filters>
</div>
