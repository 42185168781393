import { Subscription } from 'rxjs';
import { ProtocolEmployeeSearcherComponent } from './components/protocol-employee-searcher/protocol-employee-searcher.component';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  HostListener,
  AfterViewInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { ActivatedRoute } from '@angular/router';
import { TextAttachmentsConfig } from '@shared/components/text-attachments-input/models/text-attachments-config.ts';
import { ProtocolType } from '@shared/enums/protocol-type.enum';
import { ProtocolApiService } from '@modules/protocols/shared/services/protocol-api.service';
import { WindowHelper } from '@shared/helpers/window.helper';
import { debounce } from '@shared/decorators/debounce.decorator';
import { EntryAction } from '@modules/protocols/shared/enums/entry-action.enum';
import {
  EntryListComponent,
  EntryManageType,
  IEntryEmitter,
} from '@shared/modules/entry/components/entry-list/entry-list.component';
import { EntryType } from '@shared/modules/entry/enums/entry-type.enum';
import { EntryObjectType } from '@shared/modules/entry/enums/entry-object-type.enum';

@Component({
  templateUrl: './protocol-manage.component.html',
  styleUrls: ['./protocol-manage.component.scss'],
})
export class ProtocolManageComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  WindowHelper = WindowHelper;
  header: HTMLDivElement;
  EntryType = EntryType;
  isTour: boolean = false;
  EntryManageType = EntryManageType;
  EntryObjectType = EntryObjectType;
  currentObjectType: EntryObjectType;

  subHidden: Subscription;
  hideHiddenTasks: boolean = this.pService.manager.$hideHiddenTasks;

  isPreview: boolean = false;
  isEdit: boolean = false;

  attachmentEntryConfig: TextAttachmentsConfig = {
    photoAttachments: true,
    fileAttachments: true,
    showText: true,
    maxAttachmentFileSize: 100,
    maxAttachmentImageSize: 50,
  };

  attachmentPhotoDefaultEntryConfig: TextAttachmentsConfig = {
    photoAttachments: true,
    fileAttachments: false,
    showText: true,
    maxAttachmentFileSize: 100,
    maxAttachmentImageSize: 50,
  };

  attachmentPhotoEntryConfig: TextAttachmentsConfig = {
    photoAttachments: true,
    fileAttachments: false,
    showText: true,
    maxAttachmentImageSize: 50,
    maxAttachments: 50,
  };

  @ViewChild('acceptance_stage') acceptance_stage: EntryListComponent;
  @ViewChild('acceptance_evidence') acceptance_evidence: EntryListComponent;
  @ViewChild('photo_documentation') photo_documentation: EntryListComponent;
  @ViewChild('worker_entry') worker_entry: EntryListComponent;

  acceptance_stageLoading: boolean = false;
  acceptance_evidenceLoading: boolean = false;
  photo_documentationLoading: boolean = false;
  worker_entryLoading: boolean = false;

  ProtocolEmployeeSearcherComponent = ProtocolEmployeeSearcherComponent;

  get TopOffset() {
    return this.isTour ? '0px' : `${this.header?.offsetHeight ? this.header.offsetHeight : 65}px`;
  }

  get isAcceptance() {
    return this.pService.manager?.protocol?.type === ProtocolType.TYPE_ACCEPTANCE;
  }

  get isProject() {
    return this.pService.manager?.protocol?.type === ProtocolType.TYPE_PROJECT;
  }

  get title() {
    switch (this.pService.manager.protocol?.type) {
      case ProtocolType.TYPE_ACCEPTANCE:
        return 'Protocols.acceptanceTitleType';
      case ProtocolType.TYPE_PROJECT:
        return 'Protocols.projectTitleType';
      case ProtocolType.TYPE_MEETING:
        return 'Protocols.meetingTitleType';
      default:
        return '';
    }
  }

  constructor(
    private changes: ChangeDetectorRef,
    private activated: ActivatedRoute,
    public pService: ProtocolApiService,
  ) {
    super();
  }

  ngOnInit() {
    this.isPreview = !!this.activated.snapshot?.data?.preview;
    this.isEdit = !!this.activated.snapshot?.data?.edit;

    this.pService.manager.protocolType = this.activated.snapshot.data.type;
    this.currentObjectType =
      this.pService.manager.protocol.type === ProtocolType.TYPE_ACCEPTANCE
        ? EntryObjectType.OBJECT_TYPE_ACCEPTANCE_PROTOCOL
        : EntryObjectType.OBJECT_TYPE_PROJECT_PROTOCOL;
    this.subscribe();
    this.changes.detectChanges();
  }

  subscribe() {
    this.subHidden = this.pService.manager.hideHiddenTasks.subscribe((v) => {
      this.hideHiddenTasks = v;
      this.changes.detectChanges();
    });
  }

  ngAfterViewInit() {
    this.setHeader();
    this.checkEditTour();
    this.changes.detectChanges();
  }

  checkEditTour() {
    if (this.activated.snapshot.queryParams['tutorial'] === '1') {
      this.isTour = true;
    }
  }

  setHeader() {
    this.header = document.querySelector('.sticky-header');
  }

  onEntry($event: IEntryEmitter, status: EntryType) {
    switch ($event.type) {
      case EntryAction.ADD:
      case EntryAction.EDIT:
      case EntryAction.DELETE:
      case EntryAction.RESTORE:
        this.updateEntryList($event);
        break;
      default:
        break;
    }
  }

  updateEntryList($event: IEntryEmitter) {
    this.pService.manager.ProtocolEntries[$event.data.item.type] = [...$event.data.list];
    this.pService.updateSimpleProtocol();
    this.changes.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  @debounce(30)
  windowResize(e: KeyboardEvent) {
    this.changes.detectChanges();
  }

  ngOnDestroy() {
    this.subHidden ? this.subHidden.unsubscribe() : null;
  }
}
