export const environment = {
  production: true,
  hmr: false,
  API: 'https://stg-intilio.develtio.dev/api',
  SERVER: 'https://stg-intilio.develtio.dev',
  PUBLIC_PHOTO: 'https://stg-intilio.develtio.dev/api/upload/photo/',
  PRIVATE_ATTACHMENTS: 'https://stg-intilio.develtio.dev/api/attachment/',
  CAPTCHA: '6LcTuqYZAAAAAKMIHXqo1V1TUpra5ZvZGOo-nrHD',
  GTAG: 'UA-176572901-2',
  CONTACT_EMAIL: 'support@intilio.com',
  DEBUG: false,
  INSTANCE_NAME: '[STAGE]',
  FULL_CALENDAR_LICENSE_KEY: '0588865760-fcs-1604309687'
};
