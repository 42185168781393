import { NgSelectExtensionDirective } from '@shared/directives/ng-select-extension.directive';
import { interval } from 'rxjs';
import { EmployeeSearchItem } from '@shared/models/employee-search-item.model';
import { Config } from '@shared/configs/config';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ProtocolApiService } from '@modules/protocols/shared/services/protocol-api.service';
import { BaseEntryModalComponent } from '@shared/modules/entry/components/base-entry-modal.component';

@Component({
  selector: 'protocol-employee-searcher',
  templateUrl: './protocol-employee-searcher.component.html',
})
export class ProtocolEmployeeSearcherComponent extends BaseEntryModalComponent implements OnInit {
  CONFIG = Config;
  loadingEmployees: boolean = false;

  initialValue: Array<any> = [];

  @ViewChild('employeeSelect') employeeSelect: NgSelectExtensionDirective;

  get formData() {
    const employee = this.form.get('employee')?.value;
    const assignedToIds = employee?.id && !employee?.name ? [employee?.id] : [];
    let externalEmployees = [];
    if (!!employee?.name) {
      externalEmployees = [employee.name];
    }

    if (!!employee?.label) {
      externalEmployees = [employee.label];
    }

    return { assignedToIds, externalEmployees };
  }

  get EmployeeControl() {
    return this.form.get('employee');
  }

  get EmployeeErrorMessages() {
    const control = this.EmployeeControl;
    if (control.errors?.required) {
      return 'FormErrors.required';
    }
    return '';
  }

  get URL() {
    return `${Config.API}/employee/search-all?excludedProtocolId=${this.pService.manager.protocol.id}`;
  }

  constructor(
    private fb: FormBuilder,
    public pService: ProtocolApiService,
    private changes: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit() {
    this.createForm();
    this.initialValue = [...this.pService.manager.allEmployeesToAssign];
  }

  createForm() {
    this.form = this.fb.group({
      employee: [null, Validators.required],
    });
  }

  successOnLoadEmmployees(list: any) {
    return list.employees.map((i) => new EmployeeSearchItem(i)).concat(list.externalEmployees);
  }

  addEmployee($event: string) {
    return { previewName: $event, name: $event, label: $event };
  }

  setFormFields(fields: any) {
    const employee = fields.entry?.assignedTo?.length
      ? fields.entry?.assignedTo[0]
      : fields.entry?.externalEmployees
      ? fields.entry?.externalEmployees[0]
      : null;

    if (!!employee) {
      this.initialValue = [...this.initialValue, employee];
    }

    const source$ = interval(200).subscribe(() => {
      if (!this.employeeSelect.select.loading) {
        this.form.get('employee')?.setValue(employee || null);
        source$.unsubscribe();
      }
    });
  }

  submit() {
    this.EmployeeControl.markAsTouched();
    this.changes.detectChanges();
  }
}
