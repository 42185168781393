import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseListFiltersComponentComponent } from '@shared/components/base-list-filters-component/base-list-filters-component.component';
import { ListService } from '@shared/modules/list/services/list.service';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '@shared/configs/config';
import { ContactType } from '@modules/contacts/shared/enums/contact-type.enum';

@Component({
  selector: 'project-contacts-filters',
  templateUrl: './project-contacts-filters.component.html',
  styleUrls: ['./project-contacts-filters.component.scss']
})
export class ProjectContactsFiltersComponent extends BaseListFiltersComponentComponent implements OnInit {
  Config = Config;

  contactTypes;

  constructor(
    public service: ListService,
    public t: TranslateService
  ) {
    super(service);
  }

  ngOnInit(): void {
    this.createForm();
    this.setContactTypes();
  }

  setContactTypes() {
    this.contactTypes = Object.values(ContactType).map((type) => {
      return {
        id: type,
        name: this.t.instant('Contacts.Type.' + type)
      };
    });
  }

  createForm() {
    this.service.filtersForm = new FormGroup({
      '[function-name][eq]': new FormControl(null),
      '[a-type][eq]': new FormControl(null)
    });
  }
}
