import { DeviceHelper } from '@shared/helpers/device.helper';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  BasePanelChangeEvent,
  BasePanelChangeEventType,
  BasePanelService,
} from '@shared/modules/base-panel/services/base-panel.service';

@Component({
  selector: 'base-panel',
  templateUrl: './base-panel.component.html',
  styleUrls: ['./base-panel.component.scss'],
})
export class BasePanelComponent implements OnInit {
  DeviceHelper = DeviceHelper;
  hideHeader: boolean = true;
  hideFooter: boolean = true;
  isFooterSticky: boolean = false;
  isSignUpHeader: boolean = false;

  @ViewChild('basePanel') basePanel: ElementRef;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private basePanelService: BasePanelService,
  ) {}

  ngOnInit(): void {
    this.basePanelService.basePanelComponent = this;
    this.listenBasePanelEvents();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        mergeMap((route) => route.data),
      )
      .subscribe((routeData) => {
        this.hideHeader = !!routeData.hideHeader;
        this.hideFooter = !routeData.showFooter;
        this.isFooterSticky = !!routeData.stickyFooter;
        this.isSignUpHeader = !!routeData.isSignUpHeader;
      });
  }

  private listenBasePanelEvents() {
    this.basePanelService.panelChangeEmitter.subscribe((e: BasePanelChangeEvent) => {
      switch (e.type) {
        case BasePanelChangeEventType.SCROLL_TO:
          this.scrollTo(e.data as ScrollToOptions);
          break;
        case BasePanelChangeEventType.BLOCK_SCROLL:
          this.basePanel.nativeElement.classList.add('base-panel--block-scroll');
          break;
        case BasePanelChangeEventType.UNBLOCK_SCROLL:
          this.basePanel.nativeElement.classList.remove('base-panel--block-scroll');
          break;
      }
    });
  }

  onScroll() {
    window.dispatchEvent(new CustomEvent('scroll'));
  }

  private scrollTo(scrollToOptions: ScrollToOptions) {
    this.basePanel.nativeElement.scrollTo(scrollToOptions);
  }
}
