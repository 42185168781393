<div class="ui-project__header">
  <h3 class="mb-0">{{ 'ProjectContacts.title' | translate }}</h3>

  <app-button
    [permission]="{ group: PermissionsGroups.PROJECT_CONTACTS, action: 'ADD' }"
    (onClick)="addNewContact()"
    [config]="{
      size: ButtonSize.SMALL,
      type: ButtonTypes.PRIMARY_GREEN,
      text: WindowHelper.isMobileWidth ? '' : ('ProjectContacts.addNewContact' | translate),
      prependSrc: './assets/img/ic-user-plus.svg'
    }"
  ></app-button>
</div>

<div class="container-fluid--30 pt-3">
  <app-list
    *ngIf="inited"
    [config]="config"
    [filters]="ProjectContactsFiltersComponent"
    [emptyTemplate]="emptyTemplate"
    [searchName]="'ProjectContacts.List.search' | translate"
    [selected]="selected"
    [showToggleMenu]="showToggleMenu.bind(this)"
    #list
  >
  </app-list>
</div>

<ng-template #emptyTemplate>
  <div class="d-flex flex-column align-items-center mt-4 empty">
    <img
      draggable="false"
      src="/assets/img/team-placeholder-icon.svg"
      alt="{{ 'ProjectChanges.List.noRecords' | translate }}"
      class="mb-4 mt-4"
    />
    <div class="mt-2">{{ 'ProjectTeam.List.noRecords' | translate }}</div>
    <app-button
      [permission]="{ group: PermissionsGroups.PROJECT_CONTACTS, action: 'ADD' }"
      (onClick)="addNewContact()"
      [config]="{
        size: ButtonSize.SMALL,
        type: ButtonTypes.SECONDARY_BLUE,
        text: 'ProjectTeam.addNewTeamMember' | translate,
        prependSrc: './assets/img/ic-user-plus.svg'
      }"
    ></app-button>
  </div>
</ng-template>

<project-contact-name-cell #projectContactNameCellComponent></project-contact-name-cell>
<project-contact-type-cell #projectContactTypeCellComponent></project-contact-type-cell>
<project-contact-phone-cell #projectContactPhoneCellComponent></project-contact-phone-cell>
<project-contact-email-cell #projectContactEmailCellComponent></project-contact-email-cell>
<project-contact-function-cell #projectContactFunctionCellComponent></project-contact-function-cell>
<project-contact-role-cell #projectContactRoleCellComponent></project-contact-role-cell>
