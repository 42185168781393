import { TaskSidenavService } from '@shared/modules/task-sidenav/services/task-sidenav.service';
import { Component, Input } from '@angular/core';
import { Config } from '@shared/configs/config';
import { ProjectChangesSettlementStatus } from '@modules/projects/shared/enums/project-change-settlement-status.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'task-sidenav-blue',
  styleUrls: ['./task-sidenav-blue.component.scss'],
  templateUrl: './task-sidenav-blue.component.html',
})
export class TaskSidenavBlueComponent {
  CONFIG = Config;
  items = [
    { name: 'Projects.ProjectSettlement.null', value: ProjectChangesSettlementStatus.CHANGE_SETTLEMENT_NONE },
    {
      name: 'Projects.ProjectSettlement.unsettled',
      value: ProjectChangesSettlementStatus.CHANGE_SETTLEMENT_NOT_SETTLED,
    },
    {
      name: 'Projects.ProjectSettlement.settled',
      value: ProjectChangesSettlementStatus.CHANGE_SETTLEMENT_SETTLED,
    },
  ].map((i) => {
    i.name = this.t.instant(i.name);
    return i;
  });

  constructor(public service: TaskSidenavService, private t: TranslateService) {}
}
