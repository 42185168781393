import { Languages } from '@core/services/language.service';
import { UserRole } from '@shared/interfaces/user.interface';
import { ShownTutorials } from '@shared/models/shown-tutorials.model';
import { phoneCountries } from '@shared/components/phone-input/components/country-picker/phone-countries';

export class BaseUserPerson {
  id: number;
  email: string;
  enabled: boolean;
  lastLogin?: any;
  passwordRequestedAt?: any;
  roles: UserRole[];
  firstName?: any;
  lastName?: any;
  language?: Languages;
  beforeDeleteEmail?: any;
  deletedAt?: any;
  createdAt: string;
  updatedAt: string;
  registerIpAddress: string;
  accountConfirmed: boolean;
  phone?: any;
  phoneCountry?: string;
  photo?: string;
  canCreateCompany: boolean;
  shownTutorials: ShownTutorials;
  previewName?: string;
  fullPhone?: string;
  numberFormat?: string;

  get fullName() {
    const name = [this.firstName, this.lastName].join(' ').trim();
    return name !== '' ? name : '-';
  }

  constructor(data?: BaseUserPerson) {
    data ? Object.assign(this, data) : null;
    this.setPreviewName();
  }

  setFullPhone(): void {
    if ((!this.phoneCountry && !this.phone) || !this.phone) {
      this.fullPhone = '';
      return;
    }
    const country = phoneCountries[phoneCountries.findIndex((x) => x.flagClass === this.phoneCountry)];
    country ? (this.fullPhone = `+${country.dialCode}${this.phone}`) : (this.fullPhone = this.phone);
  }

  setPreviewName() {
    this.previewName = this.fullName !== '-' ? this.fullName : this?.email;
  }
}
