import { Employee } from '@shared/models/employee.model';
import { Project } from '@modules/projects/shared/models/project.model';
import { Protocol } from '@modules/protocols/shared/models/protocol';
import { EventCycle } from '../enums/event-cycle.enum';
import { EventReminderFullDay, EventReminderNonFullDay } from '../enums/event-reminder.enum';
import { Participant } from './participant.model';

export class CalendarEvent {
  id: number;
  termStart: string = null;
  termEnd: string = null;
  isFullDay: boolean = false;
  reminderTypes: (EventReminderFullDay | EventReminderNonFullDay)[] = [];
  cycleType: EventCycle = null;
  emails: string[] = [];
  description: string = null;
  address: string = null;
  created: string;
  deleted: string;
  modified: string;
  name: string;
  participants: Participant[] = [];
  project: Project = null;
  creator: Employee = null;
  protocols: Protocol[] = [];
  color?: string;
  isExternal: boolean = false;
  myParticipation?: any;

  participantCreator: Participant = null;

  constructor(e?: CalendarEvent) {
    this.deserialize(e);
  }

  deserialize(e?: CalendarEvent) {
    e ? Object.assign(this, e) : null;
    e.project ? (this.project = new Project(e.project)) : null;

    this.setParticipants();
    this.setCreator();
    this.setColor();
  }

  setColor() {
    const color = this.color === 'ffffff' ? '187dbd' : `${this.color}`.replace('#', '');
    this.color = `#${color}`;
  }

  setCreator() {
    this.creator ? (this.creator = new Employee(this.creator)) : null;
    this.participantCreator = this.participants.filter((i) => i.isCreator)[0] || null;
  }

  setParticipants() {
    this.participants = this.participants?.map((p) => new Participant(p)) || [];
  }

  setProtocols() {
    this.protocols = this.protocols?.map((p) => new Protocol(p)) || [];
  }
}
