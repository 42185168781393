<div class="header-action-menu d-flex justify-content-end align-items-center" #el>
  <ul class="m-0 d-flex align-items-center">
    <li class="header-action-button">
      <app-button
        [matMenuTriggerFor]="createMenu"
        [config]="{
          size: ButtonSize.SMALL,
          type: ButtonTypes.PRIMARY_GREEN,
          prependSrc: './assets/img/ic-plus-add.svg'
        }"
      ></app-button>

      <mat-menu #createMenu="matMenu" yPosition="above" class="mt-1">
        <button
          [permission]="{ group: PermissionsGroups.CONTACTS, action: 'ADD' }"
          mat-menu-item
          (click)="createContact()"
        >
          <span>{{ 'Header.CreateOptions.contact' | translate }}</span>
        </button>

        <button
          [permission]="{ group: PermissionsGroups.PROJECTS, action: 'ADD' }"
          mat-menu-item
          (click)="createProjectDraft()"
        >
          <span>{{ 'Header.CreateOptions.project' | translate }}</span>
        </button>

        <button
          mat-menu-item
          [permission]="{ group: PermissionsGroups.PROTOCOLS, action: 'ADD' }"
          (click)="createProtocol()"
        >
          <span>{{ 'Header.CreateOptions.protocol' | translate }}</span>
        </button>

        <button
          mat-menu-item
          [permission]="{ group: PermissionsGroups.TASKS, action: 'ADD' }"
          (click)="createTask()"
        >
          <span>{{ 'Header.CreateOptions.task' | translate }}</span>
        </button>

        <button
          mat-menu-item
          [permission]="{ group: PermissionsGroups.TASKS, action: 'ADD' }"
          (click)="createChange()"
        >
          <span>{{ 'Header.CreateOptions.change' | translate }}</span>
        </button>

        <button
          mat-menu-item
          [permission]="{ group: PermissionsGroups.CALENDAR, action: 'ADD_EVENT' }"
          (click)="createEvent()"
        >
          <span>{{ 'CalendarEvents.event' | translate }}</span>
        </button>
        <button
          mat-menu-item
          [permission]="{ group: PermissionsGroups.MAILINGS, action: 'MAILING_FUNCTION' }"
          (click)="createEmail()"
        >
          <span>{{ 'E-mail' | translate }}</span>
        </button>
      </mat-menu>
    </li>
    <li class="header-action-menu-company">
      <header-action-menu-company></header-action-menu-company>
    </li>
    <li (click)="notificationsService.open()">
      <header-action-menu-notification></header-action-menu-notification>
    </li>
    <li class="no-hover">
      <header-action-menu-user></header-action-menu-user>
    </li>
  </ul>
</div>
