<div class="event-sidenav">
  <sidenav-loading *ngIf="service.loadingEvent" [cover]="true"></sidenav-loading>
  <div class="event-sidenav__details">
    <event-creation *ngIf="service.isCreation && !service.loadingEvent"></event-creation>
    <event-details *ngIf="service.isPreview && !service.loadingEvent"></event-details>
    <event-edit *ngIf="service.isEdit && !service.loadingEvent"></event-edit>
  </div>

  <div
    *ngIf="!service.loadingEvent && (service.isCreation || service.isEdit)"
    class="sticky-footer d-flex justify-content-end align-items-center py-1"
  >
    <event-sticky-action></event-sticky-action>
  </div>
</div>
