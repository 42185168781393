import { ProjectChangeRoutes } from './routes/project-changes.routes';
import { ProjectCreateRoutes } from './routes/projects-create.routes';
import { CustomRoutes } from '@shared/consts/routes/custom-routes.const';
import { AuthorizationRoutes } from './routes/authorization.routes';
import { SettingsRoutes } from './routes/settings.routes';
import { GlobalRoutes } from './routes/global.routes';
import { ProtocolRoutes } from './routes/protocols.routes';
import { ClientPanelRoutes } from './routes/client-panel.routes';
import { HelpRoutes } from './routes/help.routes';
import { ContactsRoutes } from '@shared/configs/routes/contacts.routes';
import { ProjectsListRoutes } from '@shared/configs/routes/projects-list.routes';
import { ProjectPreviewRoutes } from '@shared/configs/routes/project-preview.routes';
import { ProjectSummaryRoutes } from '@shared/configs/routes/project-summary.routes';
import { ProjectsTimelineRoutes } from '@shared/configs/routes/projects-timeline.routes';
import { ProjectEditRoutes } from './routes/project-edit.routes';
import { ProjectProtocolsRoutes } from '@shared/configs/routes/project-protocols.routes';
import { ProjectTasksRoutes } from './routes/project-tasks.routes';
import { ProjectTeamRoutes } from '@shared/configs/routes/project-team.routes';
import { MyWorkRoutes } from '@shared/configs/routes/my-work.routes';
import { ProjectContactsRoutes } from '@shared/configs/routes/project-contacts.routes';
import { CalendarRoutes } from './routes/calendar.routes';
import { environment } from 'src/environments/environment';
import { MailingTemplateRoutes } from './routes/mailing-template.routes';
import { ProjectCreatorRoutes } from './routes/project-creator.routes';
import { ProjectConfiguratoRoutes } from './routes/project-configurator.routes';
import { ProjectEmailRoutes } from './routes/project-email.routes';
import { ProjectCalendarRoutes } from './routes/project-calendar.routes';

export interface AppRoutes {
  [key: string]: AppRoute;
}

export interface AppRoute {
  routes: CustomRoutes;
  startPath: string;
  enabledModules?: any[];
}

const routes = {
  global: {
    routes: GlobalRoutes,
    startPath: '',
  },
  auth: {
    routes: AuthorizationRoutes,
    startPath: '',
  },
  projectCreator: {
    routes: ProjectCreatorRoutes,
    startPath: 'project-creator',
  },
  clientPanel: {
    routes: ClientPanelRoutes,
    startPath: 'client-panel',
  },
  settings: {
    routes: SettingsRoutes,
    startPath: 'panel/settings',
  },
  protocols: {
    routes: ProtocolRoutes,
    startPath: 'panel/protocols',
  },
  contacts: {
    routes: ContactsRoutes,
    startPath: 'panel/contacts',
  },
  projectsCreate: {
    routes: ProjectCreateRoutes,
    startPath: 'panel/projects/create',
  },
  projectsEdit: {
    routes: ProjectEditRoutes,
    startPath: 'panel/projects/edit',
  },
  projectsList: {
    routes: ProjectsListRoutes,
    startPath: 'panel/projects/list',
  },
  projectsTimeline: {
    routes: ProjectsTimelineRoutes,
    startPath: 'panel/projects/timeline',
  },
  projectPreview: {
    routes: ProjectPreviewRoutes,
    startPath: 'panel/projects/preview',
  },
  projectSummary: {
    routes: ProjectSummaryRoutes,
    startPath: 'panel/projects/preview/:id/summary',
  },
  projectTasks: {
    routes: ProjectTasksRoutes,
    startPath: 'panel/projects/preview/:id/tasks',
  },
  projectTeam: {
    routes: ProjectTeamRoutes,
    startPath: 'panel/projects/preview/:id/team',
  },
  projectConfigurator: {
    routes: ProjectConfiguratoRoutes,
    startPath: 'panel/projects/preview/:id/configurator',
  },
  projectContacts: {
    routes: ProjectContactsRoutes,
    startPath: 'panel/projects/preview/:id/contacts',
  },
  projectChanges: {
    routes: ProjectChangeRoutes,
    startPath: 'panel/projects/preview/:id/changes',
  },
  projectCalendar: {
    routes: ProjectCalendarRoutes,
    startPath: 'panel/projects/preview/:projectId/calendar',
  },
  projectEmail: {
    routes: ProjectEmailRoutes,
    startPath: 'panel/projects/preview/:projectId/mailings',
  },
  projectProtocols: {
    routes: ProjectProtocolsRoutes,
    startPath: 'panel/projects/preview/:projectId/protocols',
  },
  calendar: {
    routes: CalendarRoutes,
    startPath: 'panel/calendar',
  },
  myWork: {
    routes: MyWorkRoutes,
    startPath: 'panel/my-work',
  },
  mailingTemplates: {
    routes: MailingTemplateRoutes,
    startPath: 'panel/mailings',
  },
  help: {
    routes: HelpRoutes,
    startPath: 'help',
  },
};

export const Routes: AppRoutes = routes;
