<div class="d-flex align-content-start align-md-content-center flex-column flex-md-row flex-wrap">
  <list-single-filter-select
    class="w-100 w-sm-auto"
    [name]="'ProjectTeam.List.function' | translate"
    [iconUrl]="'/assets/img/ic-tag.svg'"
    [form]="service?.filtersForm"
    [controlName]="'[function-name][eq]'"
    [bindLabel]="'name'"
    [bindValue]="'name'"
    [multiple]="false"
    [translateLabelPrefix]="'Contacts.Function.'"
    (change)="onChangeFilter()"
    [requiredIdUrl]="Config.API + '/tag/type?type=contact-function'"
  ></list-single-filter-select>

</div>
