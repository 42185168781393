import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ISideNavigationConfig } from '@shared/modules/side-navigation/interfaces/side-navigation-config.interface';
import { myWorkSideNavigationConfig } from '@modules/my-work/pages/my-work/my-work-side-config.const';
import { cloneDeep } from 'lodash';
import { DefaultListConfig } from '@shared/modules/list/configs/default-list.config';
import { TaskSidenavController } from '@shared/modules/task-sidenav/controllers/task-sidenav.controller';
import { ListConfig } from '@shared/modules/list/interfaces/list-config.interface';
import { MyWorkListConfig } from '@modules/my-work/shared/configs/my-work-list.config';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { MyWorkFiltersComponent } from '@modules/my-work/pages/my-work/components/my-work-filters/my-work-filters.component';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { ListService } from '@shared/modules/list/services/list.service';
import { ISideNavigationTab } from '@shared/modules/side-navigation/interfaces/side-navigation-tab.interface';
import { Config } from '@shared/configs/config';
import { ActivatedRoute, Router } from '@angular/router';
import { SideNavigationViewComponent } from '@shared/modules/side-navigation/components/side-navigation-view/side-navigation-view.component';
import { ListTasksComponent } from '@shared/modules/list/components/list-tasks/list-tasks.component';
import { MyWorkService } from '@modules/my-work/shared/services/my-work.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { StorageService } from '@core/services/storage.service';

@Component({
  selector: 'my-work',
  templateUrl: './my-work.component.html',
  styleUrls: ['./my-work.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyWorkComponent implements OnInit, AfterViewInit {
  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;
  MyWorkFiltersComponent = MyWorkFiltersComponent;
  PermissionsGroups = PermissionsGroups;

  configSideNavigation: ISideNavigationConfig;
  taskSidenavCtrl: TaskSidenavController;
  config: ListConfig;
  tabs: any[] = [];

  @ViewChild('sideNavigationViewComponent') sideNavigationViewComponent: SideNavigationViewComponent;
  @ViewChild('listTasksComponent') listTasksComponent: ListTasksComponent;
  @ViewChild('selectTab') selectTab: NgSelectComponent;

  constructor(
    public t: TranslateService,
    private zone: NgZone,
    private listService: ListService,
    private route: ActivatedRoute,
    private changes: ChangeDetectorRef,
    private router: Router,
    public myWorkService: MyWorkService,
    private store: StorageService,
  ) {}

  ngOnInit(): void {
    this.taskSidenavCtrl = new TaskSidenavController();
    this.initSideNavigationConfig();
    this.initListConfig();
  }

  ngAfterViewInit() {
    this.listTasksComponent.listTasksController.myWorkService = this.myWorkService;
    this.sideNavigationViewComponent.sideNavigationBaseMenuComponent.changes.detectChanges();
    this.initActiveTab();
    this.listService.getRows();
  }

  setTabs() {
    this.configSideNavigation.tabs.map((t: ISideNavigationTab) => {
      this.tabs.push({
        id: t.prop,
        prop: t.prop,
        text: t.name,
      });
    });
  }

  createNewTask() {
    this.taskSidenavCtrl.addTask();
  }

  initSideNavigationConfig() {
    this.zone.runOutsideAngular(() => {
      this.configSideNavigation = myWorkSideNavigationConfig(this.t);
      if (this.store.Employee.isClient) {
        this.configSideNavigation.tabs = this.configSideNavigation.tabs.filter((t) => t.prop !== 'delegated');
      }
    });
    this.setTabs();
  }

  initListConfig() {
    this.config = cloneDeep(DefaultListConfig);
    this.config = Object.assign(this.config, MyWorkListConfig);
  }

  initActiveTab() {
    if (Number(this.route?.snapshot?.queryParams?.delegatedTasks) === 1) {
      this.configSideNavigation.tabs[1].active = true;
      this.selectTab.writeValue('delegated');
      this.listService.setDelegatedTasks(1);
    } else {
      this.configSideNavigation.tabs[0].active = true;
      this.selectTab.writeValue('my');
      this.listService.setDelegatedTasks(0);
    }
  }

  onTabClick(clickedTab) {
    this.configSideNavigation.tabs.map((t: ISideNavigationTab) => {
      t.active = t.prop === clickedTab.prop;
    });

    this.selectTab.writeValue(clickedTab.id);

    if (clickedTab.prop === 'delegated') {
      this.listService.setDelegatedTasks(1);
      this.router.navigate([], {
        queryParams: { delegatedTasks: 1 },
        queryParamsHandling: 'merge',
      });
    } else {
      this.listService.setDelegatedTasks(0);
      this.router.navigate([], {
        queryParams: { delegatedTasks: 0 },
        queryParamsHandling: 'merge',
      });
    }

    this.listService.getRows();
  }

  ngOnDestroy() {
    MyWorkListConfig.url = `${Config.API}/task-list/my-task?delegatedTasks=0`;
  }
}
