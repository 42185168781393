import { SideNavigationTabType } from '@shared/modules/side-navigation/enums/side-navigation-tab-type.enum';
import { SideNavigationTabCountType } from '@shared/modules/side-navigation/enums/side-navigation-tab-count-type.enum';
import { TranslateService } from '@ngx-translate/core';

export const myWorkSideNavigationConfig = (t: TranslateService) => {
  return {
    title: t.instant('MyWork.title'),
    backAvailable: false,
    name: 'my-work-list',
    canHidden: true,
    tabs: [
      {
        name: t.instant('MyWork.SideNavigation.myTasks'),
        type: SideNavigationTabType.TAB,
        icon: '/assets/img/ic-user-check.svg',
        prop: 'my',
        countType: SideNavigationTabCountType.SUCCESS,
        count: 0
      },
      {
        name: t.instant('MyWork.SideNavigation.delegatedTasks'),
        type: SideNavigationTabType.TAB,
        icon: '/assets/img/ic-list-mini.svg',
        prop: 'delegated',
        countType: SideNavigationTabCountType.SUCCESS,
        count: 0
      }
    ]
  };
};
