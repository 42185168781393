import { WindowHelper } from '@shared/helpers/window.helper';
import { finalize, delay } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Component,
  ElementRef,
  ViewChild,
  HostListener,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
  Input,
  AfterViewInit,
} from '@angular/core';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { LanguageService } from '@core/services/language.service';
import { ProjectCreatorDataService } from '@modules/project-creator/shared/services/project-creator-data.service';
import { Project } from '@modules/projects/shared/models/project.model';
import { BaseProjectCreator } from '@modules/project-creator/shared/controllers/base-project-creator.controller';
import { SnackBarService } from '@core/services/snackbar.service';
import { Subscription } from 'rxjs';
import { StorageService } from '@core/services/storage.service';
import { NavigateService } from '@shared/services/navigate.service';
import { ProjectStoreService } from '@modules/projects/shared/services/project-store.service';
import { ProjectPreviewService } from '@modules/projects/modules/project-preview/services/project-preview.service';
import { CreatorPermission } from '@modules/project-creator/shared/enums/creator-permisson.enum';
import { ProjectCreatorPermissionResolverService } from '@modules/project-creator/shared/services/project-creator-permission-resolver.service';

@Component({
  selector: 'app-project-creator-manage',
  templateUrl: './project-creator-manage.component.html',
  styleUrls: ['./project-creator-manage.component.scss'],
})
export class ProjectCreatorManage implements OnInit, AfterViewInit, OnDestroy {
  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;
  currentStep: BaseProjectCreator;
  project: Project;

  sub: Subscription = new Subscription();
  subForm: Subscription = new Subscription();

  readonly useCustomPermission: boolean = this.active.snapshot.data?.useExistingProjectPermissions;
  customPermissionsStep1: CreatorPermission[] = [];
  customPermissionsStep2: CreatorPermission[] = [];
  customPermissionsStep3: CreatorPermission[] = [];
  customPermissionsStep4: CreatorPermission[] = [];
  customPermissionsStep2Documents: CreatorPermission[] = [];

  get hasEditPermissionForCurrentStep(): boolean {
    const currentPerms = this.getCurrentPersmission(this.service.stepper.currentStep);
    return (
      !this.useCustomPermission || (this.useCustomPermission && currentPerms.includes(CreatorPermission.EDIT))
    );
  }

  @Input() projectValue: Project = null;
  @Input() showHeaderLogo: boolean = true;
  @Input() hideFooter: boolean = false;
  @Input() config: any = null;
  @Input() internalCreator: boolean = false; // use this value if you need to use internal API requests

  @ViewChild('header') header: ElementRef;
  @Input() headerStepOutOfTemplate: boolean = false;

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    if (document.querySelector('.base-panel').scrollTop >= this.header.nativeElement.offsetTop) {
      this.header.nativeElement.classList.add('active');
    } else {
      this.header.nativeElement.classList.remove('active');
    }
  }

  get nextStepText() {
    if (WindowHelper.isLessMobileWidth) {
      if (!!this.internalCreator) {
        if (this.service.stepper.currentStep >= 4) {
          return !!this.active.snapshot?.data?.create ? 'ProjectCreator.create' : 'ProjectCreator.save';
        }
      }
      return 'ProjectCreator.nextStep';
    }

    switch (this.service.stepper.currentStep) {
      case 1:
        return 'ProjectCreator.nextStepFirst';
      case 2:
        return 'ProjectCreator.nextStepSecond';
      case 3:
        return 'ProjectCreator.nextStepThird';
      case 4:
        return !!this.internalCreator
          ? !!this.active.snapshot?.data?.create
            ? 'ProjectCreator.create'
            : this.hasEditPermissionForCurrentStep
            ? 'ProjectCreator.save'
            : 'ProjectCreator.close'
          : 'ProjectCreator.nextStepFourh';
      case 5:
        return 'ProjectCreator.close';
      default:
        return !!this.internalCreator ? 'ProjectCreator.save' : 'ProjectCreator.nextStep';
    }
  }

  get currentLang() {
    return this.lang.getCurrentLanguage();
  }

  get flag() {
    if (this.currentLang === 'en') {
      return 'gb';
    }
    return this.currentLang;
  }

  get token() {
    return this.project?.projectPublicFormToken;
  }

  get isCurrentStepUnsaved() {
    return !!this.currentStep?.dataChanged;
  }

  constructor(
    public active: ActivatedRoute,
    public service: ProjectCreatorDataService,
    private t: TranslateService,
    private lang: LanguageService,
    private router: Router,
    private changes: ChangeDetectorRef,
    private s: SnackBarService,
    public store: StorageService,
    private n: NavigateService,
    private projectService: ProjectStoreService,
    private projectPreviewService: ProjectPreviewService,
    private projectStoreService: ProjectStoreService,
    private readonly permissionResolver: ProjectCreatorPermissionResolverService,
  ) {}

  ngOnInit(): void {
    this.service.initializeSteps(this.internalCreator);
    this.initCreatorProcess();
    this.resolveUserPermissions();
  }

  ngAfterViewInit(): void {
    this.subscribeUrlStepChange();
  }

  private getCurrentPersmission(step: number): CreatorPermission[] {
    switch (step) {
      case 1:
        return this.customPermissionsStep1;
      case 2:
        return this.customPermissionsStep2;
      case 3:
        return this.customPermissionsStep3;
      case 4:
        return this.customPermissionsStep4;
      default:
        return [];
    }
  }

  private resolveUserPermissions(): void {
    if (!this.useCustomPermission) return;
    const employee = this.store.Employee;
    if (!employee) return;

    this.customPermissionsStep1 = this.permissionResolver.resolvePermissions(
      employee,
      `PREVIEW_STEP_1`,
      `EDIT_STEP_1`,
    );

    this.customPermissionsStep2 = this.permissionResolver.resolvePermissions(
      employee,
      `PREVIEW_STEP_2`,
      `EDIT_STEP_2`,
    );

    this.customPermissionsStep3 = this.permissionResolver.resolvePermissions(
      employee,
      `PREVIEW_STEP_3`,
      `EDIT_STEP_3`,
    );

    this.customPermissionsStep4 = this.permissionResolver.resolvePermissions(
      employee,
      `PREVIEW_STEP_4`,
      `EDIT_STEP_4`,
    );

    this.customPermissionsStep2Documents = this.permissionResolver.resolvePermissions(
      employee,
      'PREVIEW_STEP_2_DOCUMENTS',
      'EDIT_STEP_2_DOCUMENTS',
    );

    this.changes.detectChanges();
  }

  initCreatorProcess() {
    let step = this.active.snapshot.queryParams.step;
    step = Number(step);

    const project: Project = new Project(this.projectValue || this.active.snapshot.data.project);
    this.setProject(project);
    if (!!step && !isNaN(step)) {
      if (step >= 5) {
        step = !project.basicDataBox?.mainContact?.mainContact ? 4 : 5;
        this.router.navigate(['.'], { relativeTo: this.active, queryParams: { step } });
        return;
      }
      this.service.stepper.setStep(step);
    } else {
      this.router.navigate(['.'], { relativeTo: this.active, queryParams: { step: 1 } });
    }
    if (!!this.project.projectPublicFormToken && !this.internalCreator) {
      this.store.sharedToken = this.project.projectPublicFormToken + '&projectToken=true';
    } else {
      this.store.deleteSharedToken();
    }
  }

  setProject(project: Project) {
    project ? (this.project = new Project(project)) : null;
  }

  subscribeUrlStepChange() {
    let step = null;
    const sub = this.active.queryParams.subscribe((queryParams) => {
      if (step === queryParams.step) return;
      step = queryParams.step;
      if (!!queryParams.step) {
        this.currentStep?.unsubscribe();
        this.service.stepper.setStep(Number(queryParams.step));
        this.currentStep = this.service.getCurrentStep();
        this.currentStep.setProject(this.project);
        setTimeout(() => {
          this.currentStep.setFormValues();
          this.changes.detectChanges();
        }, 0);
        setTimeout(() => {
          this.currentStep.subscribeChange(this.changes);
          this.changes.detectChanges();
        }, 1000);
      }
    });

    this.sub.add(sub);
  }

  enableInstantScroll() {
    setTimeout(() => {
      const basePanel = document.getElementsByClassName('base-panel')[0];
      basePanel.classList.add('base-panel--no-smooth');
      basePanel.scrollTo(0, 0);
      basePanel.classList.remove('base-panel--no-smooth');
    }, 100);
  }

  clickStep(step: number) {
    if (step === this.service.stepper.currentStep || this.service.stepper.currentStep === 5) return;
    const callback = (project: Project) => {
      this.router.navigate(['.'], { relativeTo: this.active, queryParams: { step } }).then(() => {
        if (!!this.active?.snapshot?.data?.create) {
          this.enableInstantScroll();
        } else {
          setTimeout(() => {
            const basePanel = document.getElementsByClassName('base-panel')[0];
            basePanel?.classList?.add('base-panel--no-smooth');
            document?.querySelector('.project-panel-preview__content')?.scrollIntoView();
            basePanel?.classList?.remove('base-panel--no-smooth');
          }, 100);
        }
      });
      this.updateStepAndProject(step, project);
    };

    this.submitCurrentStep(callback);
  }

  updateStepAndProject(step: number, project: Project) {
    this.service.stepper.setStep(step);
    this.updateProjectAtServices(project);
    this.currentStep = this.service.getCurrentStep();
    this.currentStep.setProject(this.project);
    setTimeout(() => {
      this.currentStep.setFormValues();
      this.changes.detectChanges();
    }, 0);
  }

  updateProjectAtServices(project) {
    this.setProject(project);
    this.projectStoreService.projectCtrl.project = this.project;
    this.projectPreviewService.project = this.project;
  }

  useLang(lang: string) {
    this.lang.useLanguage(lang).subscribe();
  }

  updateControls() {
    if (!Object.keys(this.currentStep?.form?.controls || {}).length) return;
    Object.keys(this.currentStep.form.controls).forEach((key: string) => {
      this.currentStep.form.controls[key].markAsTouched({ onlySelf: true });
      this.currentStep.form.controls[key].updateValueAndValidity({ onlySelf: true, emitEvent: false });
    });

    if (!Object.keys(this.currentStep?.additionalForm?.controls || {}).length) return;
    Object.keys(this.currentStep.additionalForm.controls).forEach((key: string) => {
      this.currentStep.additionalForm.controls[key].markAsTouched({ onlySelf: true });
      this.currentStep.additionalForm.controls[key].updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });
    });
  }

  private handleNoEditPermissionSubmit(stepModifier: number, callback?: Function): void {
    const project = this.projectPreviewService.project;

    if (!!callback) {
      callback(project);
      return;
    }

    if (this.service.stepper.currentStep >= 4 && stepModifier > 0) {
      this.n.go('project-preview', { projectId: project?.id });
      return;
    }

    const step = this.service.stepper.currentStep + stepModifier;
    this.router.navigate(['.'], { relativeTo: this.active, queryParams: { step } }).then(() => {
      this.enableInstantScroll();
    });
    this.updateStepAndProject(step, project);
  }

  submitCurrentStep(callback?: Function) {
    if (!this.hasEditPermissionForCurrentStep) {
      this.handleNoEditPermissionSubmit(1, callback);
      return;
    }

    this.updateControls();

    if (this.currentStep?.form?.invalid || this.currentStep?.additionalForm?.invalid) {
      this.scrollToError();
      return;
    }
    if (this.service.stepper.currentStep === 5) return;
    this.currentStep.loading = true;
    this.changes.detectChanges();
    const method = !!this.internalCreator
      ? this.currentStep.submitInternal(
          this.project?.id,
          !callback ? this.service.stepper.currentStep : null,
          !callback ? true : false,
        )
      : this.currentStep.submit(this.token, !callback ? this.service.stepper.currentStep : null);

    method
      .pipe(
        finalize(() => {
          this.currentStep.loading = false;
          this.changes.detectChanges();
        }),
      )
      .pipe(delay(10))
      .subscribe((project: Project) => {
        this.changes.detectChanges();

        if (!!callback) {
          this.showSuccessMessage();
          callback(project);
        } else {
          let step = this.service.stepper.currentStep + 1;
          if (!!this.internalCreator && step > 4) {
            step = 4;
            if (!!this.active?.snapshot?.data?.create) {
              this.updateProjectAtServices(project);
              this.projectService.projectCreated = true;
              this.s.success(this.t.instant('ProjectCreator.projectCreated'));
              this.n.go('projects-list');
              return;
            }

            if (!!this.active?.snapshot?.data?.edit) {
              this.showSuccessMessage();
              this.updateProjectAtServices(project);
              this.projectService.projectCreated = true;
              this.n.go('project-preview', { projectId: project?.id });
              return;
            }
          }
          this.showSuccessMessage();
          this.router
            .navigate(['.'], {
              relativeTo: this.active,
              queryParams: { step },
            })
            .then(() => {
              this.enableInstantScroll();
            });
          this.updateStepAndProject(step, project);
        }
      });
  }

  goToPrevStep(callback?: Function) {
    if (!this.hasEditPermissionForCurrentStep) {
      this.handleNoEditPermissionSubmit(-1, callback);
      return;
    }

    this.updateControls();
    if (this.service.stepper.currentStep === 1) {
      return;
    }
    if (this.currentStep?.form?.invalid || this.currentStep?.additionalForm?.invalid) {
      this.scrollToError();
      return;
    }
    this.currentStep.loading = true;
    this.changes.detectChanges();
    const method = this.internalCreator
      ? this.currentStep.submitInternal(this.project?.id, this.service.stepper.currentStep, false)
      : this.currentStep.submit(this.token);

    method
      .pipe(
        finalize(() => {
          this.currentStep.loading = false;
          this.changes.detectChanges();
        }),
      )
      .pipe(delay(10))
      .subscribe((project: Project) => {
        this.showSuccessMessage();
        if (!!callback) {
          callback(project);
        } else {
          const step = this.service.stepper.currentStep - 1;
          this.router.navigate(['.'], { relativeTo: this.active, queryParams: { step } }).then(() => {
            this.enableInstantScroll();
          });
          this.updateStepAndProject(step, project);
        }
      });
  }

  showSuccessMessage() {
    // If user had interaction with form and submitted
    if (!!this.isCurrentStepUnsaved) {
      this.s.success(this.t.instant('ProjectCreator.stepSaved'));
    }
    setTimeout(() => (this.currentStep.dataChanged = false), 10);
  }

  saveAndStay() {
    if (!this.hasEditPermissionForCurrentStep) return;

    this.updateControls();
    if (this.currentStep?.form?.invalid || this.currentStep?.additionalForm?.invalid) {
      this.scrollToError();
      return;
    }

    this.currentStep.loading = true;
    this.changes.detectChanges();
    const method = this.internalCreator
      ? this.currentStep.submitInternal(this.project?.id, this.service.stepper.currentStep, false)
      : this.currentStep.submit(this.token);

    method.pipe(delay(10)).subscribe(
      (project: Project) => {
        this.showSuccessMessage();
        this.updateStepAndProject(this.service.stepper.currentStep, project);
        setTimeout(() => {
          this.currentStep.loading = false;
          this.currentStep.dataChanged = false;
          this.changes.detectChanges();
        }, 200);
      },
      () => {
        this.currentStep.loading = false;
        this.changes.detectChanges();
      },
    );
  }

  scrollToError() {
    setTimeout(() => {
      this.changes.detectChanges();
      const el = document.querySelectorAll('.form-control.has-error,.ng-select--error')[0];
      if (el && el.parentElement) {
        el.parentElement.scrollIntoView();
        el.querySelector('input').focus();
      }
    }, 10);
  }

  ngOnDestroy(): void {
    this.store.deleteSharedToken();
    this.sub?.unsubscribe();
  }
}
