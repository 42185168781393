import { TabLink } from '@shared/interfaces/menu/tab-link.interface';
import { PermissionsGroups } from '@core/permissions/permissions.group';

export const HeaderConfig: { [key: string]: TabLink } = {
  contacts: {
    name: 'Header.contacts',
    link: 'contacts-list',
    iconUrl: '/assets/img/ic-menu-contact.svg',
    permissionGroup: PermissionsGroups.CONTACTS,
    permissionAction: 'LIST',
  },
  myJob: {
    name: 'Header.myJob',
    link: 'my-work',
    iconUrl: '/assets/img/ic-menu-job.svg',
    permissionGroup: PermissionsGroups.MY_WORK,
    permissionAction: 'LIST',
  },
  calendar: {
    name: 'Header.calendar',
    link: 'calendar',
    iconUrl: '/assets/img/ic-menu-calendar.svg',
    permissionGroup: PermissionsGroups.CALENDAR,
    permissionAction: 'PREVIEW',
  },
  projects: {
    name: 'Header.projects',
    link: 'projects-list',
    iconUrl: '/assets/img/ic-menu-project.svg',
    permissionGroup: PermissionsGroups.PROJECTS,
    permissionAction: 'LIST',
  },
  protocols: {
    name: 'Header.protocols',
    link: 'protocols-list',
    permissionGroup: PermissionsGroups.PROTOCOLS,
    iconUrl: '/assets/img/ic-menu-protocol.svg',
    permissionAction: 'LIST',
  },
};
