import { ActivatedRoute } from '@angular/router';
import { WindowHelper } from '@shared/helpers/window.helper';
import { debounce } from '@shared/decorators/debounce.decorator';
import { TaskSidenavService } from '@shared/modules/task-sidenav/services/task-sidenav.service';
import { ButtonTypes, ButtonSize } from '@shared/modules/ui/components/button/button.component';
import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  HostListener,
  Renderer2,
} from '@angular/core';
import { SIDENAV_DATA } from '@shared/services/sidenav/sidenav.data';

@Component({
  styleUrls: ['./task-sidenav.component.scss'],
  templateUrl: './task-sidenav.component.html',
})
export class TaskSidenavComponent implements OnInit, OnDestroy, AfterViewInit {
  static componentName: string = 'TaskSidenavComponent';

  WindowHelper = WindowHelper;
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  @ViewChild('whiteSidenav', { read: ElementRef }) whiteSidenav: ElementRef;
  @ViewChild('blueSidenav', { read: ElementRef }) blueSidenav: ElementRef;

  constructor(
    @Inject(SIDENAV_DATA) public sideData: SIDENAV_DATA,
    public service: TaskSidenavService,
    private renderer: Renderer2,
    private active: ActivatedRoute,
  ) {}

  ngOnInit() {
    this._setTaskConfig();
  }

  ngAfterViewInit() {
    this._setCurrentPosition();
  }

  private _setTaskConfig() {
    const config = this.active?.snapshot?.queryParams?.config
      ? JSON.parse(this.active.snapshot.queryParams.config)
      : null;
    config.extra?.type ? (this.service.TaskConfigType = config.extra?.type) : null;
    const options = Object.assign(this.sideData?.data, { extra: { type: this.service.TaskConfigType } });
    this.service.setInitialData(options);
  }

  private _setCurrentPosition() {
    const whiteSidenav: HTMLElement = this.whiteSidenav?.nativeElement;
    const blueSidenav: HTMLElement = this.blueSidenav?.nativeElement;
    let parent = null;
    let child = null;

    if (!WindowHelper.isLessMD) {
      parent = blueSidenav;
      child = whiteSidenav.querySelector('.task__sidenav__blue');
    } else {
      parent = whiteSidenav.querySelector('.task-sidenav-white-content-appendable');
      child = blueSidenav.querySelector('.task__sidenav__blue');
    }
    parent && child ? this.renderer.appendChild(parent, child) : null;
  }

  @HostListener('window:resize', ['$event'])
  onDesktopResize() {
    this._setCurrentPosition();
  }

  @HostListener('window:orientationchange', ['$event'])
  @debounce(500)
  onMobileRotate() {
    this._setCurrentPosition();
  }

  ngOnDestroy() {
    this.service.resetTaskSidenav();
    this.service.resetDefaultValuesWithinSidenav();
  }
}
