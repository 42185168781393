import { EventEmitter, Injectable } from '@angular/core';
import { Task } from '@shared/models/task.model';
import { TaskGroup } from '@shared/models/task-group.model';
import { ListGroupTask } from '@shared/modules/list/model/list-group-task.model';
import { TranslateService } from '@ngx-translate/core';
import { TaskStatus } from '@shared/enums/task-status.enum';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import * as moment from 'moment';
import { Config } from '@shared/configs/config';
import { ETaskTypeGroup } from '@shared/modules/task-sidenav/enums/task-group.enum';

@Injectable({
  providedIn: 'root',
})
export class ListTaskService {
  projectId: number;
  isMyWorkTaskLists: boolean = false;
  emitter: EventEmitter<ListTaskEvent> = new EventEmitter<ListTaskEvent>();

  static LIST_GROUP_TASK_PREFIX = 'lgt_';
  static TASK_PREFIX = 't_';

  private _listGroupTasksHashMap: Map<string, ListGroupTask> = new Map<string, ListGroupTask>();
  private _tasksHashMap: Map<string, Task> = new Map<string, Task>();
  private _completedTasksHashMap: Map<string, Task> = new Map<string, Task>();
  public originalData: ListGroupTask[];

  get tasksHashMap() {
    return this._tasksHashMap;
  }

  get completedTasksHashMap() {
    return this._completedTasksHashMap;
  }

  get listGroupTasksHashMap(): Map<string, ListGroupTask> {
    return this._listGroupTasksHashMap;
  }

  set listGroupsTask(listGroupsTask: ListGroupTask[]) {
    listGroupsTask = this.prepareGroupTasksForMyWork(listGroupsTask);
    this.setHashMaps(listGroupsTask);
  }

  constructor(private t: TranslateService) {}

  getTask(taskId: number | string): Task | null {
    return new Map([...this.tasksHashMap, ...this.completedTasksHashMap]).get(
      `${ListTaskService.TASK_PREFIX}${taskId}`,
    );
  }

  updateTask(task: Task): void {
    if (this.tasksHashMap.get(this.getKeyForTask(task))) {
      this.tasksHashMap.set(this.getKeyForTask(task), new Task(task));
    }
    if (this.completedTasksHashMap.get(this.getKeyForTask(task))) {
      this.completedTasksHashMap.set(this.getKeyForTask(task), new Task(task));
    }

    //#region NEED to Update Parent HashGroup
    !this.isMyWorkTaskLists ? this.updateTaskInHashGroup(task) : null;
    // #endregion
  }

  addTask(task: Task): void {
    if (task.status === TaskStatus.COMPLETED) {
      this.completedTasksHashMap.set(this.getKeyForTask(task), new Task(task));
    } else {
      this.tasksHashMap.set(this.getKeyForTask(task), new Task(task));
    }

    //#region NEED to Update Parent HashGroup
    !this.isMyWorkTaskLists ? this.addTaskToHashGroup(task) : null;
    // #endregion
  }

  updateTaskInHashGroup(task: Task) {
    const hashGroup = this.listGroupTasksHashMap.get(
      `${ListTaskService.LIST_GROUP_TASK_PREFIX}${task.taskGroup.id}`,
    );
    const findIndex = hashGroup.tasks.findIndex((i) => i.id === task.id);
    if (findIndex > -1) {
      hashGroup.tasks[findIndex] = task;
    }
    this.listGroupTasksHashMap.set(
      `${ListTaskService.LIST_GROUP_TASK_PREFIX}${task.taskGroup.id}`,
      hashGroup,
    );
  }

  addTaskToHashGroup(task: Task) {
    const hashGroup = this.listGroupTasksHashMap.get(
      `${ListTaskService.LIST_GROUP_TASK_PREFIX}${task.taskGroup.id}`,
    );
    hashGroup.tasks.push(task);

    this.listGroupTasksHashMap.set(
      `${ListTaskService.LIST_GROUP_TASK_PREFIX}${task.taskGroup.id}`,
      hashGroup,
    );
  }

  removeTask(task: Task) {
    this.tasksHashMap.delete(this.getKeyForTask(task));
    this.completedTasksHashMap.delete(this.getKeyForTask(task));
    !this.isMyWorkTaskLists ? this.removeTaskFromHashGroup(task) : null;
  }

  removeTaskFromHashGroup(task: Task) {
    const hashGroup = this.listGroupTasksHashMap.get(
      `${ListTaskService.LIST_GROUP_TASK_PREFIX}${task.taskGroup.id}`,
    );
    const findIndex = hashGroup.tasks.findIndex((i) => i.id === task.id);
    if (findIndex > -1) {
      hashGroup.tasks = hashGroup.tasks.filter((i) => i.id !== task.id);
    }
    this.listGroupTasksHashMap.set(
      `${ListTaskService.LIST_GROUP_TASK_PREFIX}${task.taskGroup.id}`,
      hashGroup,
    );
  }

  getKeyForTask(task: Task) {
    return `${ListTaskService.TASK_PREFIX}${task.id}`;
  }

  prepareGroupTasksForMyWork(listGroupsTask: ListGroupTask[]) {
    listGroupsTask.map((listGroupTask: ListGroupTask) => {
      if (!listGroupTask.taskGroup) {
        listGroupTask.taskGroup = new TaskGroup({
          name: listGroupTask.withProject
            ? this.t.instant('Projects.tasksWithProject')
            : this.t.instant('Projects.tasksWithoutProject'),
          sortOrder: listGroupTask.withProject ? 1 : 2,
          id: listGroupTask.withProject ? 1 : 2,
        });
      }

      listGroupTask.projectGroup = listGroupTask?.withProject === undefined;
      return listGroupTask;
    });
    return listGroupsTask;
  }

  private setHashMaps(listGroupTasks: ListGroupTask[]) {
    // #region Hide Change in Tasks
    listGroupTasks = listGroupTasks.filter((i) => i.taskGroup.name !== ETaskTypeGroup.GROUP_CHANGE);
    // #endregion

    this.originalData = listGroupTasks;

    const existsListGroupTasksKeys = [];
    const existsTasksKeys = [];
    const existsCompletedTasksKeys = [];
    for (let i = 0; i < listGroupTasks.length; i++) {
      const listGroupTask = listGroupTasks[i];
      existsListGroupTasksKeys.push(`${ListTaskService.LIST_GROUP_TASK_PREFIX}${listGroupTask.taskGroup.id}`);

      listGroupTask.currentAllCount = listGroupTask.tasks.length + listGroupTask.completedTasks.length;
      listGroupTask.currentCompletedCount = listGroupTask.completedTasks.length;
      this.listGroupTasksHashMap.set(
        `${ListTaskService.LIST_GROUP_TASK_PREFIX}${listGroupTask.taskGroup.id}`,
        listGroupTask,
      );

      for (let j = 0; j < listGroupTask.tasks.length || j < listGroupTask.completedTasks.length; j++) {
        const task = listGroupTask.tasks[j];
        const completedTask = listGroupTask.completedTasks[j];

        if (isNotNullOrUndefined(task)) {
          existsTasksKeys.push(this.getKeyForTask(task));
          this.tasksHashMap.set(this.getKeyForTask(task), new Task(task));
        }

        if (isNotNullOrUndefined(completedTask)) {
          existsCompletedTasksKeys.push(this.getKeyForTask(completedTask));
          this.completedTasksHashMap.set(this.getKeyForTask(completedTask), new Task(completedTask));
        }

        this.setSubtasksHashmap(task, completedTask, existsTasksKeys, existsCompletedTasksKeys);
      }
    }
    this.removeNotExistsTasksFromHashmap(existsListGroupTasksKeys, existsTasksKeys, existsCompletedTasksKeys);
    this.sortTaskInHashmap();
  }

  private setSubtasksHashmap(
    task: Task,
    completedTask: Task,
    existsTasksKeys: string[],
    existsCompletedTasksKeys: string[],
  ) {
    for (let k = 0; k < task?.children.length || k < completedTask?.children.length; k++) {
      const subtask = task ? task.children[k] : null;
      const completedSubtask = completedTask ? completedTask.children[k] : null;
      if (isNotNullOrUndefined(subtask)) {
        existsTasksKeys.push(this.getKeyForTask(subtask));
        this.tasksHashMap.set(this.getKeyForTask(subtask), new Task(subtask));
      }

      if (isNotNullOrUndefined(completedSubtask)) {
        existsCompletedTasksKeys.push(this.getKeyForTask(completedSubtask));
        this.completedTasksHashMap.set(this.getKeyForTask(completedSubtask), new Task(completedSubtask));
      }

      this.setSubtasksHashmap(subtask, completedSubtask, existsTasksKeys, existsCompletedTasksKeys);
    }
  }

  sortTaskInHashmap() {
    let structuralData: { [key: string]: { tasks: Task[]; completedTasks: Task[] } } = {};
    const foreach = (map: Map<string, Task>) => {
      map.forEach((task: Task, key: string) => {
        let groupId = task?.taskGroup?.id;
        if (this.isMyWorkTaskLists) {
          groupId = task.project ? 1 : 2;
        }
        if (!isNotNullOrUndefined(structuralData[groupId])) {
          structuralData[groupId] = {
            tasks: [],
            completedTasks: [],
          };
        }
        if (task.status === TaskStatus.COMPLETED) {
          structuralData[groupId].completedTasks.push(task);
        } else {
          structuralData[groupId].tasks.push(task);
        }
      });
    };
    foreach(this.tasksHashMap);
    foreach(this.completedTasksHashMap);

    Object.values(structuralData).map((item: { tasks: Task[]; completedTasks: Task[] }) => {
      const sorting = (arr: Task[]) => {
        let highPriorityTasks = arr.filter((t) => t.isHighPriority).sort(this.sortTasks.bind(this));
        let notHighPriorityTasks = arr.filter((t) => !t.isHighPriority).sort(this.sortTasks.bind(this));
        arr = highPriorityTasks.concat(notHighPriorityTasks);
        arr.map((t: Task, index) => {
          t.taskGroupOrder = index;
          this.updateTask(t);
          return t;
        });
        return arr;
      };
      item.tasks = sorting(item.tasks);
      item.completedTasks = sorting(item.completedTasks);
    });
  }

  /**
   *Sorting from Oldest to Newest
   *
   * @private
   * @param {Task} a
   * @param {Task} b
   * @return {*}
   * @memberof ListTaskService
   */
  private sortTasks(a: Task, b: Task) {
    return moment(b.created, Config.DATE_SERVER).isAfter(moment(a.created, Config.DATE_SERVER)) ? -1 : 1;
  }

  private removeNotExistsTasksFromHashmap(
    existsListGroupTasksKeys: string[],
    existsTasksKeys: string[],
    existsCompletedTasksKeys: string[],
  ) {
    this.removeListGroupTasksFromHashMap(existsListGroupTasksKeys);
    this.removeTaskFromHashmap(existsTasksKeys);
    this.removeCompletedTaskFromHashmap(existsCompletedTasksKeys);
  }

  private removeTaskFromHashmap(existsKeys: string[]) {
    for (const [key, task] of this.tasksHashMap) {
      if (existsKeys.indexOf(key) === -1) {
        this.tasksHashMap.delete(key);
      }
    }
  }

  private removeCompletedTaskFromHashmap(existsKeys: string[]) {
    for (const [key, task] of this.completedTasksHashMap) {
      if (existsKeys.indexOf(key) === -1) {
        this.completedTasksHashMap.delete(key);
      }
    }
  }

  private removeListGroupTasksFromHashMap(existsKeys: string[]) {
    for (const [key, listGroupTasks] of this.listGroupTasksHashMap) {
      if (existsKeys.indexOf(key) === -1) {
        this.listGroupTasksHashMap.delete(key);
      }
    }
  }

  clearGroups() {
    this._tasksHashMap.clear();
    this._completedTasksHashMap.clear();
    this._listGroupTasksHashMap.clear();
  }

  clearService() {
    this.projectId = null;
    this.clearGroups();
  }
}

export class ListTaskEvent {
  type: ListTaskEventType;
  data?: Task | TaskGroup | any;
}

export enum ListTaskEventType {
  REFRESH_LIST_GROUPS,
  ADD_TASK,
  ADD_SUB_TASK,
  ADD_TASK_GROUP,
  UPDATE_TASK,
  REMOVE_TASK,
  UPDATE_COUNTERS,
  ASSIGNEE_CHANGE,
  UPDATE_TASK_ATTACHMENTS,
}
