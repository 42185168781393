<div class="d-flex align-content-start align-md-content-center flex-column flex-md-row flex-wrap">
  <list-single-filter-select
    class="w-100 w-sm-auto"
    [name]="'ProjectTasks.Filters.createdBy' | translate"
    [iconUrl]="'/assets/img/ic-person-circle-outline.svg'"
    [form]="service?.filtersForm"
    [controlName]="'[creator][eq]'"
    (change)="onChangeFilter()"
    [searchGetParams]="'search'"
    [requiredIdUrl]="Config.API + '/employee/search'"
    [valueTransform]="creatorName.bind(this)"
  ></list-single-filter-select>

  <list-single-filter-select
    class="w-100 w-sm-auto"
    #assigneeFilter
    [name]="'ProjectTasks.Filters.assigneeTo' | translate"
    [iconUrl]="'/assets/img/ic-person-circle-outline.svg'"
    [form]="service?.filtersForm"
    [multiple]="true"
    [controlName]="'[assigned][in][]'"
    (change)="onChangeFilter()"
    [responseTransform]="responseTransformAssignee.bind(this)"
    [searchGetParams]="'query'"
    [requiredIdUrl]="Config.API + '/project/'+service?.data?.projectId+'/tasks-employees'"
    [valueTransform]="creatorName.bind(this)"
  ></list-single-filter-select>

  <list-single-filter-select
    class="w-100 w-sm-auto"
    [name]="'ProjectTasks.Filters.status' | translate"
    [iconUrl]="'/assets/img/ic-checkmark-circle-outline.svg'"
    [form]="service?.filtersForm"
    [localItems]="taskStatuses"
    [multiple]="true"
    [bindValue]="'id'"
    [bindLabel]="'name'"
    [controlName]="'[a-status][in][]'"
    (change)="onChangeFilter()"
  ></list-single-filter-select>

  <list-single-filter-select
    class="w-100 w-sm-auto"
    [name]="'MyWork.Filters.type' | translate"
    [iconUrl]="'/assets/img/ic-checkmark-circle-outline.svg'"
    [form]="service?.filtersForm"
    [localItems]="taskTypes"
    [bindValue]="'id'"
    [bindLabel]="'name'"
    [controlName]="'[a-type][eq]'"
    (change)="onChangeFilter()"
  ></list-single-filter-select>

  <list-single-filter-select
    #tagFilter
    class="w-100 w-sm-auto"
    [name]="'ProjectTasks.Filters.tags' | translate"
    [iconUrl]="'/assets/img/ic-tag.svg'"
    [form]="service?.filtersForm"
    [controlName]="'[tag-name][in][]'"
    [multiple]="true"
    (change)="onChangeFilter()"
    [searchGetParams]="'query'"
    [bindValue]="'name'"
    [bindLabel]="'name'"
    [requiredIdUrl]="Config.API + '/tag/type?type=task-tag&projectId='+listTaskService?.projectId"
  ></list-single-filter-select>

  <list-single-clear-filters
    [form]="service?.filtersForm"
    (change)="onChangeFilter()"
    [shouldShowClearFilters]="shouldShowClearFilters.bind(this)"
    [clearFilters]="clearFilters.bind(this)"
  >
  </list-single-clear-filters>
</div>
