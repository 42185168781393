import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { LanguageService } from '@core/services/language.service';
import { RegisterController } from '@modules/authorization/shared/controllers/register.controller';

@Component({
  templateUrl: './register.component.html',
})
export class RegisterComponent extends BaseComponent implements OnInit {
  ctrl: RegisterController;

  constructor(private langService: LanguageService) {
    super();
    this.ctrl = new RegisterController();
  }

  ngOnInit() {
    this.ctrl.createForm();
    this.ctrl.setValidatorsSimple();
    this.initLanguage();
  }

  initLanguage() {
    const lang = this.langService.getInitLanguage();
    this.langService.useLanguage(lang).subscribe();
  }
}
