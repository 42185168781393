<div class="list-tasks">
  <list-filter
    [component]="filters"
    *ngIf="filters"
    [searchName]="searchName"
    [isSearch]="isSearch"
    #filtersComponent
  ></list-filter>
  <div class="list-tasks-dynamic-container" #dropzone dndDropzone (dndDrop)="dropEvent($event)">
    <div dndPlaceholderRef class="list-tasks-group--placeholder"></div>
    <ng-template #container></ng-template>
  </div>
</div>
