import { PermissionsGroups } from '@core/permissions/permissions.group';
import { Config } from '@shared/configs/config';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { EventSidenavService } from '@shared/modules/event-sidenav/services/event-sidenav.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { EventSidenavController } from '@shared/modules/event-sidenav/controllers/event-sidenav.controller';
import { ProjectController } from '@modules/projects/shared/controllers/project.controller';

@Component({
  selector: 'event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss'],
})
export class EventDetailsComponent implements OnInit {
  PermissionsGroups = PermissionsGroups;
  eventCtrl: EventSidenavController = new EventSidenavController();

  constructor(public service: EventSidenavService, private t: TranslateService) {}

  ngOnInit(): void {}

  editOpen() {
    this.eventCtrl.openEdit(this.service.calendarEvent.id);
  }

  goToProjectSummary() {
    const ctrl = new ProjectController(this.service.calendarEvent.project);
    ctrl.preview(this.service.calendarEvent.project);
  }
}
