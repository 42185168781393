<div
  class="sidenav-title d-flex align-items-center justify-content-between flex-md-row"
  [ngClass]="{ 'flex-row': !columnOnMobile, 'flex-column': columnOnMobile }"
>
  <div class="d-flex align-items-center w-100 w-sm-auto">
    <div
      class="sidenav-title-close mr-1 mr-md-3 align-content-center justify-content-center"
      (click)="!!closeFunc ? closeFunc() : sidenav.close()"
    >
      <img draggable="false" src="/assets/img/ic-hide.svg" alt="'Global.hideSidenav'" />
    </div>
    <h2 class="blue-500 mr-3" [innerHTML]="title"></h2>
  </div>
  <ng-container *ngIf="template" [ngTemplateOutlet]="template"></ng-container>
</div>
