import { Injectable } from '@angular/core';

import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { NavigateService } from '@shared/services/navigate.service';
import { StorageService } from '@core/services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '@core/services/snackbar.service';
import { CheckPermission } from '@core/permissions/check-permission';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { AppTourService } from '@shared/modules/app-tour/shared/services/app-tour.service';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { ProtocolApiService } from '@modules/protocols/shared/services/protocol-api.service';

@Injectable({ providedIn: 'root' })
export class ProtocolDraftGuard implements CanActivate {
  constructor(
    private n: NavigateService,
    private pService: ProtocolApiService,
    private store: StorageService,
    private s: SnackBarService,
    private t: TranslateService,
    private appTourService: AppTourService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    this.pService.manager.clearService();
    this.pService.loadingProtocol = true;
    const protocolDraft = this.handleRequest(
      this.pService.getProtocolDraft(route.params.id).pipe(
        tap(() => {
          this.pService.loadingProtocol = false;
        }),
      ),
    );

    const employeeSearch = this.handleRequest(this.pService.getEmployees(''));

    return combineLatest([protocolDraft, employeeSearch]).pipe(
      map((i) => {
        if (isNotNullOrUndefined(this.pService.manager.protocol?.deleted)) {
          this.s.error(this.t.instant('Protocols.errorProtocolDeleted'));
          this.n.navigate('protocols-list-all');
          return false;
        }

        const ctrl = new CheckPermission({
          action: 'EDIT',
          group: PermissionsGroups.PROTOCOLS,
          objectCreatorId: [
            this.pService.manager.protocol?.creator?.id,
            this.pService.manager?.protocol?.project?.basicDataBox?.responsibleEmployee?.id,
            this.pService.manager?.protocol?.project?.basicDataBox?.mainContact?.id,
            ...this.pService.manager?.protocol?.taskAssignedIds
          ],
        });

        if (route.params.id == 0 || (ctrl.check() && route.data.edit)) {
          //id = 0 for tutorial
          this.pService.manager.$hideHiddenTasks = route?.queryParams?.sign === 'true';
          return true;
        } else {
          this.s.error(this.t.instant('Protocols.noPermissionEdit'));
          this.n.navigate('protocols-list-all');
          return false;
        }
      }),
    );
  }

  handleRequest(observable: Observable<any>): Observable<any> {
    return observable.pipe(
      catchError((err) => {
        this.n.navigate('protocols-list-all');
        return of(null);
      }),
    );
  }
}
