<div class="task-changes-summary">
  <div class="task-changes-summary__box">
    <div class="d-flex align-center flex-column justify-content-center">
      <div class="text-center">
        <img draggable="false" src="./assets/img/ic-instruments.svg" alt="instruments" />
      </div>
      <div class="mt-2">
        {{ 'ProjectChanges.change' | translate }} <br />

        <ng-container *ngIf="service.task?.price === 0">
          <span>{{ 'Financial.withoutInfluence' | translate }}</span>
        </ng-container>

        <ng-container *ngIf="service.task?.price > 0">
          <span>{{ 'Financial.additionalCost' | translate }}</span>
        </ng-container>

        <ng-container *ngIf="service.task?.price < 0">
          <span>{{ 'Financial.savedCost' | translate }}</span>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="task-changes-summary__extra">
    <div class="task-changes-summary__extra-title">{{ 'ProjectChanges.dataOfChange' | translate }}</div>
    <div *ngIf="service.task?.source || service.task?.protocol" class="task-changes-summary__extra-row">
      <div class="task-changes-summary__extra-row-title">{{ 'ProjectChanges.List.source' | translate }}</div>
      <div>
        <a
          draggable="false"
          class="no-select"
          [ngClass]="{ 'disabled-link': !this.protocolPreviewPermission }"
          (click)="this.protocolPreviewPermission ? preview(service.task?.protocol) : null"
          *ngIf="service.task?.source === ChangeSource.SOURCE_PROTOCOL || service.task?.protocol"
        >
          {{ 'Protocols.Types.' + service.task?.protocol?.type | translate }}
          {{ service.task?.protocol?.idNumber ? service.task?.protocol?.idNumber : '' }}
        </a>
        <ng-container
          *ngIf="
            service.task?.source &&
            service.task?.source !== ChangeSource.SOURCE_PROTOCOL &&
            !service.task?.protocol
          "
        >
          {{ 'Projects.ProjectChangeSource.' + service.task?.source | translate }}
        </ng-container>
      </div>
    </div>

    <div *ngIf="service.permissionCtrl.canSeePrice" class="task-changes-summary__extra-row">
      <div class="task-changes-summary__extra-row-title">
        {{ 'ProjectChanges.priceOfChange' | translate }}
      </div>
      <div>
        <price-cell
          [price]="service?.task?.price"
          [isLocalCurrency]="true"
          [customText]="
            !service.task?.isPriceEstimated && service?.task?.price === 0
              ? ('Financial.withoutEstimation' | translate)
              : ''
          "
        ></price-cell>
      </div>
    </div>

    <div class="task-changes-summary__extra-row">
      <div class="task-changes-summary__extra-row-title">
        {{ 'ProjectChanges.additionalDays' | translate }}
      </div>
      <div>
        <day-cell
          [day]="service.task?.additionalTime"
          [customText]="
            !service.task?.isAdditionalTimeEstimated &&
            service.task?.additionalTime?.toString() === 0?.toString()
              ? ('Financial.withoutEstimation' | translate)
              : ''
          "
        ></day-cell>
      </div>
    </div>

    <div class="task-changes-summary__extra-row">
      <div class="task-changes-summary__extra-row-title">
        {{ 'ProjectChanges.sharedChange' | translate }}
      </div>
      <div>
        {{
          (service.task?.isTaskShared || service.task?.isChangeStatusAccepted
            ? 'Projects.yes'
            : 'Projects.no'
          ) | translate
        }}
      </div>
    </div>

    <div class="task-changes-summary__extra-row">
      <div class="task-changes-summary__extra-row-title">
        {{
          (service.task?.isChangeStatusAccepted || !service?.task?.acceptanceDate
            ? 'ProjectChanges.acceptChange'
            : 'ProjectChanges.rejectChange'
          ) | translate
        }}
      </div>
      <div>
        {{
          !service?.task?.acceptanceDate
            ? ('Projects.noDate' | translate)
            : (service?.task?.acceptanceDate | dateTimeFormat)
        }}
      </div>
    </div>

    <div
      *ngIf="service?.task?.acceptedEmail || service?.task?.acceptedContact"
      class="task-changes-summary__extra-row"
    >
      <div class="task-changes-summary__extra-row-title">
        {{ 'ProjectChanges.acceptWith' | translate }}
      </div>
      <div>
        {{
          service?.task?.acceptedContact
            ? service?.task?.acceptedContact?.fullName
            : service?.task?.acceptedEmail
        }}
      </div>
    </div>
  </div>
</div>
