<div class="task__sidenav__white">
  <!-- #region Content -->
  <div class="task__sidenav__white__content">
    <!-- #region Create State -->
    <task-sidenav-white-content
      (onSubmit)="submitForm()"
      [hidden]="
        !((service.isPreview && !!service.task) || service.isCreation || (service.isEdit && !!service.task))
      "
    ></task-sidenav-white-content>
    <!-- #endregion -->
  </div>
  <!-- #endregion -->

  <!-- #region Footer -->
  <div class="sticky-footer d-flex" *ngIf="service.isCreation || (service.isEdit && !!service.task)">
    <div class="col-6">
      <app-button
        (onClick)="service.discardSaving()"
        class="bold w-100"
        [config]="{
          size: ButtonSize.LARGE,
          type: ButtonTypes.DISCARD_BUTTON,
          text: 'Projects.discard' | translate
        }"
      ></app-button>
    </div>

    <div class="col-6">
      <app-button
        class="bold w-100"
        (onClick)="submitForm()"
        [disabled]="service.loading || !!service?.task?.isDeleted"
        [loading]="service.loading"
        [config]="{
          size: ButtonSize.LARGE,
          type: ButtonTypes.PRIMARY_BLUE,
          text: 'Projects.saveAndEnd' | translate,
          prependSrc: './assets/img/ic-save.svg'
        }"
      ></app-button>
    </div>
  </div>
  <!-- #endregion -->
</div>
