import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Regex } from '@shared/configs/regex';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { BaseComponent } from '@shared/components/base.component';
import { AuthorizationService } from '@modules/authorization/shared/service/authorization.service';
import { Config } from '@shared/configs/config';
import { JWT } from '@shared/interfaces/jwt.interface';
import { HttpError } from '@shared/interfaces/error.interface';
import { BaseErrors } from '@shared/enums/base-errors.enum';
import { LanguageService } from '@core/services/language.service';
import { RedirectHelper } from '@shared/helpers/redirect-helper';

@Component({
  templateUrl: './login.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent extends BaseComponent implements OnInit {
  form: FormGroup;

  constructor(
    private changes: ChangeDetectorRef,
    private recaptchaV3Service: ReCaptchaV3Service,
    private authService: AuthorizationService,
    private langService: LanguageService,
    private active: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = new FormGroup({
      username: new FormControl('', [Validators.required, Validators.pattern(Regex.email)]),
      password: new FormControl(''),
      remember: new FormControl(false),
      captcha: new FormControl(''),
    });
  }

  submitLogin() {
    const pass = this.form.get('password').value;
    this.form.get('password').setValue(pass ? pass : '');

    Object.keys(this.form.controls).forEach((key: string) => {
      this.form.controls[key].markAsTouched();
      this.form.controls[key].updateValueAndValidity();
    });

    if (this.form.invalid) {
      return;
    }
    this.loading = true;
    this.changes.detectChanges();
    if (!Config.DEBUG) {
      this.captchaValidation()
        .then((token: string) => {
          this.form.controls.captcha.setValue(token);
          this.changes.detectChanges();

          if (!token.length) {
            this.s.error(this.t.instant('Auth.Errors.captchaValidation'));
            return;
          }
          this.signInHandler();
        })
        .catch(() => this.signInHandler());
    } else {
      this.signInHandler();
    }
  }

  captchaValidation() {
    return this.recaptchaV3Service.execute('signIn').toPromise();
  }

  signInHandler() {
    this.authService
      .signIn(this.form.value)
      .subscribe({
        next: (jwt: JWT) => this.successSignIn(jwt),
        error: (err: HttpError) => this.errorSignIn(err),
      })
      .add(() => {
        this.loading = false;
        this.changes.detectChanges();
      });
  }

  successSignIn(jwt: JWT) {
    this.store.setSignedIn(jwt);
    setTimeout(() => this.initLanguageAfterAuth(jwt), 10);
  }

  initLanguageAfterAuth(jwt: JWT) {
    this.langService
      .useLanguage(jwt.userPerson.language)
      .subscribe()
      .add(() => {
        this.s.success(this.t.instant('Auth.Messages.successSignIn'));
        this.n.navigate('company-selection', this.prepareRedirectQueryParams());
        this.changes.detectChanges();
      });
  }

  private prepareRedirectQueryParams(): any {
    const redirect = this.active?.snapshot?.queryParams?.redirectTo;
    const exclude = ['sign-in'];
    return !!redirect && exclude.indexOf(redirect) === -1 ? { redirectTo: redirect } : {};
  }

  errorSignIn(err: HttpError) {
    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();
    this.changes.detectChanges();
    switch (err.messageCode) {
      case BaseErrors.CREDENTIALS:
        this.s.error(this.t.instant('Auth.Errors.errorCredentials'));
        break;
      case BaseErrors.DISABLED:
        this.s.error(this.t.instant('Auth.Errors.accountNotConfirmed'));
        break;
      default:
        this.s.error(this.t.instant('Auth.Errors.serverError'));
        break;
    }
    this.changes.detectChanges();
  }

  get errorMessages() {
    return Config.validationMessages;
  }
}
