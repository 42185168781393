import { Config } from '@shared/configs/config';
import { ListConfig } from '@shared/modules/list/interfaces/list-config.interface';
import { ProjectToggleMenuCellComponent } from '@project-modules/projects-list/pages/projects-list/components/project-toggle-menu-cell/project-toggle-menu-cell.component';
import { ColumnMode } from '@swimlane/ngx-datatable';

export const ProjectsListConfig: ListConfig = {
  columns: [
    {
      prop: 'basicDataBox.propertyType',
      name: 'ProjectsList.Columns.type',
      componentRef: 'projectPropertyTypeCell',
      sortField: 'basicDataBox.propertyType',
      resizeable: false,
      hideOnMobile: true,
      width: 100,
      order: 1,
    },
    {
      prop: 'basicDataBox.mainContact',
      name: 'ProjectsList.Columns.client',
      componentRef: 'projectClientCell',
      sortField: 'mainContact.name',
      resizeable: false,
      hideOnMobile: true,
      width: 180,
      order: 2,
    },
    {
      prop: 'address',
      name: 'ProjectsList.Columns.address',
      componentRef: 'projectAddressCell',
      sortField: 'a.address',
      resizeable: false,
      hideOnMobile: true,
      width: 280,
      order: 3,
    },
    {
      prop: 'name',
      name: 'ProjectsList.Columns.name',
      componentRef: 'projectNameCell',
      headerCellComponentRef: 'projectNameHeaderCell',
      sortField: 'a.name',
      resizeable: false,
      canNotShow: false,
      hideOnMobile: false,
      width: 250,
      mobileWidth: 300,
      order: 4,
    },
    {
      prop: 'projectId',
      name: 'ProjectsList.Columns.id',
      sortField: 'a.projectId',
      resizeable: false,
      cellClass: 'no-select',
      hideOnMobile: true,
      width: 80,
      order: 5,
    },
    {
      prop: 'stage',
      name: 'ProjectsList.Columns.stage',
      componentRef: 'projectStageCellComponent',
      sortable: false,
      resizeable: false,
      hideOnMobile: true,
      width: 170,
      order: 6,
    },
    {
      prop: 'responsibleContact',
      name: 'ProjectsList.Columns.responsibleClient',
      componentRef: 'projectResponsibleClientCell',
      sortField: 'responsibleContact.name',
      resizeable: false,
      hideOnMobile: true,
      width: 180,
      order: 7,
    },
    {
      prop: 'basicDataBox.responsibleEmployee',
      name: 'ProjectsList.Columns.responsibleCompany',
      componentRef: 'projectResponsibleCompanyCell',
      sortField: 'responsibleUser.name',
      resizeable: false,
      hideOnMobile: true,
      width: 170,
      order: 8,
    },
    {
      prop: 'failureReason',
      name: '',
      componentRef: 'projectLoseCell',
      sortable: false,
      resizeable: false,
      hideOnMobile: true,
      hideNotShow: true,
      width: 150,
      order: 9,
    },
    {
      toggleMenu: true,
      component: ProjectToggleMenuCellComponent,
      maxWidth: 0,
      width: 0,
      mobileWidth: 0,
      sortable: false,
      order: 10,
    },
  ],
  url: `${Config.API}/project/search-advanced`,
  scrollbarH: true,
  columnMode: ColumnMode.standard,
  responseKey: 'records',
  listName: 'projects-list',
  listTitle: 'ProjectsList.allStages',
  desktopRowHeight: 80,
};
