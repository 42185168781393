<!-- #region Container -->
<div
  class="d-flex project__task__sidenav"
  [ngClass]="{ 'project__task__sidenav--full-width': WindowHelper.isLessMD }"
>
  <sidenav-loading *ngIf="service.loadingTask" [cover]="true"></sidenav-loading>

  <!-- #region Left Side -->
  <task-sidenav-white [hidden]="service.loadingTask" #whiteSidenav></task-sidenav-white>
  <!-- #endregion -->

  <!-- #region Right Side -->
  <task-sidenav-blue [hidden]="service.loadingTask" #blueSidenav></task-sidenav-blue>
  <!-- #endregion -->
</div>
<!-- #endregion -->
