<div class="task__sidenav__blue">
  <div
    [formGroup]="service.form"
    *ngIf="(service.isPreview && !!service.task) || service.isCreation || (service.isEdit && !!service.task)"
  >
    <task-sidenav-blue-preview *ngIf="service.isPreview"></task-sidenav-blue-preview>
    <task-sidenav-blue-create *ngIf="!service.isPreview"></task-sidenav-blue-create>

    <div *ngIf="service.isTaskSelection" class="mb-2">
      <checkbox
        [form]="service.form"
        [title]="'Projects.showOnCalendar' | translate"
        [controlName]="service.TASK_SIDENAV_FORM.showInCalendar"
        [disabled]="
          service.task?.isDeleted ||
          (!service.permissionCtrl.edit && (service.isEdit || service.isPreview)) ||
          service?.task?.showInCalendar
        "
      ></checkbox>
    </div>

    <div class="mb-2">
      <checkbox
        [form]="service.form"
        [classes]="'red-500'"
        [title]="'Projects.highPriority' | translate"
        [controlName]="service.TASK_SIDENAV_FORM.isHighPriority"
        [disabled]="
          service.task?.isDeleted || (!service.permissionCtrl.edit && (service.isEdit || service.isPreview))
        "
      ></checkbox>
    </div>

    <div class="mb-2">
      <checkbox
        [form]="service.form"
        [title]="'ProjectTasks.Task.hiddenFromClient' | translate"
        [controlName]="service.TASK_SIDENAV_FORM.hiddenForClient"
        [disabled]="
          service.task?.isDeleted || (!service.permissionCtrl.edit && (service.isEdit || service.isPreview))
        "
        *ngIf="!service?.storage?.Employee?.isClient"
      ></checkbox>
    </div>

    <form
      [formGroup]="service.form"
      *ngIf="
        !(
          service?.storage?.Employee?.isClient ||
          service?.storage?.Employee?.isWorker ||
          service?.storage?.Employee?.isExternalUser
        ) && service?.isChange
      "
    >
      <div class="grey-1 bold mb-1">{{ 'ProjectChanges.List.settlementStatus' | translate }}</div>
      <ng-select
        [items]="items"
        [bindValue]="'value'"
        [bindLabel]="'name'"
        [clearable]="false"
        [searchable]="false"
        [formControlName]="service.TASK_SIDENAV_FORM.settlementStatus"
        [tabIndex]="-1"
        [notFoundText]="'Projects.notFoundText' | translate"
        class="select select-default w-100 mb-4"
        [readonly]="
          service.task?.isDeleted || (!service.permissionCtrl.edit && (service.isEdit || service.isPreview))
        "
      ></ng-select>
    </form>
  </div>
</div>
