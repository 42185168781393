<div [formGroup]="service.form">
  <daterangepicker
    #datepicker
    [interactive]="true"
    [isCalendarTime]="true"
    [disabled]="!!service?.task?.isDeleted"
    (rangeChanged)="service.rangeChanged($event)"
  ></daterangepicker>

  <div [hidden]="!service.isTaskSelection">
    <ng-select
      #taskGroup
      ngSelectExtension
      [hidden]="!service?.form?.get(service.TASK_SIDENAV_FORM.projectId)?.value"
      [url]="taskGroupURL"
      [formControlName]="service.TASK_SIDENAV_FORM.groupId"
      [bindValue]="'id'"
      [bindLabel]="'name'"
      class="select select-default w-100 mb-3"
      [notFoundText]="'Projects.notFoundText' | translate"
      [placeholder]="'Projects.taskGroup' | translate"
      [clearable]="false"
      [translateLabelPrefix]="'Projects.TaskGroups.'"
      [responseTransform]="taskGroupTransform.bind(this)"
      [sortByField]="'sortOrder'"
      [readonly]="!!service?.task?.isDeleted"
    ></ng-select>
  </div>

  <employee-search
    #search
    [readonly]="!!service?.task?.isDeleted"
    [initialValue]="service.assignedUsers"
    [projectId]="service.formProjectId"
    [form]="service.form"
    [controlName]="service.TASK_SIDENAV_FORM.assignedTo"
  ></employee-search>

  <ng-select
    ngSelectExtension
    #tagsSelect="ngSelectExtensionDir"
    [url]="service.TagUrl"
    [bindValue]="'name'"
    [bindLabel]="'name'"
    [formControlName]="service.TASK_SIDENAV_FORM.tags"
    [closeOnSelect]="false"
    [addTag]="true"
    [multiple]="true"
    [clearable]="true"
    [tabIndex]="-1"
    [notFoundText]="'Projects.notFoundText' | translate"
    [addTagText]="'Projects.addTaskTag' | translate"
    [clearAllText]="'clearAll' | translate"
    class="select select-default w-100 mb-4"
    [placeholder]="'Projects.tags' | translate"
    [enableSort]="false"
    [readonly]="!!service?.task?.isDeleted"
  ></ng-select>
</div>
