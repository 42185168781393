<div class="project-question-directory-sidenav">
  <div class="pr-4 project-question-directory">
    <div>
      <sidenav-title
        [title]="('Projects.Menu.questions' | translate) + ' 3'"
        [template]="stuffsHeadTemplate"
      ></sidenav-title>
      <div class="d-flex align-items-start justify-content-between">
        <project-question-directory-breadcrumbs
          [componentRefName]="'ProjectQuestionDirectoryThirdSidenavComponent'"
        ></project-question-directory-breadcrumbs>

        <div class="form-control-checkbox mt-2 ml-2">
          <label class="m-0 d-flex align-items-center">
            <input type="checkbox" [formControl]="showRemovedEntries" />
            <span></span>
            <span [innerHTML]="'Projects.showRemoved' | translate"></span>
          </label>
        </div>
      </div>

      <div *ngIf="isBasicBox3Filled" class="mt-4">
        <div *ngFor="let entry of entryListsConfig; let i = index; trackBy: trackEntries">
          <div *ngIf="entry.list?.length" class="mb-4">
            <ng-container *ngIf="isTitleVisible(i)">
              <div
                [ngClass]="{
                  'project-question-directory__region-title--single': !entry?.description?.length
                }"
                class="project-question-directory__region-title"
              >
                {{ entry.title | translate }}
              </div>
            </ng-container>

            <entry-list
              [snackBottom]="false"
              [showDeleted]="showRemovedEntries.value"
              [id]="projectStore.projectCtrl?.project?.id"
              [objectType]="EntryObjectType.OBJECT_TYPE_DATA_BOX_3"
              [buttonTitle]="'Projects.addEntry' | translate"
              [placeholder]="'Projects.enterText' | translate"
              [manageType]="entry.type"
              [type]="entry.entryType"
              [attachmentsConfig]="entry.attachmentsConfig"
              [entryModalConfig]="entry.entryModalConfig || {}"
              (onEntry)="updateListModel(ProjectThirdBoxEntries, $event, entry.entryType, 'dataBox3')"
              [items]="entry.list"
            ></entry-list>
          </div>
        </div>
        <!-- <directory-first-tasks></directory-first-tasks> -->
      </div>
      <div class="empty-box" *ngIf="!isBasicBox3Filled">
        <div>
          <span class="empty-box-text">
            {{ 'Projects.projectBoxEmpty' | translate }}
          </span>
        </div>
        <app-button
          [permission]="{
            group: PermissionsGroups.PROJECTS,
            action: 'PROJECT_BOX_THIRD_EDIT'
          }"
          [class]="'mt-2'"
          (onClick)="goToEdit()"
          [config]="{
            type: ButtonTypes.SECONDARY_BLUE,
            size: ButtonSize.LARGE,
            text: ('Projects.projectBoxFill' | translate) + ' 3',
            prependSrc: './assets/img/ic-arrow-black.svg'
          }"
        >
        </app-button>
      </div>
    </div>

    <div *ngIf="isBasicBox3Filled" class="mt-4">
      <commentary-manage
        #comments
        [attachmentsConfig]="attachmentsConfig"
        [viewType]="CommentaryViewType.COMMENTS_FIRST"
        [id]="projectStore.projectCtrl?.project?.id"
        [type]="CommentaryType.BOX_THIRD"
        [commentaries]="projectStore.projectCtrl.project?.dataBox3?.comments"
        (commentaryEvent)="updateCommentaries($event.data, 'dataBox3')"
      ></commentary-manage>
    </div>
  </div>
</div>
<ng-template #stuffsHeadTemplate>
  <div class="w-100 w-sm-auto d-flex flex-column flex-md-row d-md-block mt-3 mt-md-0">
    <app-button
      [permission]="{
        group: PermissionsGroups.PROJECTS,
        action: 'PROJECT_BOX_THIRD_EDIT'
      }"
      (onClick)="goToEdit()"
      [config]="{
        type: ButtonTypes.PRIMARY_BLUE,
        size: ButtonSize.LARGE,
        text: (isBasicBox3Filled ? 'Projects.edit' : 'Projects.projectBoxFillText') | translate,
        prependSrc: './assets/img/ic-edit-3-white.svg'
      }"
    >
    </app-button>
  </div>
</ng-template>
