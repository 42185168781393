import { Component, Input, OnInit } from '@angular/core';
import { EventSidenavController } from '@shared/modules/event-sidenav/controllers/event-sidenav.controller';
import { TaskSidenavController } from '@shared/modules/task-sidenav/controllers/task-sidenav.controller';
import { MyWeekEvent } from '../../shared/models/my-week-event.model';

@Component({
  selector: 'calendar-events-item',
  templateUrl: './calendar-events-item.component.html',
  styleUrls: ['./calendar-events-item.component.scss'],
})
export class CalendarEventsItemComponent implements OnInit {
  @Input() item: MyWeekEvent;

  constructor() {}

  ngOnInit(): void {}

  openPreview() {
    if (this.item.isEvent) {
      const ctrl = new EventSidenavController();
      ctrl.openPreview(this.item.id);
    }

    if (this.item.isTask) {
      const ctrl = new TaskSidenavController();
      ctrl.previewTask(String(this.item.id));
    }
  }
}
