<div
  class="sidenav hide"
  #sidenavEl
  *ngIf="opened"
  [class]="class"
  [ngClass]="{
    'sidenav-android': DeviceHelper.isAndroid
  }"
>
  <div
    class="sidenav-popup {{ 'sidenav-popup-side-' + side }} out"
    #sidenavPopup
    [ngClass]="{
      'sidenav-popup-side-fullwidth': !!maxWidth
    }"
    [ngStyle]="{ 'max-width': maxWidth ? maxWidth : '100%' }"
  >
    <div class="sidenav-popup-content">
      <ng-container #sidenavContent></ng-container>
    </div>
  </div>
  <div *ngIf="!hideBackdrop" class="sidenav-backdrop" (click)="closeBackdrop()"></div>
</div>
