import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { AppInjector } from '@shared/services/app-injector.service';
import { Observable, Subject, throwError } from 'rxjs';
import { map, catchError, retry, takeUntil } from 'rxjs/operators';
import { InterceptorSkipHeader } from '@core/interceptors/auth.interceptor';
import { BaseErrors } from '@shared/enums/base-errors.enum';
import { StorageService } from '@core/services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '@core/services/snackbar.service';
import { NavigateService } from '@shared/services/navigate.service';
import { MatDialog } from '@angular/material/dialog';
import { SidenavService } from '@shared/services/sidenav/sidenav.service';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { TopProgressBarSkipHeader } from '@core/interceptors/top-progressbar.interceptor';
import { HttpUrlEncodingCodec } from '@core/http/http-parameter-codec';
import { Config } from '@shared/configs/config';
import { RedirectHelper } from '@shared/helpers/redirect-helper';
import { IntilioCodes } from '@shared/enums/initilio-codes.enum';

@Injectable({
  providedIn: 'root',
})
export class BaseHttpService {
  injector: Injector;
  http: HttpClient;
  store: StorageService;
  t: TranslateService;
  s: SnackBarService;
  n: NavigateService;
  active: ActivatedRoute;
  dialog: MatDialog;
  sidenav: SidenavService;

  private m_unsubscribe: Subject<void> = new Subject();

  constructor() {
    this.injector = AppInjector.getInjector();
    this.http = this.injector.get(HttpClient);
    this.store = this.injector.get(StorageService);
    this.s = this.injector.get(SnackBarService);
    this.t = this.injector.get(TranslateService);
    this.n = this.injector.get(NavigateService);
    this.active = this.injector.get(ActivatedRoute);
    this.dialog = this.injector.get(MatDialog);
    this.sidenav = this.injector.get(SidenavService);
  }

  get deepestSnapshot() {
    const getRoutes = (snapshot: ActivatedRouteSnapshot) => {
      if (!!snapshot.firstChild) {
        return getRoutes(snapshot.firstChild);
      }
      return snapshot;
    };
    return getRoutes(this.active.snapshot);
  }

  private skipAuth(skip: boolean, opt: any): void {
    if (!!skip) {
      const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
      opt.headers = headers;
    }
  }

  private skipTopProgressBar(skip: boolean, opt: any): void {
    if (!!skip) {
      const headers = new HttpHeaders().set(TopProgressBarSkipHeader, '');
      opt.headers = headers;
    }
  }

  private httpGetParameterCodec(params) {
    if (!params) {
      return new HttpParams({
        encoder: new HttpUrlEncodingCodec(),
      });
    } else {
      return new HttpParams({
        fromObject: params,
        encoder: new HttpUrlEncodingCodec(),
      });
    }
  }

  private createFormData(body: any): FormData {
    const formData: FormData = new FormData();

    if (body) {
      for (const key in body) {
        if (typeof body[key] === 'function') {
          continue;
        }

        let value = body[key];
        if (typeof value === 'boolean') {
          value === true ? (value = 1) : (value = 0);
        }

        if (typeof body[key] === 'object' && !(body[key] instanceof File)) {
          for (const objKey in body[key]) {
            if (objKey) {
              value = body[key][objKey];
              formData.append(key + '[' + objKey + ']', value);
            }
          }
        } else {
          formData.append(key, value);
        }
      }
    }

    return formData;
  }

  abortGetRequest() {
    this.m_unsubscribe.next();
    this.m_unsubscribe = new Subject();
  }

  patch(url: string, body: any, options?: any, skipAuth?: boolean): Observable<any> {
    const opt = options ? options : {};
    this.skipAuth(skipAuth, opt);

    return this.http.patch(url, body, opt).pipe(
      map((resp) => resp),
      catchError(this.errorHandler('PATCH')),
    );
  }

  put(
    url: string,
    body: any,
    skipAuth: boolean = false,
    multipart: boolean = false,
    options?: any,
  ): Observable<any> {
    const opt = options ? options : {};
    this.skipAuth(skipAuth, opt);
    multipart ? (body = this.createFormData(body)) : null;
    return this.http.put(url, body, opt).pipe(
      map((resp) => resp),
      catchError(this.errorHandler('PUT')),
    );
  }

  delete(url: string, skipAuth: boolean = false, options?: any) {
    const opt = options ? options : {};
    this.skipAuth(skipAuth, opt);

    return this.http.delete(url, opt).pipe(
      map((resp) => resp),
      catchError(this.errorHandler('DELETE')),
    );
  }

  post(
    url: string,
    body: any,
    skipAuth: boolean = false,
    multipart: boolean = false,
    options?: any, // for params put it in the options object
  ): Observable<any> {
    const opt = options ? options : {};
    this.skipAuth(skipAuth, opt);
    multipart ? (body = this.createFormData(body)) : null;

    return this.http.post(url, body, opt).pipe(
      map((resp) => {
        return resp;
      }),
      catchError(this.errorHandler('POST')),
    );
  }

  postFile(url: string, body: any, skipAuth: boolean = false, options?: any): Observable<any> {
    const opt = options ? options : {};
    if (skipAuth) {
      const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
      opt.observe = 'response';
      opt['Content-Type'] = 'multipart/form-data';
      opt.headers = headers;
    }

    const formData: FormData = this.createFormData(body);

    return this.http.post(url, formData).pipe(
      map((resp) => resp),
      catchError(this.errorHandler('POST_FILE')),
    );
  }

  getUrlFromObject(url: string, obj: any): string {
    const params: string[] = [];
    for (let key in obj) {
      if (Array.isArray(obj[key])) {
        for (let index = 0; index < obj[key].length; index++)
          params.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key][index])}`);
      } else params.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
    }
    return params.length ? `${url}?${params.join('&')}` : `${url}`;
  }

  postFileBlob<T>(url: string, body: any, skipAuth: boolean = false, options?: any, params?: any) {
    const opt = options ? options : {};
    this.skipAuth(skipAuth, opt);
    opt.observe = 'response';
    opt.responseType = 'blob';
    const prepUrl = this.getUrlFromObject(url, params);
    return this.http.post(prepUrl, body, opt).pipe(
      map(
        (resp: any) => resp.body,
        (err) => err,
      ),
    );
  }

  getFileBlob<T>(url: string, skipAuth: boolean, params?: T) {
    const opt: any = {};

    opt.params = this.httpGetParameterCodec(params);
    this.skipAuth(skipAuth, opt);

    opt.observe = 'response';
    opt['content-type'] = 'application/pdf';
    opt.responseType = 'blob';

    return this.http.get(url, opt).pipe(
      map(
        (resp: any) => resp.body,
        (err) => err,
      ),
    );
  }

  get<T>(url: string, skipAuth: boolean = false, params?: T, skipTopProgressBar?: boolean) {
    const opt: any = {};
    this.skipAuth(skipAuth, opt);
    this.skipTopProgressBar(skipTopProgressBar, opt);
    opt.observe = 'response';
    opt.params = this.httpGetParameterCodec(params);

    return this.http
      .get(url, opt)
      .pipe(takeUntil(this.m_unsubscribe))
      .pipe(
        map((resp: any) => resp.body),
        catchError(this.errorHandler('GET')),
      );
  }

  errorHandler(operation) {
    return (httpResponse: HttpErrorResponse) => {
      console.log(httpResponse);
      const code = httpResponse.status;
      switch (code) {
        case 0:
          return throwError(Object.assign(httpResponse.message, { code }));
        case 400:
          return throwError(Object.assign(httpResponse.error.error, { code }));
        case 401:
        case 403:
          return this.checkError(httpResponse, code);
        default:
          return throwError(httpResponse);
      }
    };
  }

  checkError(httpResponse, code) {
    const error = httpResponse.error.error;
    if (error?.data?.subject) {
      switch (error?.data?.subject) {
        case BaseErrors.REMOVED:
          this.n.navigate('login', { redirectTo: RedirectHelper.getWindowPath() });
          this.s.error(this.t.instant('deletedCompany'));
          this.store.logout();
          return;
        default:
          break;
      }
    }
    switch (error?.message) {
      case BaseErrors.EXPIRED:
      case BaseErrors.NOT_FOUND_JWT:
        this.n.navigate('login', { redirectTo: RedirectHelper.getWindowPath() });
        this.s.error(this.t.instant('sessionExpired'));
        this.store.logout();
        break;
      case BaseErrors.DENIED:
        this.n.navigate(Config.MAIN_VIEW);
        this.s.error(this.t.instant('accessDenied'));
        this.sidenav.close();
        this.dialog.closeAll();
        break;
      case BaseErrors.EMPLOYEE_BLOCKED:
        this.sidenav.close();
        this.dialog.closeAll();
        this.s.error(this.t.instant('accountBlocked'));
        break;
      default:
        this.n.navigate('login', { redirectTo: RedirectHelper.getWindowPath() });
        this.store.logout();
        break;
    }

    return throwError({ messageCode: httpResponse.error.error.message, code });
  }
}
