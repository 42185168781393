import { NavigateService } from '@shared/services/navigate.service';
import { AppInjector } from '@shared/services/app-injector.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Component, OnDestroy } from '@angular/core';
import { SnackBarService } from '@core/services/snackbar.service';
import { StorageService } from '@core/services/storage.service';
import { UserPerson } from '@shared/interfaces/user.interface';
import { Config } from '@shared/configs/config';
import { Employee } from '@shared/models/employee.model';
import { UserRoles } from '@shared/enums/user-roles.enum';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import {
  BasePanelChangeEventType,
  BasePanelService,
} from '@shared/modules/base-panel/services/base-panel.service';

@Component({ template: '' })
export class BaseComponent implements OnDestroy {
  public CONFIG = Config;
  public readonly PermissionsGroups = PermissionsGroups;
  public loading: boolean = false;
  private readonly injector = AppInjector.getInjector();

  public n: NavigateService;
  public router: Router;
  public t: TranslateService;
  public sub: Subscription = new Subscription();
  public s: SnackBarService;
  public store: StorageService;
  public userPerson: UserPerson;
  public employee: Employee;

  constructor() {
    this.initServices();
    this.setConfigCurrency();
  }

  initServices() {
    this.n = this.injector.get(NavigateService);
    this.router = this.injector.get(Router);
    this.t = this.injector.get(TranslateService);
    this.s = this.injector.get(SnackBarService);
    this.store = this.injector.get(StorageService);
    this.userPerson = this.store.UserPerson;
    this.employee = this.store.Employee;
  }

  setConfigCurrency() {
    !Config.currency ? (Config.currency = this.employee?.company?.currency) : null;
  }

  scrollToError() {
    const el = document.getElementsByClassName('form-control has-error')[0];
    if (el && el.parentElement) {
      el.parentElement.scrollIntoView();
    }
  }

  ngOnDestroy() {
    this.sub ? this.sub.unsubscribe() : null;
  }
}
