import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ListFilterComponent } from '@shared/modules/list/components/list-filter/list-filter.component';
import { ListService } from '@shared/modules/list/services/list.service';
import { TranslateService } from '@ngx-translate/core';
import { AppInjector } from '@shared/services/app-injector.service';
import { ListConfig } from '@shared/modules/list/interfaces/list-config.interface';
import { ListEvent, ListEventType } from '@shared/modules/list/model/list-event.model';
import {
  ListTaskEvent,
  ListTaskEventType,
  ListTaskService,
} from '@shared/modules/list/services/list-task.service';
import { Subscription } from 'rxjs';
import { ListTasksController } from '@shared/modules/list/components/list-tasks/controllers/list-tasks.controller';
import { StorageService } from '@core/services/storage.service';
import { CustomDndDropEventInterface } from '@shared/interfaces/custom-dnd-drop-event.interface';
import { DndDropEvent } from 'ngx-drag-drop';
import { arrMove } from '@shared/helpers/arr-move.helper';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ListGroupTask } from '../../model/list-group-task.model';
import { SnackBarService } from '@core/services/snackbar.service';
import { ProjectTaskAPIService } from '@modules/projects/shared/services/project-task-api.service';
import { HttpError } from '@shared/interfaces/error.interface';

@Component({
  selector: 'list-tasks',
  templateUrl: './list-tasks.component.html',
  styleUrls: ['./list-tasks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListTasksComponent implements OnInit, AfterViewInit, OnDestroy {
  zone: NgZone;
  listTasksController: ListTasksController;
  sub: Subscription = new Subscription();

  private _config: ListConfig;
  @Input()
  set config(config: ListConfig) {
    this._config = config;
    this.changes.detectChanges();
  }

  get config() {
    return this._config;
  }

  private _showCompleted: boolean = true;
  @Input()
  set showCompleted(showCompleted: boolean) {
    this._showCompleted = showCompleted;
  }

  get showCompleted() {
    return this._showCompleted;
  }

  @Input() filters;
  @Input() isMyWork: boolean = false;
  @Input() searchName: string;
  @Input() projectId?: number;
  @Input() isSearch: boolean = true;

  @ViewChild('filtersComponent') filtersComponent: ListFilterComponent;
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;

  constructor(
    public service: ListService,
    public listTaskService: ListTaskService,
    public t: TranslateService,
    public changes: ChangeDetectorRef,
    public store: StorageService,
    public resolver: ComponentFactoryResolver,
    public s: SnackBarService,
    private projectTaskApiService: ProjectTaskAPIService,
  ) {
    this.zone = AppInjector.getInjector().get(NgZone);
    this.listTasksController = new ListTasksController(
      this.resolver,
      this.changes,
      this.zone,
      this.listTaskService,
      this.store,
      this.service,
      this.s,
      this.t,
    );
  }

  ngOnInit(): void {
    this.service.clearService();
    this.service.config = this.config;
    this.service.config.limit = this.service.PerPage as number;
    this.listenListServiceEvents();
    this.changes.detectChanges();
  }

  ngAfterViewInit() {
    this.listTaskService.projectId = this.projectId;
    this.listTaskService.isMyWorkTaskLists = this.isMyWork;
    this.listTasksController.container = this.container;
    this.service.filters = this.filtersComponent;
    this.service.readUrlParams();
    this.service.setInitSorting();
    this.changes.detectChanges();
  }

  listenListServiceEvents() {
    this.sub = this.service.eventEmitter.subscribe((e: ListEvent<any>) => {
      switch (e.type) {
        case ListEventType.END_GET_ROWS:
          this.listTaskService.listGroupsTask = this.service.rows;
          this.listTasksController.onDataLoad();
          break;
      }
    });
  }

  onUpdate(event: any) {
    console.log(event);
  }

  dropEvent(e: DndDropEvent) {
    this.listTasksController.handleReorder(e.data.taskGroup, e.index);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.listTasksController.sub.unsubscribe();
  }
}
