import { TaskType } from '@shared/enums/task-type.enum';

export const TaskTypeList: Array<TaskTypeItem> = [
  {
    name: 'Tasks.TaskType.note',
    type: TaskType.TYPE_NOTE,
  },
  {
    name: 'Tasks.TaskType.task',
    type: TaskType.TYPE_TASK,
  },
  {
    name: 'Tasks.TaskType.correction',
    type: TaskType.TYPE_CORRECTION,
  },
  {
    name: 'Tasks.TaskType.change',
    type: TaskType.TYPE_CHANGE,
  },
  {
    name: 'Tasks.TaskType.order',
    type: TaskType.TYPE_ORDER,
  },
];

export interface TaskTypeItem {
  name: string;
  type: TaskType;
}
