import { NgSelectExtensionDirective } from '@shared/directives/ng-select-extension.directive';
import { TASK_SIDENAV_FORM } from '@shared/modules/task-sidenav/const/task-sidenav-form';
import { DateRangePickerComponent } from '@shared/components/date-rangepicker/date-rangepicker.component';
import { Subscription } from 'rxjs';
import { EmployeeSearchComponent } from '@shared/components/employee-search/employee-search.component';
import { ETaskGroup, ETaskTypeGroup } from '@shared/modules/task-sidenav/enums/task-group.enum';
import { Config } from '@shared/configs/config';
import { TaskSidenavService } from '@shared/modules/task-sidenav/services/task-sidenav.service';
import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  AfterViewInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { debounceTime, tap, distinctUntilChanged } from 'rxjs/operators';
import { ListTaskService } from '@shared/modules/list/services/list-task.service';

@Component({
  selector: 'task-sidenav-blue-create',
  templateUrl: './task-sidenav-blue-create.component.html',
  styleUrls: ['./task-sidenav-blue-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskSidenavBlueCreateComponent implements OnInit, AfterViewInit, OnDestroy {
  CONFIG = Config;
  initial: boolean = false;
  sub: Subscription;
  projectSub: Subscription;
  @ViewChild('datepicker') datepicker: DateRangePickerComponent;
  @ViewChild('search') search: EmployeeSearchComponent;
  @ViewChild(NgSelectExtensionDirective) taskGroup: NgSelectExtensionDirective;
  @ViewChild('tagsSelect') tagsSelect: NgSelectExtensionDirective;

  get taskGroupURL() {
    const control = this.service.form.get(this.service.TASK_SIDENAV_FORM.projectId);
    return Config.API + '/task-group' + (control?.value ? `?projectId=${control?.value}` : '');
  }

  constructor(
    public service: TaskSidenavService,
    private changes: ChangeDetectorRef,
    private listTaskService: ListTaskService,
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.sub = new Subscription();
    this.listenProjectChange();
    this.service.registerSearchEmployee(this.search);
    this.service.registerGroupRef(this.taskGroup);
    const subChanges = this.service.taskActionEmitter.subscribe(() => this.changes.detectChanges());
    setTimeout(() => this.setTerm(), 0);

    // const subType = this.service.manualChangeTypeEmitter.subscribe((type: string) => {
    //   this.initial = false;
    //   this.service.defaultGroupID = null;
    //   setTimeout(() => this.taskGroup?.getItems(), 100);
    // });

    this.sub.add(subChanges);
  }

  listenProjectChange() {
    this.projectSub = this.service.form
      .get(TASK_SIDENAV_FORM.projectId)
      .valueChanges.pipe(
        distinctUntilChanged(),
        tap(() => {
          this.initial = false;
          this.service.defaultGroupID = null;
          this.changes.detectChanges();
        }),
      )
      .pipe(
        debounceTime(0),
        tap(() => {
          this.taskGroup?.getItems();
          this.tagsSelect?.getItems();
        }),
      )
      .subscribe();
  }

  setTerm() {
    if (!this.service.task?.termStart || !this.service.task?.termEnd) {
      return;
    }
    this.datepicker.setRange(this.service.task?.termStart, this.service.task?.termEnd);
  }

  taskGroupTransform(list) {
    if (!this.initial && !this.service.defaultGroupID) {
      const found =
        list.filter((i) => i.name === ETaskGroup.ALL_TASKS || i.name === ETaskTypeGroup.GROUP_TASK)[0]?.id ||
        null;
      this.service.defaultGroupID = found;
      this.service.form
        .get(this.service.TASK_SIDENAV_FORM.groupId)
        .patchValue(this.service.defaultGroupID, { onlySelf: true });
      this.initial = true;
    }

    return list?.filter((i) => i.name !== ETaskTypeGroup.GROUP_CHANGE);
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
    this.projectSub?.unsubscribe();
  }
}
