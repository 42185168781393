<div
  class="task-basic-preview-data"
  [ngClass]="{
    'task-basic-preview-data--disabled': service.task?.isDeleted,
    'task-basic-preview-data--completed': service.task?.isCompleted,
    'task-basic-preview-data--disabled-edit': !service.permissionCtrl.edit,
    'task-basic-preview-data--change': service.isConfigChange
  }"
>
  <!-- #region task container -->
  <div class="task-basic-preview-data__container">
    <div class="task-basic-preview-data__parent">
      <span
        class="pointer"
        *ngIf="!!service.task?.parent?.description"
        [title]="service.task?.parent?.description"
        (click)="openParentPreview(service.task?.parent)"
      >
        {{ service.task?.parent?.description }}
      </span>
    </div>

    <!-- #region task name -->
    <div class="task-basic-preview-data__name">
      <div class="d-flex">
        <task-checkbox
          [task]="service.task"
          *ngIf="service.isTaskSelection && service.permissionCtrl.edit"
          [placement]="'right'"
          [disabled]="service.task?.isDeleted"
          (onUpdateTask)="onStatusChange($event)"
        ></task-checkbox>

        <span class="task-basic-preview-data__name-title">
          {{ service.task?.description }}
        </span>
      </div>
      <svg-icon
        *ngIf="!service.task?.isDeleted && service.permissionCtrl.edit"
        (click)="openTaskEdit()"
        class="ml-3"
        src="./assets/img/ic-edit-3.svg"
        [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
      ></svg-icon>
    </div>
    <!-- #endregion -->
    <!-- #region task project address -->
    <div class="task-basic-preview-data__address">
      <span *ngIf="!!service.task?.project?.fullName" (click)="service.goToProjectSummary()" class="pointer">
        {{ service.task?.project?.fullName }}
      </span>
    </div>
    <!-- #endregion -->
    <!-- #region task info -->
    <div class="task-basic-preview-data__info">
      <span class="inline-flex" *ngIf="service.task?.taskId">
        <span class="pointer" copyDir [textCopy]="service.task?.taskId" [callback]="showTooltip.bind(this)">
          {{ service.task?.taskId }}
        </span>
        <span
          matTooltip="{{ 'Tasks.copied' | translate }}"
          #tooltipCopy="matTooltip"
          matTooltipPosition="right"
        ></span>

        |
      </span>

      <span>{{ 'Tasks.added' | translate }}</span>
      <b>&nbsp;{{ service.task?.creator?.userPerson?.previewName }}&nbsp;</b>
      <span>{{ service.task?.created | dateFormat : Config.DATETIME_FORMAT_DOTS }}</span>

      <ng-container *ngIf="service.task?.isCorrection">
        |
        <span>
          {{ 'Tasks.discount' | translate }}
        </span>
        <span>{{ service.task?.discount | priceFormat }}</span>
      </ng-container>
    </div>
    <!-- #endregion -->
  </div>
  <!-- #endregion -->

  <!-- #region description -->
  <div
    *ngIf="!!service.safeContent"
    class="task-basic-preview-data__description ql-editor task-basic-preview-data__custom-img-size"
    [innerHTML]="service.safeContent"
  ></div>
  <!-- #endregion -->

  <!-- #region description -->

  <div *ngIf="!!service.isConfigChange && !storage?.Employee?.isClient && service.safeContentInternalNote">
    <div class="grey-1 bold mb-1 mt-2">{{ 'Projects.internalNote' | translate }}</div>
    <div
      *ngIf="!!service.safeContentInternalNote"
      class="task-basic-preview-data__description ql-editor task-basic-preview-data__custom-img-size"
      [innerHTML]="service.safeContentInternalNote"
    ></div>
  </div>
  <!-- #endregion -->
</div>
