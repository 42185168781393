import { PermissionsGroups } from '@core/permissions/permissions.group';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  HostBinding,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { ListGroupTask } from '@shared/modules/list/model/list-group-task.model';
import { TranslateService } from '@ngx-translate/core';
import { TaskSidenavController } from '@shared/modules/task-sidenav/controllers/task-sidenav.controller';
import {
  ListTaskEvent,
  ListTaskEventType,
  ListTaskService,
} from '@shared/modules/list/services/list-task.service';
import { Subscription } from 'rxjs';
import { ListTasksSingleTaskComponent } from '@shared/modules/list/components/list-tasks/components/list-tasks-single-task/list-tasks-single-task.component';
import { TaskStatus } from '@shared/enums/task-status.enum';
import { Task } from '@shared/models/task.model';
import { DomSanitizer } from '@angular/platform-browser';
import { ListService } from '@shared/modules/list/services/list.service';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { ListEvent, ListEventType } from '@shared/modules/list/model/list-event.model';
import { Config } from '@shared/configs/config';
import { TaskGroupModalComponent } from '@shared/modules/task-sidenav/components/task-group-modal/task-group-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ListGroupTranslatePipe } from '@shared/modules/list/pipes/list-group-translate.pipe';
import { TaskService } from '@shared/modules/tasks/services/task-service';
import { TaskGroup } from '@shared/models/task-group.model';
import { TaskType } from '@shared/enums/task-type.enum';
import { TaskGroupTypeList } from '@shared/modules/task-sidenav/const/task-group.list';
import { ETaskTypeGroup } from '@shared/modules/task-sidenav/enums/task-group.enum';
import { DndDropEvent } from 'ngx-drag-drop';
import { CustomDndDropEventInterface } from '@shared/interfaces/custom-dnd-drop-event.interface';
import { ProjectTaskAPIService } from '@modules/projects/shared/services/project-task-api.service';
import { SnackBarService } from '@core/services/snackbar.service';
import { HttpError } from '@shared/interfaces/error.interface';
import { IntilioCodes } from '@shared/enums/initilio-codes.enum';

@Component({
  selector: 'list-tasks-group',
  templateUrl: './list-tasks-group.component.html',
  styleUrls: ['./list-tasks-group.component.scss'],
  host: { class: 'list-tasks-group' },
})
export class ListTasksGroupComponent implements OnInit, AfterViewInit, OnDestroy {
  TaskStatus = TaskStatus;
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  PermissionsGroups = PermissionsGroups;

  initEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  sub: Subscription = new Subscription();
  taskGroupId: string | number;

  listGroupTask: ListGroupTask;
  isEmpty: boolean = false;
  isEmptyCompleted: boolean = false;

  dragging: boolean = false;
  interval = null;
  mousePosition = { x: 0, y: 0 };

  @ViewChild('completedContainer', { read: ViewContainerRef }) completedContainer: ViewContainerRef;
  @ViewChild('tasksContainer', { read: ViewContainerRef }) tasksContainer: ViewContainerRef;

  @ViewChildren('tasksComponents') tasksComponents: QueryList<ListTasksSingleTaskComponent>;

  constructor(
    private t: TranslateService,
    private s: SnackBarService,
    public changes: ChangeDetectorRef,
    public listTaskService: ListTaskService,
    public listService: ListService,
    private sanitizer: DomSanitizer,
    public elRef: ElementRef,
    public renderer: Renderer2,
    private ngZone: NgZone,
    private dialog: MatDialog,
    private listGroupTranslatePipe: ListGroupTranslatePipe,
    private projectTaskApiService: ProjectTaskAPIService,
    private taskService: TaskService,
  ) {}

  ngOnInit(): void {
    this.listenListTaskEvents();
    this.listenListServiceEvents();
    this.setListGroupTask();
  }

  ngAfterViewInit() {
    this.initEmitter.emit(true);
  }

  dragstart() {
    this.dragging = true;
    this.scrollInterval();
    this.changes.detectChanges();
  }

  drag(e: DragEvent) {
    this.mousePosition = Object.assign({}, { x: e.clientX, y: e.clientY });
    this.changes.detectChanges();
  }

  dragend() {
    this.dragging = false;
    this.changes.detectChanges();
  }

  scrollInterval() {
    const offset = 200;
    const speedOffset = 200;

    const element = document.querySelector('.base-panel');
    const _scrollElement: any = element;

    this.interval = setInterval(() => {
      const mousePositionY = this.mousePosition.y;
      if (!this.dragging) {
        clearInterval(this.interval);
        this.interval = null;
      }
      const currentScrollTop = _scrollElement.scrollTop;

      // Scrolling Down
      if (mousePositionY > _scrollElement.clientHeight - offset) {
        _scrollElement.scrollTo(_scrollElement.scrollLeft, currentScrollTop + speedOffset);
      }

      //Scrolling Up
      if (this.mousePosition.y < offset) {
        _scrollElement.scrollTo(_scrollElement.scrollLeft, currentScrollTop - speedOffset);
      }
      this.changes.detectChanges();
    }, 200);
  }

  setListGroupTask() {
    this.listGroupTask = this.listTaskService.listGroupTasksHashMap.get(
      `${ListTaskService.LIST_GROUP_TASK_PREFIX}${this.taskGroupId}`,
    );
    this.setIsEmpty();
  }

  listenListTaskEvents() {
    const sub = this.listTaskService.emitter.subscribe((event: ListTaskEvent) => {
      switch (event.type) {
        case ListTaskEventType.REFRESH_LIST_GROUPS:
          this.setListGroupTask();
          this.changes.detectChanges();
          break;
      }
    });
    this.sub.add(sub);
  }

  listenListServiceEvents() {
    const sub = this.listService.eventEmitter.subscribe((event: ListEvent<any>) => {
      switch (event.type) {
        case ListEventType.END_GET_ROWS:
          this.setListGroupTask();
          this.changes.detectChanges();
          break;
      }
    });
    this.sub.add(sub);
  }

  addTask(taskGroup: TaskGroup) {
    const notFound = TaskGroupTypeList.findIndex((i) => i.id === taskGroup.name) === -1;
    const extra = { type: ETaskTypeGroup.GROUP_TASK };
    !notFound ? (extra.type = taskGroup?.name as ETaskTypeGroup) : null;

    this.ngZone.run(() => {
      const taskSidenavController = new TaskSidenavController();
      taskSidenavController.addTask(this.listTaskService.projectId, Number(this.taskGroupId), null, extra);
    });
  }

  setIsEmpty() {
    let isEmpty = true;
    let isEmptyCompleted = true;

    const foreach = (map: Map<string, Task>, flag: boolean) => {
      map.forEach((task: Task, key: string) => {
        if (
          !task.parentId ||
          this.listTaskService.isMyWorkTaskLists ||
          (task.parentId && this.listTaskService.getTask(task.parentId)?.taskGroup?.id !== task.taskGroup?.id)
        ) {
          //

          if (this.listTaskService.isMyWorkTaskLists) {
            if (this.listGroupTask.taskGroup?.id === 1 && task.project) {
              //my work component
              flag = false;
            }

            if (this.listGroupTask.taskGroup?.id === 2 && !task.project) {
              //my work component
              flag = false;
            }
          } else {
            if (task.taskGroup?.id === this.taskGroupId) {
              flag = false;
            }
          }
        }
      });
      return flag;
    };
    this.isEmpty = foreach(this.listTaskService.tasksHashMap, isEmpty);
    this.isEmptyCompleted = foreach(this.listTaskService.completedTasksHashMap, isEmptyCompleted);
  }

  editGroupName(listGroupTask: ListGroupTask) {
    const dialog = this.dialog.open(TaskGroupModalComponent, {
      width: Config.DEFAULT_MODAL_WIDTH,
      autoFocus: true,
      data: { notSendNewGroup: true, edit: true, taskGroup: listGroupTask?.taskGroup },
    });

    dialog.componentInstance.group.setValue(
      this.listGroupTranslatePipe.transform(listGroupTask.taskGroup.name),
    );

    const sub = dialog.componentInstance.onGroupAdd.subscribe((groupName: string) => {
      listGroupTask.taskGroup.name = groupName;
      this.taskService.editTaskGroup(groupName, listGroupTask?.taskGroup?.id).subscribe();
      this.changes.detectChanges();
      sub.unsubscribe();
    });

    const subDelete = dialog.componentInstance.onGroupRemove.subscribe((group: TaskGroup) => {
      this.projectTaskApiService
        .taskGroupBulkEdit({
          groupsToDeleteIds: [group?.id],
          projectId: this.listTaskService?.projectId,
        })
        .subscribe(
          () => {
            this.listService.getRows().add(() => {
              this.listService.eventEmitter.emit({ type: ListEventType.ROW_REMOVED, data: null });
              this.changes.detectChanges();
            });
            this.changes.detectChanges();
            this.s.success(this.t.instant('Projects.groupRemoved'));
          },
          (error: HttpError) => {
            let text = '';
            switch (error.messageCode) {
              case IntilioCodes.GROUP_NOT_EMPTY:
                text = 'Projects.groupNotEmpty';
                break;
              default:
                text = 'errorDefault';
                break;
            }
            this.s.error(this.t.instant(text));
          },
        );

      this.changes.detectChanges();
      subDelete.unsubscribe();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
