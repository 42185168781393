import { NgModule } from '@angular/core';
import { FooterComponent } from '@shared/modules/base-panel/components/footer/footer.component';
import { HeaderComponent } from '@shared/modules/base-panel/components/header/header.component';
import { BasePanelComponent } from '@shared/modules/base-panel/components/base-panel/base-panel.component';
import { SharedModule } from '@shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { TopProgressBarModule } from '@modules/top-progress-bar/top-progress-bar.module';
import { HeaderActionMenuComponent } from '@shared/modules/base-panel/components/header/components/header-action-menu/header-action-menu.component';
import { BasePanelService } from '@shared/modules/base-panel/services/base-panel.service';
import { HeaderLogoComponent } from './components/header/components/header-logo/header-logo.component';
import { HeaderNavigationComponent } from './components/header/components/header-navigation/header-navigation.component';
import { HeaderActionMenuCompanyComponent } from '@shared/modules/base-panel/components/header/components/header-action-menu/components/header-action-menu-company/header-action-menu-company.component';
import { HeaderActionMenuNotificationComponent } from './components/header/components/header-action-menu/components/header-action-menu-notification/header-action-menu-notification.component';
import { HeaderActionMenuUserComponent } from './components/header/components/header-action-menu/components/header-action-menu-user/header-action-menu-user.component';
import { HeaderHamburgerComponent } from './components/header/components/header-hamburger/header-hamburger.component';
import { MobileNavigationComponent } from '@shared/modules/base-panel/components/mobile-navigation/mobile-navigation.component';
import { HeaderBackIconComponent } from './components/header/components/header-back-icon/header-back-icon.component';
import { UIModule } from '@shared/modules/ui/ui.module';
import { SignUpHeaderComponent } from './components/sign-up-header/sign-up-header.component';
import { SignupHumburgerComponent } from './components/sign-up-header/components/signup-humburger/signup-humburger.component';
import { SignupMobileNavigationComponent } from './components/signup-mobile-navigation/signup-mobile-navigation.component';
import { ClickOutsideModule } from 'ng-click-outside';

@NgModule({
  declarations: [
    BasePanelComponent,
    HeaderActionMenuComponent,
    HeaderComponent,
    HeaderLogoComponent,
    HeaderNavigationComponent,
    HeaderActionMenuCompanyComponent,
    HeaderActionMenuNotificationComponent,
    HeaderActionMenuUserComponent,
    HeaderHamburgerComponent,
    MobileNavigationComponent,
    HeaderBackIconComponent,
    SignUpHeaderComponent,
    SignupHumburgerComponent,
    SignupMobileNavigationComponent,
  ],
  imports: [SharedModule, TopProgressBarModule, TranslateModule, UIModule, ClickOutsideModule],
  exports: [BasePanelComponent, HeaderActionMenuComponent],
  providers: [BasePanelService],
})
export class BasePanelModule {}
