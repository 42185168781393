import { Component, Input, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { SidenavService } from '@shared/services/sidenav/sidenav.service';

@Component({
  selector: 'sidenav-title',
  templateUrl: './sidenav-title.component.html',
  styles: [],
})
export class SidenavTitleComponent {
  @Input() title: string = 'Lets begin!';
  @Input() columnOnMobile: boolean = true;
  @Input() template: TemplateRef<any>;
  @Input() closeFunc: () => void;

  constructor(public sidenav: SidenavService) {}
}
