<div class="d-flex align-content-start align-md-content-center flex-column flex-md-row flex-wrap">
  <list-single-filter-select
    class="w-100 w-sm-auto"
    *ngIf="
      employee?.role === UserRoles.ROLE_EMPLOYEE_ADMIN || employee?.role === UserRoles.ROLE_EMPLOYEE_MANAGER || employee?.hasResponsibleProjects
    "
    [name]="'ProjectsList.Filters.responsibleCompany' | translate"
    [iconUrl]="'/assets/img/ic-person-circle-outline.svg'"
    [form]="service?.filtersForm"
    [controlName]="'[responsibleEmployee-id][eq]'"
    (change)="onChangeFilter()"
    [searchGetParams]="'search'"
    [requiredIdUrl]="Config.API + '/employee/search?' + searchRoles"
    [valueTransform]="creatorName.bind(this)"
  ></list-single-filter-select>

  <list-single-filter-select
    class="w-100 w-sm-auto"
    [name]="'ProjectsList.Filters.client' | translate"
    [iconUrl]="'/assets/img/ic-person-circle-outline.svg'"
    [form]="service?.filtersForm"
    [controlName]="'[mainContact-id][eq]'"
    [multiple]="false"
    (change)="onChangeFilter()"
    [requiredIdUrl]="Config.API + '/contact/search'"
    [valueTransform]="contactName.bind(this)"
  ></list-single-filter-select>

  <list-single-filter-select
    class="w-100 w-sm-auto"
    [name]="'ProjectsList.Filters.propertyType' | translate"
    [iconUrl]="'/assets/img/ic-home.svg'"
    [form]="service?.filtersForm"
    [localItems]="propertyTypes"
    [bindValue]="'id'"
    [bindLabel]="'name'"
    [controlName]="'[basicDataBox-propertyType][eq]'"
    (change)="onChangeFilter()"
  ></list-single-filter-select>

  <list-single-filter-checkbox
    [name]="'ProjectsList.Filters.showRemoved' | translate"
    [form]="service?.filtersForm"
    [controlName]="'[deleted]'"
    (change)="onChangeFilter()"
  ></list-single-filter-checkbox>

  <list-single-clear-filters
    [form]="service?.filtersForm"
    (change)="onChangeFilter()"
    [shouldShowClearFilters]="shouldShowClearFilters.bind(this)"
    [clearFilters]="clearFilters.bind(this)"
  >
  </list-single-clear-filters>
</div>
