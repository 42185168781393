<section class="project-creator grey p-0 pb-5">
  <div *ngIf="!!headerStepOutOfTemplate" #header class="steps-header">
    <ul class="row mb-0 text-center steps-list">
      <li class="col-3">
        <div
          (click)="clickStep(1)"
          [ngClass]="{
            loading: service.steps[0]?.loading,
            active: service.stepper.currentStep === 1,
            finish: service.stepper.currentStep === 5
          }"
          class="step"
        >
          <span class="step__number">
            <span>1</span>
          </span>
          <span [innerHTML]="'ProjectCreator.Stepper.first' | translate"> </span>
        </div>
      </li>
      <li class="col-3">
        <div
          (click)="clickStep(2)"
          [ngClass]="{
            loading: service.steps[1]?.loading,
            active: service.stepper.currentStep === 2,
            finish: service.stepper.currentStep === 5
          }"
          class="step"
        >
          <span class="step__number">
            <span>2</span>
          </span>
          <span [innerHTML]="'ProjectCreator.Stepper.second' | translate"></span>
        </div>
      </li>
      <li class="col-3">
        <div
          (click)="clickStep(3)"
          [ngClass]="{
            loading: service.steps[2]?.loading,
            active: service.stepper.currentStep === 3,
            finish: service.stepper.currentStep === 5
          }"
          class="step"
        >
          <span class="step__number">
            <span>3</span>
          </span>
          <span [innerHTML]="'ProjectCreator.Stepper.third' | translate"></span>
        </div>
      </li>
      <li class="col-3">
        <div
          (click)="clickStep(4)"
          [ngClass]="{
            loading: service.steps[3]?.loading,
            active: service.stepper.currentStep === 4,
            finish: service.stepper.currentStep === 5
          }"
          class="step"
        >
          <span class="step__number">
            <span>4</span>
          </span>
          <span [innerHTML]="'ProjectCreator.Stepper.fourth' | translate"></span>
        </div>
      </li>
    </ul>
  </div>

  <div class="project-creator-container">
    <div *ngIf="showHeaderLogo" class="py-2">
      <div
        class="project-creator-container__title d-flex justify-content-between align-items-center py-4 px-sm-5"
      >
        <span>
          <a draggable="false" href="/">
            <img draggable="false" src="./assets/img/logo.svg" alt="logo" />
          </a>
        </span>

        <span class="d-flex align-items-center">
          <app-lang-switcher></app-lang-switcher>
        </span>
      </div>
    </div>

    <div class="bg-white project-creator-container_box">
      <div *ngIf="!headerStepOutOfTemplate" #header class="steps-header">
        <ul class="row mb-0 text-center steps-list">
          <li class="col-3">
            <div
              (click)="clickStep(1)"
              [ngClass]="{
                loading: service.steps[0]?.loading,
                active: service.stepper.currentStep === 1,
                finish: service.stepper.currentStep === 5
              }"
              class="step"
            >
              <span class="step__number">
                <span>1</span>
              </span>
              <span [innerHTML]="'ProjectCreator.Stepper.first' | translate"> </span>
            </div>
          </li>
          <li class="col-3">
            <div
              (click)="clickStep(2)"
              [ngClass]="{
                loading: service.steps[1]?.loading,
                active: service.stepper.currentStep === 2,
                finish: service.stepper.currentStep === 5
              }"
              class="step"
            >
              <span class="step__number">
                <span>2</span>
              </span>
              <span [innerHTML]="'ProjectCreator.Stepper.second' | translate"></span>
            </div>
          </li>
          <li class="col-3">
            <div
              (click)="clickStep(3)"
              [ngClass]="{
                loading: service.steps[2]?.loading,
                active: service.stepper.currentStep === 3,
                finish: service.stepper.currentStep === 5
              }"
              class="step"
            >
              <span class="step__number">
                <span> 3</span>
              </span>
              <span [innerHTML]="'ProjectCreator.Stepper.third' | translate"></span>
            </div>
          </li>
          <li class="col-3">
            <div
              (click)="clickStep(4)"
              [ngClass]="{
                loading: service.steps[3]?.loading,
                active: service.stepper.currentStep === 4,
                finish: service.stepper.currentStep === 5
              }"
              class="step"
            >
              <span class="step__number">
                <span>4</span>
              </span>
              <span [innerHTML]="'ProjectCreator.Stepper.fourth' | translate"></span>
            </div>
          </li>
        </ul>
      </div>

      <div class="project-creator-content">
        <div class="steps-container">
          <app-project-creator-first-step
            *ngIf="service.stepper.currentStep === 1"
            [config]="config?.firstStep"
            [useCustomPermission]="useCustomPermission"
            [customPermissions]="customPermissionsStep1"
          ></app-project-creator-first-step>
          <app-project-creator-second-step
            *ngIf="service.stepper.currentStep === 2"
            [config]="config?.secondStep"
            [useCustomPermission]="useCustomPermission"
            [customPermissions]="customPermissionsStep2"
            [customDocumentPermissions]="customPermissionsStep2Documents"
          ></app-project-creator-second-step>
          <app-project-creator-third-step
            *ngIf="service.stepper.currentStep === 3"
            [config]="config?.thirdStep"
            [useCustomPermission]="useCustomPermission"
            [customPermissions]="customPermissionsStep3"
          ></app-project-creator-third-step>
          <app-project-creator-fourth-step
            *ngIf="service.stepper.currentStep === 4"
            [config]="config?.fourthStep"
            [useCustomPermission]="useCustomPermission"
            [customPermissions]="customPermissionsStep4"
          ></app-project-creator-fourth-step>
          <app-project-creator-fifth-step
            *ngIf="service.stepper.currentStep === 5"
          ></app-project-creator-fifth-step>
        </div>
      </div>

      <div
        class="steps-footer d-flex justify-content-between bg-white"
        *ngIf="service.stepper.currentStep < 5 && !hideFooter"
      >
        <div>
          <app-button
            class="pr-4 pl-2"
            *ngIf="service.stepper.currentStep > 1 && service.stepper.currentStep <= 4"
            (click)="goToPrevStep()"
            [config]="{
              size: ButtonSize.LARGE,
              type: ButtonTypes.PRIMARY_WHITE_BORDERED,
              text: 'ProjectCreator.prevStep' | translate,
              prependSrc: './assets/img/ic-back-black.svg'
            }"
          ></app-button>
        </div>

        <app-button
          class="pl-4"
          [ngClass]="{
            'pr-2': service.stepper.currentStep <= 4,
            'pr-4': service.stepper.currentStep > 4
          }"
          [disabled]="currentStep?.loading"
          [loading]="currentStep?.loading"
          (click)="submitCurrentStep()"
          [config]="{
            size: ButtonSize.LARGE,
            text: nextStepText | translate,
            appendSrc: service.stepper.currentStep <= 4 ? './assets/img/ic-chevron-right.svg' : ''
          }"
        ></app-button>
      </div>
    </div>
  </div>
</section>

<div
  class="steps-footer steps-footer--sticky steps-footer--sticky_custom"
  *ngIf="service.stepper.currentStep < 5 && !!hideFooter"
>
  <div
    [hidden]="
      !(!!currentStep?.dataChanged && !active?.snapshot?.data?.create && hasEditPermissionForCurrentStep)
    "
    class="text-center steps-footer-save_wrapper"
  >
    <div class="bg-white steps-footer-save">
      <app-button
        class="bold"
        (click)="saveAndStay()"
        [disabled]="currentStep?.loading"
        [loading]="currentStep?.loading"
        [config]="{
          size: ButtonSize.SMALL,
          type: ButtonTypes.PRIMARY_GREEN,
          text: 'ProjectCreator.saveAndStay' | translate,
          prependSrc: './assets/img/ic-save.svg'
        }"
      ></app-button>
    </div>
  </div>

  <div class="d-flex justify-content-between bg-white steps-footer-sticky-inside">
    <div>
      <app-button
        class="pr-4 pl-2"
        *ngIf="service.stepper.currentStep > 1 && service.stepper.currentStep <= 4"
        (click)="goToPrevStep()"
        [config]="{
          size: ButtonSize.LARGE,
          type: ButtonTypes.PRIMARY_WHITE_BORDERED,
          text: 'ProjectCreator.prevStep' | translate,
          prependSrc: './assets/img/ic-back-black.svg'
        }"
      ></app-button>
    </div>

    <div>
      <app-button
        class="pl-4"
        [ngClass]="{
          'pr-2': service.stepper.currentStep <= 4,
          'pr-4': service.stepper.currentStep > 4
        }"
        [disabled]="currentStep?.loading"
        [loading]="currentStep?.loading"
        (click)="submitCurrentStep()"
        [config]="{
          size: ButtonSize.LARGE,
          text: nextStepText | translate,
          appendSrc: service.stepper.currentStep < 4 ? './assets/img/ic-chevron-right.svg' : ''
        }"
      ></app-button>
    </div>
  </div>
</div>
