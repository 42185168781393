<div class="mailing-sidenav">
  <sidenav-loading *ngIf="loadingTemplate" [cover]="true"></sidenav-loading>
  <div class="mailing-sidenav__details">
    <sidenav-title
      [closeFunc]="discardSaving.bind(this)"
      [title]="'Mailings.Templates.templatesTitle' | translate"
    ></sidenav-title>
    <div [formGroup]="form" class="pt-3">
      <!-- #region -->
      <div>
        <div
          class="grey-1 bold title mb-3"
          [innerHTML]="form.controls[MailingForm.NAME].value || '&nbsp;'"
        ></div>
        <div class="mb-3">
          <span class="grey-1 d-inline-block mb-1 bold">{{
            'Mailings.Templates.selectProject' | translate
          }}</span>
          <project-selection
            [changePromise]="changePromiseFunc.bind(this)"
            [requiredid]="projectId"
            [clearable]="!isDigitalChanges"
            [form]="form"
            [disabled]="isDigitalChanges"
            [class]="'w-100'"
          ></project-selection>
        </div>
        <div class="mb-3">
          <span class="grey-1 d-inline-block mb-1 bold required">
            {{ 'Mailings.Templates.reciever' | translate }} <span class="text-danger">*</span>
          </span>
          <ng-select
            #participants
            [ngClass]="{ 'ng-select--error': isControlInvalid(MailingForm.RECIEVER) }"
            ngSelectExtension
            [url]="ContactsUrl"
            [responseTransform]="successOnLoadContacts.bind(this)"
            [formControlName]="MailingForm.RECIEVER"
            [ignoreLocalSearch]="true"
            [multiple]="!isDigitalChanges"
            [bindValue]="'email'"
            [bindLabel]="'fullName'"
            [clearable]="true"
            [initialValue]="initialEmails"
            [addTag]="validateEmail.bind(this)"
            [searchGetParam]="'query'"
            [addTagText]="'Protocols.add' | translate"
            [notFoundText]="'Projects.notFoundText' | translate"
            [placeholder]="'CalendarEvents.selectParticipatns' | translate"
            class="select select-default w-100"
          ></ng-select>
          <span
            *ngIf="isControlInvalid(MailingForm.RECIEVER)"
            class="d-flex form-control-error-msg mt-1"
            [innerHTML]="'FormErrors.required' | translate"
          ></span>
        </div>
      </div>
      <!-- #endregion -->
      <!-- #region -->
      <div>
        <div class="d-flex justify-content-between align-items-md-end align-items-center mb-2">
          <span class="grey-1 title">{{ 'Mailings.Templates.mailContent' | translate }}</span>
          <span class="d-inline-block">
            <div
              class="hidden"
              mat-raised-button
              #tooltip="matTooltip"
              matTooltip="{{ 'Projects.copied' | translate }}"
              matTooltipPosition="above"
            ></div>
            <button
              copyDir
              [withoutHtml]="true"
              [textCopy]="formattedText"
              [callback]="showTooltip.bind(this)"
              class="btn btn_sm btn-empty_grey font-400 pl-2 pr-2"
            >
              <img
                draggable="false"
                class="d-inline-block mr-0 mr-md-1"
                src="/assets/img/ic_copy_to_clipboard.svg"
                alt="copy"
              />
              <span class="d-none d-md-inline-block blue-500">
                {{ 'Mailings.Templates.copyText' | translate }}
              </span>
            </button>
          </span>
        </div>
        <div>
          <gbxsoft-input
            [errMessages]="errorMessages"
            class="text-control required"
            [formControlName]="MailingForm.TITLE"
            [config]="{ name: 'Mailings.Templates.subjectOfMail' | translate }"
          ></gbxsoft-input>
        </div>
        <div class="relative mb-3">
          <quill-editor
            [formControlName]="MailingForm.DESCRIPTION"
            [modules]="modules"
            [formats]="formats"
            [placeholder]="'Mailings.mailDescription' | translate"
          ></quill-editor>
          <span
            *ngIf="isControlInvalid(MailingForm.DESCRIPTION)"
            class="d-flex form-control-error-msg mt-1"
            [innerHTML]="'FormErrors.required' | translate"
          ></span>
        </div>

        <div [hidden]="!isDigitalChanges" class="mb-3">
          <app-project-changes-info [changesSummary]="changesSummary"></app-project-changes-info>
        </div>

        <div [hidden]="!isDigitalChanges" class="relative mb-3">
          <quill-editor
            [formControlName]="MailingForm.DESCRIPTION_END"
            [modules]="modules"
            [formats]="formats"
            [placeholder]="'Mailings.mailDescription' | translate"
          ></quill-editor>
        </div>
      </div>
      <!-- #endregion -->

      <!-- #region -->
      <div>
        <attachment-manager-list
          #attachmentsList
          [attachmentsConfig]="attachmentsConfig"
          (filesUploaded)="filesUploaded($event)"
        ></attachment-manager-list>
      </div>
      <!-- #endregion -->
    </div>
  </div>

  <div class="sticky-footer d-flex justify-content-end sticky-footer align-items-center py-1">
    <div class="col-6">
      <app-button
        class="bold w-100"
        (onClick)="discardSaving()"
        [config]="{
          size: ButtonSize.LARGE,
          type: ButtonTypes.DISCARD_BUTTON,
          text: 'Projects.discard' | translate
        }"
      ></app-button>
    </div>
    <div class="col-6">
      <app-button
        class="bold w-100"
        [disabled]="sendingTemplate"
        [loading]="sendingTemplate"
        (onClick)="submit()"
        [config]="{
          size: ButtonSize.LARGE,
          type: ButtonTypes.PRIMARY_BLUE,
          text: 'Mailings.Templates.send' | translate,
          prependSrc: './assets/img/business-mail-open-line.svg'
        }"
      ></app-button>
    </div>
  </div>
</div>
