<main class="bg-white contacts-list">
  <action-title-header
    [title]="'Contacts.List.title' | translate"
    [buttonText]="'Contacts.List.addContact' | translate"
    (onAction)="addContact()"
    [sticky]="true"
    [permissionDecorator]="{ group: PermissionsGroups.CONTACTS, action: 'ADD' }"
  ></action-title-header>

  <div class="container-fluid--30 contacts-list__content">
    <app-list
      *ngIf="inited"
      [config]="config"
      [emptyTemplate]="emptyTemplate"
      [filters]="ContactsFiltersComponent"
      [searchName]="'Contacts.List.searchContact' | translate"
      [selected]="selected"
      (activate)="activate($event)"
      [showToggleMenu]="showToggleMenu.bind(this)"
      #list
    >
    </app-list>
  </div>
</main>

<ng-template #emptyTemplate>
  <div class="d-flex flex-column align-items-center mt-4 empty">
    <img
      draggable="false"
      src="/assets/img/Contact.svg"
      alt="{{ 'Contacts.List.addContact' | translate }}"
      class="mb-4 mt-4"
    />
    <div class="mt-2">{{ 'Contacts.List.emptyList' | translate }}</div>
    <app-button
      [permission]="{
        group: PermissionsGroups.CONTACTS,
        action: 'ADD'
      }"
      (onClick)="addContact()"
      [config]="{
        type: ButtonTypes.SECONDARY_BLUE,
        text: 'Contacts.List.addContact' | translate,
        size: ButtonSize.LARGE,
        prependSrc: './assets/img/ic-plus-add.svg'
      }"
    >
    </app-button>
  </div>
</ng-template>

<contact-name-cell #contactNameCell></contact-name-cell>
<contact-type-cell #contactTypeCcell></contact-type-cell>
<contact-phone-cell #contactPhoneCell></contact-phone-cell>
<contact-email-cell #contactEmailCell></contact-email-cell>
<contact-availability-cell #contactAvailabilityCell></contact-availability-cell>
<contact-parents-count-cell #contactParentCountCell></contact-parents-count-cell>
<contact-subcontacts-count-cell #contactSubcontactsCountCell></contact-subcontacts-count-cell>
<contact-creator-cell #contactCreatorCell></contact-creator-cell>
<contact-function-cell #contactFunctionCell></contact-function-cell>
