import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CoreModule } from '@core/core.module';
import { AttachmentInlineComponent } from './components/attachment-inline/attachment-inline.component';
import { AttachmentManagerListComponent } from './components/attachment-manager-list/attachment-manager-list.component';

@NgModule({
  declarations: [AttachmentManagerListComponent, AttachmentInlineComponent],
  imports: [CoreModule, MatIconModule],
  exports: [AttachmentManagerListComponent],
  providers: [],
})
export class AttachmentsManageModule {}
