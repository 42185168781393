import { CustomRoutes } from '@shared/consts/routes/custom-routes.const';
import { ProtocolsListComponent } from '@modules/protocols/pages/protocols-list/protocols-list.component';
import { ProtocolsPanelComponent } from '@modules/protocols/pages/protocols-panel/protocols-panel.component';
import { ProtocolManageComponent } from '@modules/protocols/pages/protocol-manage/protocol-manage.component';
import { ProtocolsPanelManageComponent } from '@modules/protocols/pages/protocols-panel-manage/protocols-panel-manage.component';
import { ProtocolType } from '@shared/enums/protocol-type.enum';
import { ProtocolsGuard } from '@core/guards/protocols/protocols.guard';
import { ProtocolPreviewComponent } from '@modules/protocols/pages/protocol-preview/protocol-preview.component';
import { ProtocolDraftGuard } from '@core/guards/protocols/protocol-draft.guard';
import { ProtocolPreviewGuard } from '@core/guards/protocols/protocol-preview.guard';

export const ProtocolRoutes: CustomRoutes = [
  {
    path: '',
    canActivate: [ProtocolsGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/panel/protocols/list',
      },
      {
        path: 'list',
        name: 'protocols-list',
        component: ProtocolsPanelComponent,
        children: [
          {
            path: '',
            component: ProtocolsListComponent,
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'all',
              },
              {
                path: 'all',
                name: 'protocols-list-all',
                data: {
                  edit: true,
                  metaTitle: 'Protocols.list',
                  notRestorationPosition: true,
                },
              },
              {
                path: 'acceptance',
                name: 'protocols-list-acceptance',
                data: {
                  edit: true,
                  type: ProtocolType.TYPE_ACCEPTANCE,
                  metaTitle: 'Protocols.listAcceptance',
                  notRestorationPosition: true,
                },
              },
              {
                path: 'project',
                name: 'protocols-list-project',
                data: {
                  edit: true,
                  type: ProtocolType.TYPE_PROJECT,
                  metaTitle: 'Protocols.listProject',
                  notRestorationPosition: true,
                },
              },
              {
                path: 'meeting',
                name: 'protocols-list-meeting',
                data: {
                  edit: true,
                  type: ProtocolType.TYPE_MEETING,
                  metaTitle: 'Protocols.listMeeting',
                  notRestorationPosition: true,
                },
              },
              {
                path: 'drafts',
                name: 'protocols-list-drafts',
                data: {
                  edit: true,
                  type: ProtocolType.TYPE_DRAFT,
                  metaTitle: 'Protocols.listDrafts',
                  notRestorationPosition: true,
                },
              },
            ],
          },
        ],
      },
      {
        path: 'acceptance',
        name: 'acceptance-protocol',
        component: ProtocolsPanelManageComponent,
        children: [
          {
            path: 'draft/:id',
            component: ProtocolManageComponent,
            name: 'acceptance-protocol-new',
            canActivate: [ProtocolDraftGuard],
            data: {
              edit: true,
              type: ProtocolType.TYPE_ACCEPTANCE,
              metaTitle: 'Protocols.draftProtocol',
              hideHeader: true,
              notRestorationPosition: true,
            },
          },
          {
            path: 'edit/:id',
            component: ProtocolManageComponent,
            name: 'acceptance-protocol-edit',
            canActivate: [ProtocolDraftGuard],
            data: {
              edit: true,
              type: ProtocolType.TYPE_ACCEPTANCE,
              metaTitle: 'Protocols.editProtocol',
              hideHeader: true,
              notRestorationPosition: true,
            },
          },
          {
            path: 'preview/:id',
            component: ProtocolManageComponent,
            name: 'acceptance-protocol-preview',
            canActivate: [ProtocolPreviewGuard],
            data: {
              preview: true,
              edit: true,
              type: ProtocolType.TYPE_ACCEPTANCE,
              metaTitle: 'Protocols.previewProtocol',
              hideHeader: true,
              notRestorationPosition: true,
            },
          },
        ],
      },
      {
        path: 'project',
        name: 'project-protocol',
        component: ProtocolsPanelManageComponent,
        children: [
          {
            path: 'draft/:id',
            component: ProtocolManageComponent,
            name: 'project-protocol-new',
            canActivate: [ProtocolDraftGuard],
            data: {
              edit: true,
              type: ProtocolType.TYPE_PROJECT,
              metaTitle: 'Protocols.draftProtocol',
              hideHeader: true,
              notRestorationPosition: true,
            },
          },
          {
            path: 'edit/:id',
            component: ProtocolManageComponent,
            name: 'project-protocol-edit',
            canActivate: [ProtocolDraftGuard],
            data: {
              edit: true,
              type: ProtocolType.TYPE_PROJECT,
              metaTitle: 'Protocols.editProtocol',
              hideHeader: true,
              notRestorationPosition: true,
            },
          },
          {
            path: 'preview/:id',
            component: ProtocolManageComponent,
            name: 'project-protocol-preview',
            canActivate: [ProtocolPreviewGuard],
            data: {
              preview: true,
              edit: true,
              type: ProtocolType.TYPE_PROJECT,
              metaTitle: 'Protocols.previewProtocol',
              hideHeader: true,
              notRestorationPosition: true,
            },
          },
        ],
      },
      {
        path: 'meeting',
        name: 'meeting-protocol',
        component: ProtocolsPanelManageComponent,
        children: [
          {
            path: 'draft/:id',
            component: ProtocolManageComponent,
            name: 'meeting-protocol-new',
            canActivate: [ProtocolDraftGuard],
            data: {
              edit: true,
              type: ProtocolType.TYPE_MEETING,
              metaTitle: 'Protocols.draftProtocol',
              hideHeader: true,
              notRestorationPosition: true,
            },
          },
          {
            path: 'edit/:id',
            component: ProtocolManageComponent,
            name: 'meeting-protocol-edit',
            canActivate: [ProtocolDraftGuard],
            data: {
              edit: true,
              type: ProtocolType.TYPE_MEETING,
              metaTitle: 'Protocols.editProtocol',
              hideHeader: true,
              notRestorationPosition: true,
            },
          },
          {
            path: 'preview/:id',
            component: ProtocolManageComponent,
            name: 'meeting-protocol-preview',
            canActivate: [ProtocolPreviewGuard],
            data: {
              preview: true,
              edit: true,
              type: ProtocolType.TYPE_MEETING,
              metaTitle: 'Protocols.previewProtocol',
              hideHeader: true,
              notRestorationPosition: true,
            },
          },
        ],
      },
      {
        path: 'preview/:id',
        component: ProtocolPreviewComponent,
        name: 'protocol-preview',
        data: {
          client: false,
          metaTitle: 'Protocols.previewProtocol',
          notRestorationPosition: true,
        },
      },
    ],
  },
];
