<section class="container-fluid grey">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <div
          class="card card_sm left-tab"
          #tabs
          [ngClass]="{ 'pt-0': !tabsTemplate || hideTopTabIconOnMobile, 'pt-md-3': hideTopTabIconOnMobile }"
        >
          <app-content-tabs
            [selectTabsOnMobile]="selectTabsOnMobile"
            [tabsTemplate]="tabsTemplate"
            [tabsDescription]="tabsDescription"
            [tabsDescriptionTemplate]="tabsDescriptionTemplate"
            [tabLinks]="tabLinks"
            [hideTopTabIconOnMobile]="hideTopTabIconOnMobile"
          ></app-content-tabs>
        </div>
      </div>
      <div class="col-lg-9 col-md-12">
        <div class="card card_big">
          <div class="content-with-tabs content-with-tabs-content">
            <router-outlet *ngIf="!contentTemplate"></router-outlet>
            <ng-container *ngIf="contentTemplate" [ngTemplateOutlet]="contentTemplate"></ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
