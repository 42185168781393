import { ProjectAddressCellComponent } from './components/project-address-cell/project-address-cell.component';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ISideNavigationConfig } from '@shared/modules/side-navigation/interfaces/side-navigation-config.interface';
import { TranslateService } from '@ngx-translate/core';
import { NavigateService } from '@shared/services/navigate.service';
import { ISideNavigationTab } from '@shared/modules/side-navigation/interfaces/side-navigation-tab.interface';
import { projectsListSideNavigationConfig } from '@modules/projects/modules/projects-list/pages/projects-list/projects-list-side-config.const';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { ProjectController } from '@modules/projects/shared/controllers/project.controller';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { BaseListComponent } from '@shared/components/base-list/base-list.component';
import { ListService } from '@shared/modules/list/services/list.service';
import { ProjectsFiltersComponent } from '@modules/projects/modules/projects-list/pages/projects-list/components/projects-filters/projects-filters.component';
import { ProjectsListConfig } from '@modules/projects/modules/projects-list/shared/configs/projects-list.config';
import { ProjectPropertyTypeCellComponent } from '@modules/projects/modules/projects-list/pages/projects-list/components/project-property-type-cell/project-property-type-cell.component';
import { ProjectClientCellComponent } from '@modules/projects/modules/projects-list/pages/projects-list/components/project-client-cell/project-client-cell.component';
import { ProjectNameCellComponent } from '@modules/projects/modules/projects-list/pages/projects-list/components/project-name-cell/project-name-cell.component';
import { ProjectResponsibleClientCellComponent } from '@modules/projects/modules/projects-list/pages/projects-list/components/project-responsible-client-cell/project-responsible-client-cell.component';
import { ProjectResponsibleCompanyCellComponent } from '@modules/projects/modules/projects-list/pages/projects-list/components/project-responsible-company-cell/project-responsible-company-cell.component';
import { ProjectNameHeaderCellComponent } from '@modules/projects/modules/projects-list/pages/projects-list/components/project-name-header-cell/project-name-header-cell.component';
import { ProjectStage } from '@shared/enums/project-stage.enum';
import { GbxsoftSelectOption } from '@shared/interfaces/gbxsoft-select-option.interface';
import { SideNavigationViewComponent } from '@shared/modules/side-navigation/components/side-navigation-view/side-navigation-view.component';
import { NgSelectComponent } from '@ng-select/ng-select';
import { CustomTableColumn } from '@shared/modules/list/interfaces/custom-table-column.interface';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { Project } from '@modules/projects/shared/models/project.model';
import { ProjectLoseCellComponent } from '@modules/projects/modules/projects-list/pages/projects-list/components/project-lose-cell/project-lose-cell.component';
import { ButtonGroupConfig } from '@shared/modules/ui/components/button-group/button-group.component';
import { ProjectStageAPIService } from '@modules/projects/shared/services/project-stage-api.service';
import { IProjectStage } from '@modules/projects/shared/interfaces/project-stage.interface';
import { SideNavigationTabCountType } from '@shared/modules/side-navigation/enums/side-navigation-tab-count-type.enum';
import { ListEvent, ListEventType } from '@shared/modules/list/model/list-event.model';
import { CheckPermission } from '@core/permissions/check-permission';
import { ProjectStageCellComponent } from '@project-modules/projects-list/pages/projects-list/components/project-stage-cell/project-stage-cell.component';

@Component({
  selector: 'projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectsListComponent extends BaseListComponent implements OnInit, AfterViewInit {
  PermissionsGroups = PermissionsGroups;
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  ProjectsFiltersComponent = ProjectsFiltersComponent;

  buttonGroupConfig: ButtonGroupConfig = {
    buttons: [
      {
        name: this.t.instant('ProjectsList.list'),
        link: this.n.getPath('projects-list'),
        icon: '/assets/img/ic-list.svg',
      },
      {
        name: this.t.instant('ProjectsList.timeline'),
        link: this.n.getPath('projects-timeline'),
        icon: '/assets/img/ic-timeline.svg',
      },
    ],
  };

  loadingAddProject: boolean = false;
  configSideNavigation: ISideNavigationConfig;
  stages: GbxsoftSelectOption[] = [];

  @ViewChild('sideNavigationViewComponent') public sideNavigationViewComponent: SideNavigationViewComponent;
  @ViewChild('selectStage') selectStage: NgSelectComponent;

  @ViewChild('projectPropertyTypeCell') public projectPropertyTypeCell: ProjectPropertyTypeCellComponent;
  @ViewChild('projectClientCell') public projectClientCell: ProjectClientCellComponent;
  @ViewChild('projectNameCell') public projectNameCell: ProjectNameCellComponent;
  @ViewChild('projectStageCellComponent') public projectStageCellComponent: ProjectStageCellComponent;
  @ViewChild('projectResponsibleClientCell')
  public projectResponsibleClientCell: ProjectResponsibleClientCellComponent;
  @ViewChild('projectResponsibleCompanyCell')
  public projectResponsibleCompanyCell: ProjectResponsibleCompanyCellComponent;
  @ViewChild('projectNameHeaderCell') public projectNameHeaderCell: ProjectNameHeaderCellComponent;
  @ViewChild('projectLoseCell') public projectLoseCell: ProjectLoseCellComponent;
  @ViewChild('projectAddressCell') public projectAddressCell: ProjectAddressCellComponent;

  constructor(
    public t: TranslateService,
    public n: NavigateService,
    public changes: ChangeDetectorRef,
    public listService: ListService,
    private projectStageApiService: ProjectStageAPIService,
    public zone: NgZone,
  ) {
    super(changes, listService);
    ProjectsListConfig.rowClassFunction = this.rowClass.bind(this);
    this.initListConfig(ProjectsListConfig);
  }

  ngOnInit(): void {
    this.initSideNavigationConfig();
    this.setStages();
    this.listenUpdateList();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.setActiveTab();
  }

  initSideNavigationConfig() {
    this.zone.runOutsideAngular(() => {
      this.configSideNavigation = projectsListSideNavigationConfig(this.t);
      this.getStagesProjectsCount();
    });
  }

  listenUpdateList() {
    this.sub = this.listService.eventEmitter.subscribe((e: ListEvent<ProjectStage>) => {
      switch (e.type) {
        case ListEventType.ROW_REMOVED:
          this.decreaseCountInStage(e.data);
          break;
        case ListEventType.ROW_ADD:
          this.increaseCountInStage(e.data);
          break;
        case ListEventType.END_GET_ROWS:
          this.setProjectRows();
          // this.setActiveTabInSideNavigation();
          break;
      }
    });
  }

  private setProjectRows() {
    this.listService.rows = this.listService.rows.map((p: Project) => {
      p = new Project(p);
      const clientPermissionCtrl = new CheckPermission({
        group: PermissionsGroups.PROJECTS,
        action: 'CLIENT_VISIBILITY',
        objectCreatorId: [p?.basicDataBox?.responsibleEmployee?.id],
      });
      p.accessToClient = clientPermissionCtrl.check();

      const previewPermissionCtrl = new CheckPermission({
        group: PermissionsGroups.PROJECTS,
        action: 'PREVIEW',
        objectCreatorId: p?.basicDataBox?.responsibleEmployee?.id,
      });
      p.accessToPreview = previewPermissionCtrl.check();

      const editPermissionCtrl = new CheckPermission({
        group: PermissionsGroups.PROJECTS,
        action: 'EDIT',
        objectCreatorId: p?.basicDataBox?.responsibleEmployee?.id,
      });
      p.accessToEdit = editPermissionCtrl.check();

      const removePermissionCtrl = new CheckPermission({
        group: PermissionsGroups.PROJECTS,
        action: 'REMOVE',
        objectCreatorId: p?.basicDataBox?.responsibleEmployee?.id,
      });
      p.accessToRemove = removePermissionCtrl.check();

      const editSecondPermissionCtrl = new CheckPermission({
        group: PermissionsGroups.PROJECTS,
        action: 'EDIT_SECOND_BOX_FROM_LIST',
      });
      p.accessToEditSecondFromList = editSecondPermissionCtrl.check();

      return p;
    });
  }

  private decreaseCountInStage(stage: ProjectStage) {
    this.configSideNavigation.tabs.map((t: ISideNavigationTab) => {
      if (t.prop === stage && t.count - 1 >= 0) {
        t.count--;
      }
    });
    this.changes.detectChanges();
    this.sideNavigationViewComponent.sideNavigationBaseMenuComponent.changes.detectChanges();
  }

  private increaseCountInStage(stage: ProjectStage) {
    this.configSideNavigation.tabs.map((t: ISideNavigationTab) => {
      if (t.prop === stage) {
        t.count++;
      }
    });
    this.changes.detectChanges();
    this.sideNavigationViewComponent.sideNavigationBaseMenuComponent.changes.detectChanges();
  }

  setStages() {
    this.zone.runOutsideAngular(() => {
      this.stages = [
        {
          id: 'all',
          text: this.t.instant('ProjectsList.allStages'),
        },
      ];

      Object.values(ProjectStage).map((stage: string) => {
        this.stages.push({
          id: stage,
          text: this.t.instant('Projects.Stage.' + stage),
        });
      });
    });
  }

  setActiveTab() {
    if (this.listService?.filtersForm?.value['[a-stage][eq]']) {
      const selectedStage = this.listService?.filtersForm?.value['[a-stage][eq]'];
      this.onTabClick({ id: selectedStage }, true);
      this.configSideNavigation.tabs.map((t: ISideNavigationTab) => {
        t.active = t.prop === selectedStage;
      });
    } else {
      this.configSideNavigation.tabs[0].active = true;
      this.onTabClick({ id: 'all' }, true);
    }

    this.changes.detectChanges();
    this.sideNavigationViewComponent.sideNavigationBaseMenuComponent.changes.detectChanges();
  }

  onTabClick(clickedTab, isFromMobileSelect?: boolean) {
    const stage = isFromMobileSelect ? clickedTab?.id : clickedTab?.prop;
    this.selectStage.writeValue(stage);

    if (stage === 'all') {
      this.listService.setFilter('[a-stage][eq]', null);
    } else {
      this.listService.setFilter('[a-stage][eq]', stage);
    }

    this.listService.abortGetRequest();
    this.listService.setPage(1);
    this.listService.getRows().add(() => {
      if (stage === 'all') {
        this.listService.config.listTitle = 'ProjectsList.allStages';
      } else {
        this.listService.config.listTitle = 'Projects.Stage.' + stage;
      }
    });
  }

  setActiveTabInSideNavigation(stage?: ProjectStage) {
    const selectedStage = stage ? stage : this.listService?.filtersForm?.value['[a-stage][eq]'];

    this.configSideNavigation.tabs.map((t: ISideNavigationTab) => {
      t.active = t.prop === selectedStage;
    });

    if (!selectedStage) {
      this.configSideNavigation.tabs[0].active = true;
    }

    this.sideNavigationViewComponent.sideNavigationBaseMenuComponent.changes.detectChanges();
    this.changes.detectChanges();
  }

  addProject() {
    const ctrl = new ProjectController();
    this.loadingAddProject = true;
    ctrl.add().add(() => {
      this.loadingAddProject = false;
    });
  }

  activate(e: { type: string; row: Project; column: CustomTableColumn; event }) {
    if ((e.type === 'click' || e.type === 'touchstart') && !isNotNullOrUndefined(e.row.deleted)) {
      let hasNoSelect: boolean = false;
      const path = e.event.path || (e.event.composedPath && e.event.composedPath());
      path.map((el) => {
        if (el.classList && el.classList.contains('no-select')) {
          hasNoSelect = true;
        }
      });

      if (!e.column?.toggleMenu && !hasNoSelect && !document?.getSelection()?.toString()?.length) {
        const ctrl = new ProjectController(e.row as Project);
        ctrl.preview(e.row);
      }
    }
  }

  rowClass(row: Project) {
    const ctrl = new CheckPermission({
      group: PermissionsGroups.PROJECTS,
      action: 'PREVIEW',
      objectCreatorId: row?.basicDataBox?.responsibleEmployee?.id,
    });
    return !isNotNullOrUndefined(row.deleted) && ctrl.check() ? 'cursor-pointer' : '';
  }

  showToggleMenu(row: Project) {
    return !isNotNullOrUndefined(row.deleted);
  }

  getStagesProjectsCount() {
    this.projectStageApiService.getStages().subscribe({
      next: this.onSuccessGetStagesProjectsCount.bind(this),
    });
  }

  private onSuccessGetStagesProjectsCount(stages: IProjectStage[]) {
    let allProjectsIndexTab = null;
    let allProjectsCount = 0;
    this.configSideNavigation.tabs.map((tab: ISideNavigationTab, index: number) => {
      if (tab.prop === 'all') {
        allProjectsIndexTab = index;
      }
      stages.map((stage: IProjectStage) => {
        if (stage.stage === tab.prop) {
          tab.count = stage.projectsCount;
          allProjectsCount += tab.count;
          if (stage.stage === ProjectStage.STAGE_LOST) {
            tab.countType = SideNavigationTabCountType.DANGER;
          }
        }
      });
    });
    this.configSideNavigation.tabs[allProjectsIndexTab].count = allProjectsCount;
    this.sideNavigationViewComponent.sideNavigationBaseMenuComponent.changes.detectChanges();
  }
}
