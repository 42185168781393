<button
  #btn
  [title]="config?.text"
  [disabled]="disabled"
  [attr.type]="!!type ? type : null"
  (click)="onClick.emit($event)"
  class="btn d-flex-inline align-items-center"
  [ngClass]="ButtonClass"
  [tourAnchor]="tourAnchorName"
>
  <svg-icon [src]="config?.prependSrc" *ngIf="config?.prependSrc && !loading"></svg-icon>

  <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

  <span *ngIf="!!config?.text" class="d-flex-inline" [ngClass]="TextStyles" [innerText]="config?.text"></span>

  <svg-icon [src]="config?.appendSrc" *ngIf="config?.appendSrc"></svg-icon>
</button>
