<header class="d-flex align-items-center header" #header tourAnchor="protocolsList.s0.welcome">
  <nav class="container-fluid w-100">
    <div class="row">
      <div class="col-12 d-flex align-items-center justify-content-between p-0">
        <div class="d-flex align-items-center">
          <header-back-icon></header-back-icon>
          <header-hamburger></header-hamburger>
          <header-logo (click)="!!headerService.isMenuOpen ? headerService.closeMenu() : null"></header-logo>
          <header-navigation
            (click)="!!headerService.isMenuOpen ? headerService.closeMenu() : null"
            *ngIf="store.isLogged"
          ></header-navigation>
        </div>
        <header-action-menu
          (click)="!!headerService.isMenuOpen ? headerService.closeMenu() : null"
          *ngIf="store.isLogged"
        ></header-action-menu>
        <app-button
          *ngIf="!store.isLogged"
          class="mr-2"
          (click)="n.go('login'); !!headerService.isMenuOpen ? headerService.closeMenu() : null"
          [config]="{
            text: 'Auth.Login.signIn' | translate,
            type: ButtonTypes.PRIMARY_WHITE
          }"
        ></app-button>
      </div>
    </div>
  </nav>
</header>

<div [@enterAnimation] *ngIf="headerService.isMenuOpen" class="ui-mobile-nav">
  <div class="mobile-navigation-tabs">
    <div class="row justify-content-center">
      <ng-container *ngFor="let tabLink of tabLinks; let index = index">
        <a
          draggable="false"
          [routerLink]="n.getPath(tabLink.link)"
          [routerLinkActive]="['mobile-navigation-tabs-tab--active']"
          class="mobile-navigation-tabs-tab"
          (click)="headerService.closeMenu()"
          data-type="tab"
          id="tab{{ index }}"
        >
          <svg-icon [src]="tabLink.iconUrl"></svg-icon>
          <span [innerHTML]="tabLink.name | translate" class="mobile-navigation-tabs-tab-text"></span>
        </a>
      </ng-container>
    </div>
    <div class="mt-4">
      <div class="mobile-navigation-create">
        <app-button
          [class]="'btn-block font-16'"
          [matMenuTriggerFor]="createMenu"
          [config]="{
            type: ButtonTypes.PRIMARY_GREEN,
            size: ButtonSize.LARGE,
            text: 'Header.create' | translate
          }"
        ></app-button>
        <mat-menu #createMenu="matMenu" yPosition="above" class="mt-1">
          <button
            [permission]="{ group: PermissionsGroups.CONTACTS, action: 'ADD' }"
            mat-menu-item
            (click)="createContact()"
          >
            <span>{{ 'Header.CreateOptions.contact' | translate }}</span>
          </button>

          <button
            [permission]="{ group: PermissionsGroups.PROJECTS, action: 'ADD' }"
            mat-menu-item
            (click)="createProjectDraft()"
          >
            <span>{{ 'Header.CreateOptions.project' | translate }}</span>
          </button>

          <button
            mat-menu-item
            [permission]="{ group: PermissionsGroups.PROTOCOLS, action: 'ADD' }"
            (click)="createProtocol()"
          >
            <span>{{ 'Header.CreateOptions.protocol' | translate }}</span>
          </button>

          <button
            mat-menu-item
            [permission]="{ group: PermissionsGroups.TASKS, action: 'ADD' }"
            (click)="createTask()"
          >
            <span>{{ 'Header.CreateOptions.task' | translate }}</span>
          </button>

          <button
            mat-menu-item
            [permission]="{ group: PermissionsGroups.TASKS, action: 'ADD' }"
            (click)="createChange()"
          >
            <span>{{ 'Header.CreateOptions.change' | translate }}</span>
          </button>

          <button
            mat-menu-item
            [permission]="{ group: PermissionsGroups.CALENDAR, action: 'ADD_EVENT' }"
            (click)="createEvent()"
          >
            <span>{{ 'CalendarEvents.event' | translate }}</span>
          </button>
          <button
            mat-menu-item
            [permission]="{ group: PermissionsGroups.MAILINGS, action: 'MAILING_FUNCTION' }"
            (click)="createEmail()"
          >
            <span>{{ 'E-mail' | translate }}</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
