import { UserPerson } from '@shared/interfaces/user.interface';
import { Component, OnInit, ChangeDetectionStrategy, ViewChild, AfterViewInit } from '@angular/core';
import { SnackBarService } from '@core/services/snackbar.service';
import { StorageService } from '@core/services/storage.service';
import { GbxsoftErrorTypes } from '@form/src/lib/controllers/gbxsoft-form-control-error.controller';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '@shared/configs/config';
import { Regex } from '@shared/configs/regex';
import { EventSidenavService } from '@shared/modules/event-sidenav/services/event-sidenav.service';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import * as moment from 'moment';
import { PairTimepickerComponent } from '@shared/components/pair-timepicker/pair-timepicker.component';
import { NgSelectComponent } from '@ng-select/ng-select';
import { DateRangePickerComponent } from '@shared/components/date-rangepicker/date-rangepicker.component';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { CheckPermission } from '@core/permissions/check-permission';
import { Contact } from '@modules/contacts/shared/models/contact.model';

@Component({
  selector: 'event-edit',
  templateUrl: './event-edit.component.html',
  styleUrls: ['./event-edit.component.scss'],
})
export class EventEditComponent implements OnInit {
  Config = Config;

  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;
  @ViewChild('daterangepicker') daterangepicker: DateRangePickerComponent;
  @ViewChild('participants') participants: NgSelectComponent;
  @ViewChild('timepicker') timepicker: PairTimepickerComponent;

  firstLoad: boolean = false;
  canProjectAdd: boolean = false;

  constructor(
    public service: EventSidenavService,
    private t: TranslateService,
    private storage: StorageService,
    private s: SnackBarService,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.service.participants = this.participants;
    this.service.daterangepicker = this.daterangepicker;
    this.daterangepicker.setRange(this.service.calendarEvent.termStart, this.service.calendarEvent.termEnd);
    this.checkProjectPermissions();
  }

  validateEmail($event) {
    if (!!$event?.length && !Regex.email.test($event.toString())) {
      this.s.error(this.t.instant('FormErrors.email'));
      return null;
    }
    return this.service.createCustomTag($event);
  }

  successOnLoadContacts(resp) {
    this.service.setCreatorContact(resp[0]);
    return [...resp.map((i) => new Contact(i))];
  }

  checkProjectPermissions() {
    const ctrl = new CheckPermission({
      group: PermissionsGroups.PROJECTS,
      action: 'ADD',
      objectCreatorId: [],
    });
    this.canProjectAdd = ctrl.check();
  }

  errorMessages(name: string) {
    const messages = Config.validationMessages;
    const control = this.service.form.get(name);

    if (control?.errors?.maxlength?.requiredLength) {
      messages[GbxsoftErrorTypes.maxLength] = this.t.instant('FormErrors.maxLength', {
        number: control.errors?.maxlength?.requiredLength,
      });
    }

    if (control?.errors?.minlength?.requiredLength) {
      messages[GbxsoftErrorTypes.minLength] = this.t.instant('FormErrors.minLength', {
        number: control.errors?.minlength?.requiredLength,
      });
    }

    return messages;
  }
}
