<div
  class="task-sidenav-blue-preview"
  [ngClass]="{ 'task-sidenav-blue-preview--disabled': service.task?.isDeleted }"
  [formGroup]="service.form"
>
  <!-- #region Realization Term -->
  <div class="mb-3">
    <b class="mb-1">{{ 'Tasks.term' | translate }}</b>
    <div
      (click)="!service.task?.isDeleted && service.permissionCtrl.edit ? datepicker.picker.openMenu() : null"
      class="task-sidenav-blue-preview__subtitle"
    >
      {{
        service.selectedDate?.start === service.selectedDate?.end
          ? service.selectedDate?.start || ('Tasks.noRealizationTerm' | translate)
          : service.selectedDate?.start + ' - ' + service.selectedDate?.end
      }}
    </div>
    <daterangepicker
      #datepicker
      [remoteSelect]="true"
      [isCalendarTime]="true"
      (rangeChanged)="changeRange($event)"
    ></daterangepicker>
  </div>
  <!-- #endregion -->

  <!-- #region Task Group -->
  <div class="mb-3" *ngIf="service.isTaskSelection">
    <ng-select
      [hidden]="!service?.form?.get(service.TASK_SIDENAV_FORM.projectId)?.value"
      #taskGroup
      ngSelectExtension
      [url]="taskGroupURL"
      [formControlName]="service.TASK_SIDENAV_FORM.groupId"
      [bindValue]="'id'"
      [bindLabel]="'name'"
      class="select select-default w-100 mb-3 d-none"
      [notFoundText]="'Projects.notFoundText' | translate"
      [placeholder]="'Projects.taskGroup' | translate"
      [clearable]="false"
      [readonly]="!!service.task?.isDeleted || !service.permissionCtrl.edit"
      [translateLabelPrefix]="'Projects.TaskGroups.'"
      [responseTransform]="taskGroupTransform.bind(this)"
      [sortByField]="'sortOrder'"
    ></ng-select>

    <b class="mb-1">{{ 'Tasks.taskGroup' | translate }}</b>
    <div
      [ngClass]="{ disabled: service.task?.isDeleted || !service.permissionCtrl.edit }"
      [matMenuTriggerFor]="groups"
    >
      <div class="task-sidenav-blue-preview__subtitle">{{ service.selectedGroup?.text }}</div>
    </div>
    <mat-menu [class]="'custom-mat-menu'" #groups="matMenu" xPosition="after">
      <button
        (click)="setTaskGroup(i)"
        *ngFor="let i of TaskGroupList"
        [disabled]="service.selectedGroup?.id === i.id"
        [ngClass]="{
          'blue-500': service.selectedGroup?.id === i.id
        }"
        mat-menu-item
      >
        {{ i.text }}
      </button>
    </mat-menu>
  </div>
  <!-- #endregion -->

  <!-- #region Task Type -->
  <div class="mb-3">
    <b class="mb-1">{{ 'Tasks.taskType' | translate }}</b>
    <div
      [ngClass]="{ disabled: service.task?.isDeleted || !service.permissionCtrl.edit }"
      [matMenuTriggerFor]="types"
    >
      <div class="task-sidenav-blue-preview__subtitle">{{ service.selectedType?.name | translate }}</div>
    </div>
    <mat-menu [class]="'custom-mat-menu'" #types="matMenu" xPosition="after">
      <button
        *ngFor="let i of TaskTypeList"
        (click)="setTaskType(i)"
        [disabled]="service.selectedType?.type === i.type"
        [ngClass]="{
          'blue-500': service.selectedType?.type === i.type
        }"
        mat-menu-item
      >
        {{ i.name | translate }}
      </button>
    </mat-menu>
  </div>
  <!-- #endregion -->

  <!-- #region Task Assignments -->
  <div class="mb-3">
    <b>{{ 'Tasks.assignedTo' | translate }}</b>
    <employee-search
      #search
      [readonly]="service.task?.isDeleted || !service.permissionCtrl.edit"
      [initialValue]="service.assignedUsers"
      [projectId]="service.formProjectId"
      [form]="service.form"
      [controlName]="service.TASK_SIDENAV_FORM.assignedTo"
    ></employee-search>
  </div>
  <!-- #endregion -->

  <!-- #region Task Tags -->
  <div class="mb-3">
    <b class="mb-1">{{ 'Tasks.tags' | translate }}</b>
    <ng-select
      ngSelectExtension
      [url]="service.TagUrl"
      [bindValue]="'name'"
      [bindLabel]="'name'"
      [formControlName]="service.TASK_SIDENAV_FORM.tags"
      [closeOnSelect]="false"
      [addTag]="true"
      [multiple]="true"
      [clearable]="true"
      [tabIndex]="-1"
      [readonly]="service.task?.isDeleted || !service.permissionCtrl.edit"
      [notFoundText]="'Projects.notFoundText' | translate"
      [addTagText]="'Projects.addTaskTag' | translate"
      [clearAllText]="'clearAll' | translate"
      class="select select-default w-100 mb-4"
      [placeholder]="'Projects.tags' | translate"
      [enableSort]="false"
    ></ng-select>
  </div>
  <!-- #endregion -->
</div>
