import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { ProtocolsStatuses } from '@modules/protocols/shared/enums/protocols-statuses.enum';
import { BaseListFiltersComponentComponent } from '@shared/components/base-list-filters-component/base-list-filters-component.component';
import { Config } from '@shared/configs/config';
import { ProtocolType } from '@shared/enums/protocol-type.enum';
import { ProjectListItem } from '@shared/interfaces/project-list-item.interface';
import { ListService } from '@shared/modules/list/services/list.service';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ProtocolApiService } from '@modules/protocols/shared/services/protocol-api.service';
import { Employee } from '@shared/models/employee.model';
import { Contact } from '@modules/contacts/shared/models/contact.model';
import { ListSingleFilterRangeDateComponent } from '@shared/modules/list/components/list-filter/components/list-single-filter-range-date/list-single-filter-range-date.component';

@Component({
  selector: 'protocols-filters',
  templateUrl: './protocols-filters.component.html',
  styles: [],
})
export class ProtocolsFiltersComponent extends BaseListFiltersComponentComponent implements OnInit {
  Config = Config;

  isDraftTab: boolean = false;
  statuses = [];

  @ViewChild('dateRanePickerFilter') dateRanePickerFilter: ListSingleFilterRangeDateComponent;

  constructor(
    public service: ListService,
    public pService: ProtocolApiService,
    private route: ActivatedRoute,
  ) {
    super(service);
    this.checkDraftTab();
  }

  setIsDraftTab(type) {
    if (type === ProtocolType.TYPE_DRAFT) {
      this.isDraftTab = true;
    } else {
      this.isDraftTab = false;
    }
  }

  checkDraftTab() {
    this.setIsDraftTab(this.route.snapshot.firstChild?.data?.type);
    this.sub = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        mergeMap((route) => route.data),
      )
      .subscribe((routeData) => {
        this.setIsDraftTab(routeData.type);
      });
  }

  ngOnInit() {
    this.createForm();
    this.setStatuses();
  }

  shouldShowClearFilters() {
    let show = false;
    Object.keys(this.service.filtersForm.value).forEach((key) => {
      if (this.service?.filtersForm?.value[key]) {
        if (this.isDraftTab) {
          {
            if (key !== '[a-status][eq]') {
              show = true;
            }
          }
        } else {
          show = true;
        }
      }
    });
    return show;
  }

  clearFilters() {
    Object.keys(this.service?.filtersForm.value).forEach((key) => {
      if (this.isDraftTab) {
        if (key !== '[a-status][eq]') {
          this.service.filtersForm.get(key).setValue(null);
        }
      } else {
        this.service.filtersForm.get(key).setValue(null);
      }
    });
    this.dateRanePickerFilter.clearDateRangePicker();
  }

  createForm() {
    this.service.filtersForm = new FormGroup({
      '[a-created][gte]': new FormControl(null),
      '[a-created][lte]': new FormControl(null),
      '[creator-id][eq]': new FormControl(null),
      '[project-id][eq]': new FormControl(null),
      '[mainContact-id][eq]': new FormControl(null),
      '[a-status][eq]': new FormControl(null),
      '[deleted]': new FormControl(null),
    });
  }

  setStatuses() {
    this.statuses = [];
    Object.values(ProtocolsStatuses).map((key) => {
      if (key !== 'expired' && key !== 'rejected' && key !== 'modified') {
        this.statuses.push({
          id: key,
          name: this.t.instant('Protocols.Statuses.' + key),
        });
      }
    });
  }

  creatorName(employee: Employee) {
    const e = new Employee(employee);
    return e.userPerson.previewName;
  }

  mainContact(contact: Contact) {
    return new Contact(contact).fullName;
  }

  projectName(project: ProjectListItem) {
    return project.name ? project.name : '';
  }
}
