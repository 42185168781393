<protocol-header-manage [preview]="isPreview"></protocol-header-manage>
<section class="grey pb-0 animated-section protocols-creator pb-5">
  <protocols-data [hidden]="isPreview"></protocols-data>
  <protocols-data-preview [hidden]="!isPreview"></protocols-data-preview>
  <div class="mb-4" *ngIf="pService.manager?.protocol?.isChanged && !isPreview">
    <version-resend></version-resend>
  </div>

  <div class="container protocols-creator-container">
    <div class="row">
      <div *ngIf="WindowHelper.isLargeMD" class="col-12 col-lg-6 col-xl-6 laptop">
        <protocol-task-list
          [preview]="isPreview"
          [tourAnchor]="'protocolManage.s7.stuffsSection'"
        ></protocol-task-list>
      </div>
      <div class="col-12 col-lg-6 col-xl-6 laptop">
        <div class="bg-white p-3 pt-4">
          <ng-container *ngIf="pService.manager.protocol && !pService.loadingProtocol">
            <div class="d-flex align-items-start justify-content-between pb-1">
              <h2 class="blue-500 mb-3" [innerHTML]="title | translate"></h2>
              <div
                *ngIf="!pService.manager?.protocol?.isDraft"
                class="ml-2 d-flex flex-column justify-content-end align-items-end protocol-status-wrapper"
              >
                <div
                  class="protocol-status text-right"
                  [innerHTML]="'Protocols.protocolStatus' | translate"
                ></div>
                <protocol-status [protocol]="pService.manager.protocol"></protocol-status>
              </div>
            </div>

            <div class="protocol-manager">
              <ng-container *ngIf="isAcceptance">
                <protocol-type-selection [preview]="isPreview"></protocol-type-selection>
                <div
                  class="protocol-manager-section"
                  [ngClass]="{
                    valid: (pService?.manager?.ProtocolEntries)[EntryType.TYPE_ACCEPTANCE_EVIDENCE]?.length
                  }"
                >
                  <entry-list
                    [snackBottom]="true"
                    [preview]="isPreview"
                    [tourAnchor]="'protocolManage.s15.getBy'"
                    #acceptance_evidence
                    [id]="pService.manager.protocol.id"
                    [objectType]="currentObjectType"
                    [manageType]="EntryManageType.BUTTON"
                    [items]="pService.manager.ProtocolEntries[EntryType.TYPE_ACCEPTANCE_EVIDENCE]"
                    [loading]="acceptance_evidenceLoading"
                    (onEntry)="onEntry($event, EntryType.TYPE_ACCEPTANCE_EVIDENCE)"
                    [type]="EntryType.TYPE_ACCEPTANCE_EVIDENCE"
                    [buttonTitle]="'Protocols.addEntry' | translate"
                    [title]="'Protocols.getBy' | translate"
                    [placeholder]="'Protocols.enterData' | translate"
                    [attachmentsConfig]="attachmentEntryConfig"
                    [entryModalConfig]="{
                      addTitle: 'Protocols.getBy',
                      editTitle: 'Protocols.editRecord'
                    }"
                  >
                  </entry-list>
                </div>

                <ng-container
                  *ngIf="
                    pService?.manager.protocol?.acceptanceData?.type ===
                    pService?.manager.protocolAcceptanceType.PARTIAL
                  "
                >
                  <div
                    class="protocol-manager-section"
                    [ngClass]="{
                      valid: (pService?.manager?.ProtocolEntries)[EntryType.TYPE_ACCEPTANCE_STAGE]?.length
                    }"
                  >
                    <entry-list
                      [snackBottom]="true"
                      [preview]="isPreview"
                      #acceptance_stage
                      [manageType]="EntryManageType.BUTTON"
                      [id]="pService.manager.protocol.id"
                      [objectType]="currentObjectType"
                      [items]="pService.manager.ProtocolEntries[EntryType.TYPE_ACCEPTANCE_STAGE]"
                      [loading]="acceptance_stageLoading"
                      (onEntry)="onEntry($event, EntryType.TYPE_ACCEPTANCE_STAGE)"
                      [type]="EntryType.TYPE_ACCEPTANCE_STAGE"
                      [buttonTitle]="'Protocols.addEntry' | translate"
                      [title]="'Protocols.nextStage' | translate"
                      [placeholder]="'Protocols.getInfo' | translate"
                      [attachmentsConfig]="attachmentEntryConfig"
                      [entryModalConfig]="{
                        addTitle: 'Protocols.nextStage',
                        editTitle: 'Protocols.editRecord'
                      }"
                    >
                    </entry-list>
                  </div>
                </ng-container>

                <protocol-finance [preview]="isPreview"></protocol-finance>
                <protocol-changes [preview]="isPreview"></protocol-changes>
              </ng-container>

              <div
                *ngIf="isProject"
                class="protocol-manager-section"
                [ngClass]="{
                  valid: (pService?.manager?.ProtocolEntries)[EntryType.TYPE_WORKER_ENTRY]?.length
                }"
              >
                <entry-list
                  [snackBottom]="true"
                  [preview]="isPreview"
                  #worker_entry
                  [manageType]="EntryManageType.BUTTON"
                  [id]="pService.manager.protocol.id"
                  [objectType]="currentObjectType"
                  [items]="pService.manager.ProtocolEntries[EntryType.TYPE_WORKER_ENTRY]"
                  [loading]="worker_entryLoading"
                  (onEntry)="onEntry($event, EntryType.TYPE_WORKER_ENTRY)"
                  [type]="EntryType.TYPE_WORKER_ENTRY"
                  [buttonTitle]="'Protocols.addWorker' | translate"
                  [title]="'Protocols.employees' | translate"
                  [placeholder]="'Protocols.employeeDescription' | translate"
                  [attachmentsConfig]="attachmentEntryConfig"
                  [componentRef]="ProtocolEmployeeSearcherComponent"
                  [entryModalConfig]="{
                    addTitle: 'Protocols.addEmployee',
                    editTitle: 'Protocols.editEmployee'
                  }"
                >
                </entry-list>
              </div>

              <div
                *ngIf="isAcceptance || isProject"
                class="protocol-manager-section"
                [ngClass]="{
                  valid: (pService?.manager?.ProtocolEntries)[EntryType.TYPE_PHOTO_DOCUMENTATION]?.length
                }"
              >
                <entry-list
                  [snackBottom]="true"
                  [preview]="isPreview"
                  #photo_documentation
                  [manageType]="EntryManageType.BUTTON"
                  [id]="pService.manager.protocol.id"
                  [objectType]="currentObjectType"
                  [items]="pService.manager.ProtocolEntries[EntryType.TYPE_PHOTO_DOCUMENTATION]"
                  [loading]="photo_documentationLoading"
                  (onEntry)="onEntry($event, EntryType.TYPE_PHOTO_DOCUMENTATION)"
                  [type]="EntryType.TYPE_PHOTO_DOCUMENTATION"
                  [buttonTitle]="'Protocols.addEntry' | translate"
                  [title]="
                    (isAcceptance ? 'Protocols.photoDocumentation' : 'Protocols.photoDocumentationProject')
                      | translate
                  "
                  [placeholder]="
                    (isAcceptance ? 'Protocols.addDescriptionNeed' : 'Protocols.describeDone') | translate
                  "
                  [attachmentsConfig]="
                    isProject ? attachmentPhotoEntryConfig : attachmentPhotoDefaultEntryConfig
                  "
                  [entryModalConfig]="{
                    addTitle: isAcceptance
                      ? 'Protocols.photoDocumentation'
                      : 'Protocols.photoDocumentationProject',
                    editTitle: 'Protocols.editRecord'
                  }"
                >
                </entry-list>
              </div>
              <protocol-rate [preview]="isPreview" *ngIf="isProject"></protocol-rate>
              <protocol-confirmations *ngIf="(isEdit || isPreview) && hideHiddenTasks"></protocol-confirmations>
              <protocol-signatures
                *ngIf="isPreview || isEdit"
                [preview]="isPreview || isEdit"
              ></protocol-signatures>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</section>
