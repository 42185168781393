import { debounce } from '@shared/decorators/debounce.decorator';
import { ResourceApi } from '@fullcalendar/resource-common';
import { Component, OnInit, HostListener, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';
import { Config } from '@shared/configs/config';
import { WindowHelper } from '@shared/helpers/window.helper';
import { CalendarService } from '@modules/calendar/shared/services/calendar.service';

@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(private service: CalendarService, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.service.initialize();
  }

  ngAfterViewInit() {
    this.setCalendarHeaderPosition();
  }

  private setCalendarHeaderPosition() {
    const calendar = document.querySelector('.calendar');
    const header = document.querySelector('calendar-header');
    const calendarContent = document.querySelector('.calendar-main-section__content');
    if (WindowHelper.isLessMD) {
      this.renderer.insertBefore(calendar.parentElement, header, calendar);
    } else {
      this.renderer.insertBefore(calendarContent.parentElement, header, calendarContent);
    }
  }

  @HostListener('window:resize', ['$event'])
  @debounce(100)
  onDesktopResize() {
    this.setCalendarHeaderPosition();
  }

  ngOnDestroy() {
    this.service.resetViewState();
  }
}
