import { WindowHelper } from '@shared/helpers/window.helper';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BaseListComponent } from '@shared/components/base-list/base-list.component';
import { ProtocolsListConfig } from '@modules/protocols/shared/configs/protocols-list.config';
import { ProtocolNumberCellComponent } from './components/protocol-number-cell/protocol-number-cell.component';
import { ProtocolClientCellComponent } from './components/protocol-client-cell/protocol-client-cell.component';
import { ProtocolTypeCellComponent } from './components/protocol-type-cell/protocol-type-cell.component';
import { ProtocolCreatorCellComponent } from './components/protocol-creator-cell/protocol-creator-cell.component';
import { ProtocolStatusCellComponent } from './components/protocol-status-cell/protocol-status-cell.component';
import { ProtocolStuffsCellComponent } from './components/protocol-stuffs-cell/protocol-stuffs-cell.component';
import { ProtocolAddressCellComponent } from './components/protocol-address-cell/protocol-address-cell.component';
import { ListService } from '@shared/modules/list/services/list.service';
import { MatDialog } from '@angular/material/dialog';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { ProtocolsFiltersComponent } from './components/protocols-filters/protocols-filters.component';
import { MatDrawer } from '@angular/material/sidenav';
import { ProtocolType } from '@shared/enums/protocol-type.enum';
import { ProtolActionEmitter } from '@modules/protocols/shared/interfaces/protocol-action-emitter.interface';
import { ProtocolAction } from '@modules/protocols/shared/enums/protocol-action.enum';
import { CustomTableColumn } from '@shared/modules/list/interfaces/custom-table-column.interface';
import { ProtocolApiService } from '@modules/protocols/shared/services/protocol-api.service';
import { Protocol } from '@modules/protocols/shared/models/protocol';
import { ProtocolController } from '@modules/protocols/shared/controllers/protocol.controller';
import { PermissionsGroups } from '@core/permissions/permissions.group';
import { CheckPermission } from '@core/permissions/check-permission';
import { TreeStatusEnum } from '@shared/modules/list/enums/tree-status.enum';
import { ProtocolListRecord } from '@modules/protocols/shared/interfaces/protocol-list-record.interface';
import { AppTourService } from '@shared/modules/app-tour/shared/services/app-tour.service';
import { AppTourTypes } from '@shared/modules/app-tour/shared/enums/app-tour-types.enum';
import {
  AppTourEvent,
  AppTourEventType,
} from '@shared/modules/app-tour/shared/interfaces/app-tour-event.interface';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { ProtocolsStatuses } from '@modules/protocols/shared/enums/protocols-statuses.enum';
import { Acceptance } from '@modules/protocols/shared/interfaces/acceptance.interface';
import { ButtonGroupConfig } from '@shared/modules/ui/components/button-group/button-group.component';
import { MediaQuery } from '@shared/configs/media-query';
import { Config } from '@shared/configs/config';
import { ProjectPreviewService } from '@modules/projects/modules/project-preview/services/project-preview.service';
import { ProtocolProjectNameCellComponent } from '@modules/protocols/pages/protocols-list/components/protocol-project-name-cell/protocol-project-name-cell.component';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import { TabsComponent } from '@shared/modules/ui/components/tabs/tabs.component';
import { ITaskActionEvent } from '@shared/modules/task-sidenav/interfaces/task-action-event.interface';
import { ETaskAction } from '@shared/modules/task-sidenav/enums/task-action.enum';
import { TaskSidenavService } from '@shared/modules/task-sidenav/services/task-sidenav.service';
import { ListEvent, ListEventType } from '@shared/modules/list/model/list-event.model';
import { Project } from '@modules/projects/shared/models/project.model';

@Component({
  selector: 'protocols-list',
  templateUrl: './protocols-list.component.html',
  styleUrls: ['./protocols-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProtocolsListComponent extends BaseListComponent implements AfterViewInit, OnInit {
  ProtocolsFiltersComponent = ProtocolsFiltersComponent;
  buttonsGroupConfig: ButtonGroupConfig;
  ButtonSize = ButtonSize;
  ButtonTypes = ButtonTypes;
  WindowHelper = WindowHelper;

  projectId: number;
  @Input() standalone: boolean = false;

  @ViewChild('sidenavPreview') public sidenavPreview: MatDrawer;
  @ViewChild('protocolNumber') public protocolNumber: ProtocolNumberCellComponent;
  @ViewChild('protocolClient') public protocolClient: ProtocolClientCellComponent;
  @ViewChild('protocolType') public protocolType: ProtocolTypeCellComponent;
  @ViewChild('protocolCreator') public protocolCreator: ProtocolCreatorCellComponent;
  @ViewChild('protocolStatus') public protocolStatus: ProtocolStatusCellComponent;
  @ViewChild('protocolStuffs') public protocolStuffs: ProtocolStuffsCellComponent;
  @ViewChild('protocolAddress') public protocolAddress: ProtocolAddressCellComponent;
  @ViewChild('protocolProjectName') public protocolProjectName: ProtocolProjectNameCellComponent;
  @ViewChild('tabsComponent') public tabsComponent: TabsComponent;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeSidenavPreview();
  }

  protocolController: ProtocolController;

  constructor(
    public listService: ListService,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public pService: ProtocolApiService,
    public changes: ChangeDetectorRef,
    public appTourService: AppTourService,
    private taskSidenavService: TaskSidenavService,
    private projectPreviewService: ProjectPreviewService,
  ) {
    super(changes, listService);
    this.checkProjectId();
    ProtocolsListConfig.treeAction.treeActionFunction = this.treeAction.bind(this);
    ProtocolsListConfig.treeAction.treeDisabledFunction = this.treeDisabled.bind(this);

    this.protocolController = new ProtocolController(this.pService);
    this.checkColumnInListForClientType();
    this.listenChangeTab();
    this.listenProtocolsEvents();
  }

  ngOnInit() {
    if (
      !this.store.UserPerson.shownTutorials ||
      !this.store.UserPerson.shownTutorials[AppTourTypes.PROTOCOLS_LIST]
    ) {
      this.appTourService.initTour(AppTourTypes.PROTOCOLS_LIST);
    }
    this.setButtonsGroupConfig();
    this.listenProjectChangeChanges();
  }

  ngAfterViewInit() {
    this.listenQuickPreviewClose();
    this.checkEditTour();
    super.ngAfterViewInit();
    this.listenEndAppTour();
  }

  addProtocol() {
    const ctrl = new ProtocolController(null, null);
    ctrl.add(this.projectId);
  }

  checkProjectId() {
    if (this.route?.parent?.parent?.snapshot?.params?.projectId) {
      this.projectId = this.route?.parent?.parent?.snapshot?.params?.projectId;
      ProtocolsListConfig.url += '?filters[project.id][eq]=' + this.projectId;
    } else {
      ProtocolsListConfig.url = `${Config.API}/protocol`;
    }
  }

  setButtonsGroupConfig() {
    this.buttonsGroupConfig = {
      buttons: [
        {
          name: this.t.instant('Protocols.List.tabs.all'),
          link: this.n.getPath(
            (this.standalone ? 'project-' : '') + 'protocols-list-all',
            {},
            {
              projectId: this.projectId,
              id: this.projectId,
            },
          ),
        },
        {
          name: this.t.instant('Protocols.List.tabs.meeting'),
          link: this.n.getPath(
            (this.standalone ? 'project-' : '') + 'protocols-list-meeting',
            {},
            {
              projectId: this.projectId,
              id: this.projectId,
            },
          ),
        },
        {
          name: this.t.instant('Protocols.List.tabs.project'),
          link: this.n.getPath(
            (this.standalone ? 'project-' : '') + 'protocols-list-project',
            {},
            {
              projectId: this.projectId,
              id: this.projectId,
            },
          ),
        },
        {
          name: this.t.instant('Protocols.List.tabs.acceptance'),
          link: this.n.getPath(
            (this.standalone ? 'project-' : '') + 'protocols-list-acceptance',
            {},
            {
              projectId: this.projectId,
              id: this.projectId,
            },
          ),
        },
        {
          name: this.t.instant('Protocols.List.tabs.draft'),
          link: this.n.getPath(
            (this.standalone ? 'project-' : '') + 'protocols-list-drafts',
            {},
            {
              projectId: this.projectId,
              id: this.projectId,
            },
          ),
        },
      ],
      mobileDropdown: MediaQuery.XL,
    };
  }

  listenProjectChangeChanges() {
    this.sub.add(
      this.taskSidenavService.taskActionEmitter.subscribe((e: ITaskActionEvent) => {
        switch (e.action) {
          case ETaskAction.ACTION_CREATE:
          case ETaskAction.ACTION_DELETE:
          case ETaskAction.ACTION_UPDATE:
          case ETaskAction.ACTION_SHARE:
            this.listService.getRows();
            break;
        }
      }),
    );
  }

  listenEndAppTour() {
    this.appTourService.emmiter.subscribe((e: AppTourEvent) => {
      switch (e.type) {
        case AppTourEventType.APP_TOUR_END:
          this.list.service?.clearParams();
          this.list.service?.getRows();
          break;
      }
    });
  }

  private setProtocolsRows() {
    if (!this.listService || !this.list) return;
    this.listService.rows = this.listService?.rows?.map((p: ProtocolListRecord) => {
      p = new ProtocolListRecord(p);

      const editPermissionCtrl = new CheckPermission({
        group: PermissionsGroups.PROTOCOLS,
        action: 'EDIT',
        objectCreatorId: p?.protocol?.creator.id,
      });
      p.accessToEdit = editPermissionCtrl.check();

      const previewPermissionCtrl = new CheckPermission({
        group: PermissionsGroups.PROTOCOLS,
        action: 'PREVIEW',
        objectCreatorId: p?.protocol?.creator.id,
      });
      p.accessToPreview = previewPermissionCtrl.check();

      const signaturePermissionCtrl = new CheckPermission({
        group: PermissionsGroups.PROTOCOLS,
        action: 'SIGNATURE',
        objectCreatorId: [
          p?.protocol?.creator?.id,
          p?.protocol?.project?.basicDataBox?.mainContact?.id,
          p?.protocol?.project?.basicDataBox?.responsibleEmployee?.id,
        ],
      });
      p.accessToSignature = signaturePermissionCtrl.check();

      const acceptancePermissionCtrl = new CheckPermission({
        group: PermissionsGroups.PROTOCOLS,
        action: 'ACCEPTANCE',
      });
      p.accessToAcceptance = acceptancePermissionCtrl.check();

      const removePermissionCtrl = new CheckPermission({
        group: PermissionsGroups.PROTOCOLS,
        action: 'SEND',
        objectCreatorId: [p?.protocol?.creator.id],
      });
      p.accessToRemove = removePermissionCtrl.check();

      return p;
    });
  }

  checkEditTour() {
    if (this.route.snapshot.queryParams['tutorial'] === '1') {
      this.appTourService.initTour(AppTourTypes.PROTOCOLS_LIST);
    }
  }

  checkColumnInListForClientType() {
    ProtocolsListConfig.columns.map((col: CustomTableColumn, index: number) => {
      if (this.employee.isClient) {
        if (col.prop === 'completedTaskCount' || col.prop === 'protocol.externalClients') {
          col.hide = true;
        }
      } else {
        col.hide = false;
      }
    });
    this.initListConfig(ProtocolsListConfig);
  }

  setTabToList(type: ProtocolType) {
    if (this.activeType === type) {
      return;
    }
    this.activeType = type;

    if (type === ProtocolType.TYPE_DRAFT) {
      this.listService.setFilter('[a-status][eq]', type); // set draft status if move to drafts tab
      this.listService.setFilter('[a-type][eq]', null);
    } else if (type) {
      this.listService.setFilter('[a-type][eq]', type);

      if (this.n.previousUrl.indexOf('drafts') !== -1) {
        //store status if you move between different tab than drafts
        this.listService.setFilter('[a-status][eq]', null);
      }
    } else {
      this.listService.setFilter('[a-type][eq]', null);

      if (this.n.previousUrl.indexOf('drafts') !== -1) {
        //store status if you move between different tab than drafts
        this.listService.setFilter('[a-status][eq]', null);
      }
    }
    if (this.listService.config) {
      this.listService.setPage(1);
      this.listService.getRows();
      this.listService.config.listTitle =
        'Protocols.List.tabs.' + (isNotNullOrUndefined(type) ? type : 'all');
    } else {
      this.config.listTitle = 'Protocols.List.tabs.' + (isNotNullOrUndefined(type) ? type : 'all');
    }
    this.changes.detectChanges();
  }

  listenListEvents() {
    super.listenListEvents();
    const sub = this.listService.eventEmitter.subscribe((e: ListEvent<any>) => {
      switch (e.type) {
        case ListEventType.END_GET_ROWS:
          this.setProtocolsRows();
          break;
      }
    });
    this.sub.add(sub);
  }

  listenChangeTab() {
    this.sub = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        mergeMap((route) => route.data),
      )
      .subscribe((routeData) => {
        this.setTabToList(routeData.type);
      });
  }

  listenProtocolsEvents() {
    const sub = this.pService.manager.protocolAction.subscribe((e: ProtolActionEmitter) => {
      if (e.type === ProtocolAction.RELOAD_LIST) {
        this.listService.getRows();
      }
    });
    this.sub.add(sub);
  }

  listenQuickPreviewClose() {
    const sub = this.sidenavPreview._closedStream.subscribe((e) => {
      this.selected = [];
      this.changes.detectChanges();
    });
    this.sub.add(sub);
    this.changes.detectChanges();
  }

  activate(e: {
    type: string;
    row: { protocol: Protocol; expanded?: boolean };
    column: CustomTableColumn;
    event;
  }) {
    if (e.type === 'dblclick' && !e.row?.expanded && !isNotNullOrUndefined(e.row.protocol.deleted)) {
      const ctrl = new CheckPermission({
        group: PermissionsGroups.PROTOCOLS,
        action: 'PREVIEW',
        objectCreatorId: e.row.protocol.creator.id,
      });

      if (!ctrl.check()) {
        return;
      }

      const protocolController = new ProtocolController(this.pService, e.row.protocol);
      protocolController.preview(this.employee);
    }

    if ((e.type === 'click' || e.type === 'touchstart') && !isNotNullOrUndefined(e.row.protocol.deleted)) {
      if (e.row?.expanded) {
        this.closeSidenavPreview();
        return;
      }

      if (!e.column?.toggleMenu && !e.event.target.classList.contains('no-select') && this.selected.length) {
        this.sidenavPreview.open();
        this.changes.detectChanges();
      } else {
        this.closeSidenavPreview();
      }
    }

    if (isNotNullOrUndefined(e.row.protocol.deleted)) {
      this.selected = [];
    }

    if (e.type === 'unselect') {
      this.closeSidenavPreview();
    }
  }

  closeSidenavPreview() {
    this.selected = [];
    this.sidenavPreview.close();
    this.changes.detectChanges();
    this.list.changes.detectChanges();
  }

  treeAction(event: { row: ProtocolListRecord }) {
    this.closeSidenavPreview();
    const row = event.row;
    if (row.treeStatus === TreeStatusEnum.COLLAPSED || !row.treeStatus) {
      this.openTree(row);
    } else {
      this.closeTree(row);
    }
  }

  openTree(row: ProtocolListRecord): void {
    row.treeStatus = TreeStatusEnum.LOADING;
    this.getProtocolVersions(row.protocol).subscribe({
      next: (records) => {
        this.onGetProtocolVersionsSuccess(records, row);
      },
      error: (e) => {
        console.log(e);
        row.treeStatus = TreeStatusEnum.COLLAPSED;
        this.s.error(this.t.instant('Protocols.errorProtocolsVersionDownload'));
      },
    });
  }

  closeTree(row: ProtocolListRecord): void {
    row.treeStatus = TreeStatusEnum.COLLAPSED;
    this.list.service.rows = [...this.list.service.rows];
  }

  treeDisabled(event: { row: ProtocolListRecord }) {
    if (this.employee.isClient) {
      return true;
    }

    if (!event.row?.protocol?.idNumber) {
      //protocol draft
      return true;
    }

    if (event.row?.protocol?.isAcceptanceRecord) {
      //acceptance record - not main protocol object
      return true;
    }

    // if the last changes was sent to client - do not show tree menu
    if (
      event.row?.protocol?.lastAcceptance &&
      event.row?.protocol?.lastAcceptance.status === ProtocolsStatuses.STATUS_SENT &&
      event?.row?.protocol?.sentAcceptances?.length <= 1 &&
      event.row.protocol.status === ProtocolsStatuses.STATUS_SENT
    ) {
      return true;
    }

    if (!event.row?.protocol?.lastAcceptance) {
      return true;
    }

    return false;
  }

  onGetProtocolVersionsSuccess(records: ProtocolListRecord[], row): void {
    for (let i = 0; i < records.length; i++) {
      const record = records[i];

      for (let j = 0; j < this.list.service.rows.length; j++) {
        const currentRecord: ProtocolListRecord = this.list.service.rows[j];
        if (Number(record.protocol.id) === Number(currentRecord.protocol.id)) {
          records.splice(i, 1);
          i--;
          continue;
        }
      }
    }

    this.list.service.rows = [...this.list.service.rows, ...records];
    this.list.table.count += records.length;
    row.treeStatus = TreeStatusEnum.EXPANDED;
    this.list.changes.detectChanges();
  }

  private getProtocolVersions(currentProtocol: Protocol) {
    return this.pService.getSimpleProtocol(currentProtocol.id, true).pipe(
      map((protocol) => {
        const acceptances = [];
        for (let i = 0; i < protocol.sentAcceptances.length; i++) {
          const acceptance = protocol.sentAcceptances[i];

          // if the last protocol was sent and there were no other changes, then do not show another version on list
          if (
            currentProtocol.status === ProtocolsStatuses.STATUS_SENT &&
            i === 0 &&
            acceptance.status === ProtocolsStatuses.STATUS_SENT
          ) {
            continue;
          }

          const acceptanceProtocol: Protocol = this.transformAcceptanceToProtocol(
            currentProtocol,
            acceptance,
          );
          acceptances.push({
            protocol: acceptanceProtocol,
            acceptanceId: currentProtocol.id,
            expanded: true,
          });
        }
        return acceptances.reverse();
      }),
    );
  }

  showToggleMenu(row: ProtocolListRecord) {
    const ctrl = new CheckPermission({
      group: PermissionsGroups.PROTOCOLS,
      action: 'PREVIEW',
      objectCreatorId: row?.protocol?.creator.id,
    });
    return !isNotNullOrUndefined(row.protocol.deleted) && ctrl.check();
  }

  private transformAcceptanceToProtocol(currentProtocol: Protocol, acceptance: Acceptance) {
    return new Protocol({
      id: acceptance.id,
      idNumber: currentProtocol?.idNumber,
      project: currentProtocol?.project,
      acceptanceNote: acceptance.acceptanceNote,
      type: currentProtocol.type,
      created: acceptance.created,
      status: acceptance.status,
      modified: acceptance.modified,
      externalClients: currentProtocol?.externalClients,
      tasks: currentProtocol?.tasks,
      externalCompaniesContacts: currentProtocol?.externalCompaniesContacts,
      creator: currentProtocol?.creator,
      lastAcceptance: currentProtocol?.lastAcceptance,
      isAcceptanceRecord: true,
      clientSignature: currentProtocol?.clientSignature,
      companySignature: currentProtocol?.companySignature,
      weather: currentProtocol?.weather,
    });
  }
}
