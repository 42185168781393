<div class="mobile-navigation" #mobileNavigation>
  <div class="mobile-navigation-header">
    <div class="mobile-navigation-header-actions">
      <avatar
        class="mr-3"
        [imageUrl]="imageUrl"
        [width]="64"
        (click)="closeMenu(); n.go('user-settings')"
      ></avatar>
      <header-action-menu-notification></header-action-menu-notification>
      <svg-icon
        [src]="'/assets/img/ic-help-circle-outline.svg'"
        [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
        class="ml-3"
        (click)="closeMenu(); n.go('faq')"
      ></svg-icon>
    </div>
    <header-action-menu-company></header-action-menu-company>
  </div>
  <div class="mobile-navigation-tabs">
    <ng-container *ngFor="let tabLink of tabLinks; let index = index">
      <a
        draggable="false"
        [routerLink]="n.getPath(tabLink.link)"
        [routerLinkActive]="['mobile-navigation-tabs-tab--active']"
        (click)="closeMenu()"
        class="mobile-navigation-tabs-tab"
        data-type="tab"
        id="tab{{ index }}"
      >
        <svg-icon [src]="tabLink.iconUrl" [svgStyle]="{ 'width.px': 30, 'height.px': 30 }"></svg-icon>
        <span [innerHTML]="tabLink.name | translate" class="mobile-navigation-tabs-tab-text"></span>
      </a>
    </ng-container>
  </div>
  <div class="mobile-navigation-create">
    <app-button
      [class]="'btn-block'"
      [matMenuTriggerFor]="createMenu"
      [config]="{
        type: ButtonTypes.PRIMARY_WHITE,
        size: ButtonSize.SMALL,
        text: 'Header.create' | translate
      }"
    ></app-button>
    <mat-menu #createMenu="matMenu" yPosition="above" class="mt-1">
      <button
        [permission]="{ group: PermissionsGroups.CONTACTS, action: 'ADD' }"
        mat-menu-item
        (click)="createContact()"
      >
        <span>{{ 'Header.CreateOptions.contact' | translate }}</span>
      </button>

      <button
        [permission]="{ group: PermissionsGroups.PROJECTS, action: 'ADD' }"
        mat-menu-item
        (click)="createProjectDraft()"
      >
        <span>{{ 'Header.CreateOptions.project' | translate }}</span>
      </button>

      <button
        mat-menu-item
        [permission]="{ group: PermissionsGroups.PROTOCOLS, action: 'ADD' }"
        (click)="createProtocol()"
      >
        <span>{{ 'Header.CreateOptions.protocol' | translate }}</span>
      </button>

      <button
        mat-menu-item
        [permission]="{ group: PermissionsGroups.TASKS, action: 'ADD' }"
        (click)="createTask()"
      >
        <span>{{ 'Header.CreateOptions.task' | translate }}</span>
      </button>

      <button
        mat-menu-item
        [permission]="{ group: PermissionsGroups.TASKS, action: 'ADD' }"
        (click)="createChange()"
      >
        <span>{{ 'Header.CreateOptions.change' | translate }}</span>
      </button>

      <button
        mat-menu-item
        [permission]="{ group: PermissionsGroups.CALENDAR, action: 'ADD_EVENT' }"
        (click)="createEvent()"
      >
        <span>{{ 'CalendarEvents.event' | translate }}</span>
      </button>
      <button
        mat-menu-item
        [permission]="{ group: PermissionsGroups.MAILINGS, action: 'MAILING_FUNCTION' }"
        (click)="createEmail()"
      >
        <span>{{ 'E-mail' | translate }}</span>
      </button>
    </mat-menu>
  </div>
</div>
