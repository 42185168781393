<div class="ui-project__header d-flex justify-content-between align-items-center flex-wrap">
  <div class="d-flex align-items-center flex-wrap">
    <h3 class="mb-0">{{ 'Projects.PreviewTabs.tasks' | translate }}</h3>
    <div class="d-flex flex-wrap">
      <app-button
        class="mr-2 my-1 my-lg-0"
        [permission]="{ group: PermissionsGroups.TASKS, action: 'ADD' }"
        (onClick)="createNewTask()"
        [config]="{
          size: ButtonSize.SMALL,
          type: ButtonTypes.PRIMARY_GREEN,
          text: WindowHelper.isMobileWidth ? '' : ('Projects.newTask' | translate),
          prependSrc: './assets/img/ic-plus-add.svg'
        }"
      ></app-button>
      <app-button
        class="my-1 my-lg-0"
        (onClick)="createNewTaskGroup()"
        [permission]="{ group: PermissionsGroups.PROJECTS, action: 'EDIT' }"
        [config]="{
          size: ButtonSize.SMALL,
          type: ButtonTypes.SECONDARY_BLUE,
          text: 'Projects.newTaskGroup' | translate,
          prependSrc: './assets/img/ic-plus-add.svg'
        }"
      ></app-button>
    </div>
  </div>
  <div class="form-control-checkbox flex-shrink">
    <label class="m-0 d-flex align-items-center">
      <input
        type="checkbox"
        [checked]="listService?.filtersForm?.get('[show_completed]')?.value === 1"
        (change)="showCompletedChange($event)"
      />
      <span></span>
      <span class="flex-shrink inline-flex">{{ 'ProjectTasks.showCompleted' | translate }}</span>
    </label>
  </div>
</div>
<div class="project-tasks">
  <!-- #region Content -->
  <div class="project-tasks__content">
    <div class="project-tasks__content__tasks">
      <list-tasks
        #listTasksComponent
        [filters]="ProjectTasksFiltersComponent"
        [config]="config"
        [projectId]="projectPreviewService.project.id"
      ></list-tasks>
    </div>
  </div>
  <!-- #endregion -->

  <!-- #region Right Section -->
  <!-- <project-tasks-right-section></project-tasks-right-section> -->
  <!-- #endregion -->
</div>
