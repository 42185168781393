<div class="project-question-directory-sidenav">
  <div class="pr-4 project-question-directory">
    <div>
      <sidenav-title
        [title]="('Projects.Menu.questions' | translate) + ' 1'"
        [template]="stuffsHeadTemplate"
      ></sidenav-title>
      <project-question-directory-breadcrumbs
        [componentRefName]="'ProjectQuestionDirectoryFirstSidenavComponent'"
      ></project-question-directory-breadcrumbs>

      <project-question-directory-first-info-box
        [project]="project"
      ></project-question-directory-first-info-box>

      <div class="mt-4">
        <!-- <directory-first-tasks></directory-first-tasks> -->
      </div>
    </div>
    <hr />
    <commentary-manage
      #comments
      [attachmentsConfig]="attachmentsConfig"
      [viewType]="CommentaryViewType.COMMENTS_FIRST"
      [id]="projectStore.projectCtrl?.project?.id"
      [type]="CommentaryType.BASIC_DATA_BOX"
      [commentaries]="project?.basicDataBox.comments"
      (commentaryEvent)="updateCommentaries($event.data, 'dataBox2')"
    ></commentary-manage>
  </div>
</div>

<ng-template #stuffsHeadTemplate>
  <div class="w-100 w-sm-auto d-flex flex-column flex-md-row d-md-block mt-3 mt-md-0">
    <app-button
      (onClick)="goToEdit()"
      [permission]="{
        group: PermissionsGroups.PROJECTS,
        action: 'PROJECT_BOX_FIRST_EDIT',
        objectCreatorId: projectStore.projectCtrl.project.projectEditIDs
      }"
      [config]="{
        type: ButtonTypes.PRIMARY_BLUE,
        size: ButtonSize.LARGE,
        text: (isBasicBox2Filled ? 'Projects.edit' : 'Projects.projectBoxFillText') | translate,
        prependSrc: './assets/img/ic-edit-3-white.svg'
      }"
    >
    </app-button>
  </div>
</ng-template>
