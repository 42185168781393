<div class="task-sidenav-white-content d-flex flex-column justify-content-between h-100">
  <div class="task-sidenav-white-content__container h-100">
    <div>
      <sidenav-title
        [closeFunc]="service.discardSaving.bind(this.service)"
        [title]="sidenavTitle | translate"
        [template]="service.isConfigChange ? stuffsHeadTemplateChanges : stuffsHeadTemplateTasks"
      ></sidenav-title>

      <!-- #region Task Content -->
      <task-basic-preview-data *ngIf="service.isPreview && service.task"></task-basic-preview-data>
      <task-basic-edit-data
        (onSubmit)="onSubmit.emit()"
        *ngIf="service.isCreation || (service.isEdit && service.task)"
      ></task-basic-edit-data>
      <!-- #endregion -->

      <ng-container *ngIf="!!service.connectedTask && service.isPreview">
        <div class="d-flex justify-content-between align-items-center task__data-connected-task">
          <span>{{ 'Projects.PreviewTabs.changes' | translate }}</span>
          <span
            (click)="
              service.permissionCtrl.linkedPreview
                ? taskManager.previewTask(service.task?.linkedChange)
                : null
            "
            class="task__data-connected-task-title elipsis"
            [ngClass]="{ pointer: service.permissionCtrl.linkedPreview }"
            [title]="service.connectedTask.description"
          >
            {{ service.connectedTask.description }}
          </span>
        </div>
        <hr class="mb-0" />
      </ng-container>

      <!-- #region Subtasks -->
      <ng-container
        *ngIf="
          service.isTaskSelection &&
          service.isPreview &&
          !(service.task?.isDeleted && !service.task?.children?.length)
        "
      >
        <!-- || (service.permissionCtrl.add && service.isPreview) -->
        <subtasks-manager
          #subtasks
          [parentTask]="service?.task"
          [disabled]="!!service.task?.isDeleted || !service.permissionCtrl.edit"
          [parentId]="service.task?.id"
          [type]="service.task?.type"
          [taskList]="service.task?.children"
        ></subtasks-manager>
        <hr class="mt-0" [ngClass]="{ 'mt-0': !subtasks?.addingTask }" />
      </ng-container>
      <!-- #endregion -->

      <ng-container
        *ngIf="
          service.isConfigChange &&
          service.isPreview &&
          service.task?.isChangeStatusEdit &&
          service.permissionCtrl.share
        "
      >
        <inside-warning-notification
          [title]="'Projects.warningChangeTitle' | translate"
          [subtitle]="'Projects.warningChangeSubtitle' | translate"
          [actionButtonText]="'Projects.warningChangeButton' | translate"
          (onAction)="shareChange()"
        ></inside-warning-notification>
      </ng-container>

      <!-- #region Change summary -->
      <ng-container *ngIf="service.isConfigChange && service.isPreview">
        <task-changes-summary></task-changes-summary>

        <div class="mt-3" *ngIf="service.task?.canAccept && service.task?.isTaskShared">
          <task-sidenav-change-acceptance></task-sidenav-change-acceptance>
        </div>
        <hr />
      </ng-container>
      <!-- #endregion -->

      <!-- #region Attachments -->
      <div class="mt-3 pb-4" *ngIf="!(service.task?.isDeleted && !service.task?.attachments?.length)">
        <attachment-manager-list
          (onAttachmentsChange)="onAttachmentsChange($event)"
          [disabled]="service.task?.isDeleted || !(service.canEditTask || service.canAddTask)"
          [uploadConfig]="
            this.service?.task?.id && (this.service.isPreview || this.service.isEdit)
              ? { id: this.service?.task?.id, objectType: 'task' }
              : null
          "
          (filesUploaded)="filesUploaded($event)"
          #attachmentsList
          [attachmentsConfig]="attachmentsConfig"
          [fileAttachments]="service.task?.originalAndChangeAttachments || []"
        ></attachment-manager-list>
      </div>
      <!-- #endregion -->

      <!-- #region Attachments -->
      <div
        class="mt-1 pb-4"
        *ngIf="
          service.isPreview &&
          service.isConfigChange &&
          (service.task?.isChangeStatusAccepted || service.task?.isChangeStatusRejected)
        "
      >
        <hr class="mt-0" />
        <div class="accept-history">
          <div class="accept-history__title">
            {{ 'Projects.acceptHistory' | translate }}
          </div>
          <div class="accept-history__info">
            <span
              class="mr-2 accept-history__info-blue"
              *ngIf="service?.task?.acceptedEmail || service?.task?.acceptedContact"
            >
              {{
                service?.task?.acceptedContact
                  ? service?.task?.acceptedContact?.fullName
                  : service?.task?.acceptedEmail
              }}
            </span>
            <span *ngIf="service?.task?.acceptanceDate" class="mr-2">{{
              service?.task?.acceptanceDate | dateTimeFormat
            }}</span>
            <status-cell
              [status]="'Tasks.ChangeStatus.' + service.task?.changeStatus | translate"
              [color]="
                !service.task?.isChangeStatusAccepted ? EStatusColor.COLOR_DANGER : EStatusColor.COLOR_SUCCESS
              "
            ></status-cell>
          </div>
          <div class="accept-history__description">
            {{ service.task.acceptanceComment }}
          </div>
        </div>
      </div>
      <!-- #endregion -->
    </div>
    <div class="task-sidenav-white-content-appendable"></div>

    <!-- #region Commentary Section -->
    <ng-container *ngIf="service.isPreview">
      <hr />
      <commentary-manage
        #comments
        [manualSort]="true"
        [append]="true"
        [appendElement]="'.task-sidenav-white-content'"
        [viewType]="CommentaryViewType.COMMENTS_FIRST"
        [id]="service.task?.id"
        [type]="CommentaryType.TASK"
        [commentaries]="service.task?.comments"
        (commentaryEvent)="updateCommentaries($event)"
      ></commentary-manage>
    </ng-container>
    <!-- #endregion -->
  </div>
</div>
<!-- #region Changes -->
<ng-template #stuffsHeadTemplateChanges>
  <form
    [formGroup]="service.form"
    class="w-100 w-sm-auto d-flex mt-3 mt-md-0 align-items-start flex-wrap justify-content-end"
  >
    <div class="d-flex align-items-start flex-wrap justify-content-end mb-2">
      <app-button
        *ngIf="canShare"
        [class]="'mr-2 mb-1 mb-md-0'"
        (onClick)="shareChange()"
        [config]="{
          size: ButtonSize.SMALL,
          type: ButtonTypes.SECONDARY_BLUE,
          text: 'Projects.share' | translate,
          prependSrc: './assets/img/ic-share.svg'
        }"
      ></app-button>

      <app-button
        *ngIf="canAddTask"
        (onClick)="createTask()"
        [class]="'mr-2 mb-1 mb-md-0 font-400'"
        [config]="{
          size: ButtonSize.SMALL,
          type: ButtonTypes.PRIMARY_BLUE,
          text: 'Projects.addTask' | translate,
          prependSrc: './assets/img/ic-check-square.svg'
        }"
      ></app-button>
    </div>

    <div class="d-flex align-items-center">
      <!-- [formControlName]="service.TASK_SIDENAV_FORM.changeStatus" -->
      <ng-select
        [formControl]="service.changeStatusHelper"
        [bindLabel]="'label'"
        [bindValue]="'id'"
        [searchable]="false"
        [clearable]="false"
        (change)="changeStatusEvent($event)"
        [items]="ChangeStatusList"
        [readonly]="service.task?.isDeleted || !(service.permissionCtrl.edit || service.permissionCtrl.add)"
        class="select-colored select-colored-canceled"
      >
      </ng-select>

      <button
        *ngIf="
          (service.isPreview || service.isEdit) &&
          !service.task?.isDeleted &&
          (this.canOpenTaskEdit || this.canRemove || this.canAddTask || this.canShare)
        "
        class="btn-icon p-0 d-inline-flex pointer ml-2"
        [matMenuTriggerFor]="menu"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu" xPosition="before">
        <button *ngIf="canOpenTaskEdit" mat-menu-item (click)="openTaskEdit()">
          <span>{{ 'Projects.edit' | translate }}</span>
        </button>

        <button *ngIf="canShare" mat-menu-item (click)="shareChange()">
          <span>{{ 'Projects.share' | translate }}</span>
        </button>

        <button *ngIf="canAddTask" mat-menu-item (click)="createTask()">
          <span>{{ 'Projects.addTask' | translate }}</span>
        </button>

        <button
          *ngIf="canRemove"
          class="hover_red red-500"
          (click)="deleteTask()"
          mat-menu-item
        >
          <span>{{ 'Projects.remove' | translate }}</span>
        </button>
      </mat-menu>
    </div>
  </form>
</ng-template>
<!-- #endregion -->

<ng-template #stuffsHeadTemplateTasks>
  <form
    [formGroup]="service.form"
    class="w-100 w-sm-auto d-flex mt-3 mt-md-0 align-items-start flex-wrap justify-content-end"
  >
    <div class="d-flex align-items-center">
      <ng-select
        [formControlName]="service.TASK_SIDENAV_FORM.status"
        [bindLabel]="'label'"
        [bindValue]="'id'"
        [searchable]="false"
        [clearable]="false"
        [items]="TaskStatusList"
        [readonly]="service.task?.isDeleted || !(service.canEditTask || service.canAddTask)"
        class="select-colored select-colored-{{ service?.form?.get('status')?.value }}"
      ></ng-select>

      <button
        *ngIf="
          (service.isPreview || service.isEdit) && !service.task?.isDeleted && (canEditTask || canRemove)
        "
        class="btn-icon p-0 d-inline-flex pointer ml-2"
        [matMenuTriggerFor]="menu"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu" xPosition="before">
        <button *ngIf="canEditTask" mat-menu-item (click)="openTaskEdit()">
          <span>{{ 'Projects.edit' | translate }}</span>
        </button>
        <button *ngIf="canRemove" class="hover_red red-500" (click)="deleteTask()" mat-menu-item>
          <span>{{ 'Projects.remove' | translate }}</span>
        </button>
      </mat-menu>
    </div>
  </form>
</ng-template>
