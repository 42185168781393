<div>
  <span
    #menuTrigger="matMenuTrigger"
    mat-icon-button
    [matMenuTriggerFor]="userMenu"
    tourAnchor="protocolsList.s1.settings"
    class="d-flex align-items-center header-action-menu-user-icon-wrapper"
  >
    <avatar [mobileConfig]="{ sm: 36 }" [width]="44" [imageUrl]="imageUrl" [title]="userPerson?.previewName"></avatar>
    <img draggable="false" src="/assets/img/arrow-down-solid.svg" alt="Arrow" class="d-none d-md-block ml-2" />
  </span>
  <mat-menu #userMenu="matMenu" xPosition="after">
    <button mat-menu-item (click)="n.go('user-settings')">
      <span>{{ 'settings' | translate }}</span>
    </button>
    <button mat-menu-item (click)="n.go('faq')">
      <span>{{ 'Header.faq' | translate }}</span>
    </button>
    <button mat-menu-item (click)="logout()" class="hover_red red-500">
      <span>{{ 'logout' | translate }}</span>
    </button>
  </mat-menu>
</div>
