import { Component, OnInit } from '@angular/core';
import { BaseListFiltersComponentComponent } from '@shared/components/base-list-filters-component/base-list-filters-component.component';
import { ListService } from '@shared/modules/list/services/list.service';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '@shared/configs/config';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'project-team-filters',
  templateUrl: './project-team-filters.component.html',
  styles: []
})
export class ProjectTeamFiltersComponent extends BaseListFiltersComponentComponent implements OnInit {
  Config = Config;

  constructor(
    public service: ListService,
    public t: TranslateService
  ) {
    super(service);
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.service.filtersForm = new FormGroup({
      '[function-name][eq]': new FormControl(null),
    });
  }
}
