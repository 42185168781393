import { Component, OnInit } from '@angular/core';
import { ListService } from '@shared/modules/list/services/list.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Config } from '@shared/configs/config';
import { TranslateService } from '@ngx-translate/core';
import { BaseListFiltersComponentComponent } from '@shared/components/base-list-filters-component/base-list-filters-component.component';
import { ContactType } from '@modules/contacts/shared/enums/contact-type.enum';
import { Project } from '@modules/projects/shared/models/project.model';
import { Employee } from '@shared/models/employee.model';

@Component({
  selector: 'contacts-filters',
  templateUrl: './contacts-filters.component.html',
  styles: []
})
export class ContactsFiltersComponent extends BaseListFiltersComponentComponent implements OnInit {
  Config = Config;

  contactTypes;

  constructor(
    public service: ListService,
    public t: TranslateService
  ) {
    super(service);
  }

  ngOnInit() {
    this.createForm();
    this.setContactTypes();
  }

  setContactTypes()
  {
    this.contactTypes = Object.values(ContactType).map((type) => {
      return {
        id: type,
        name: this.t.instant('Contacts.Type.' + type)
      };
    });
  }

  createForm() {
    this.service.filtersForm = new FormGroup({
      '[a-type][eq]': new FormControl(null),
      '[function-name][eq]': new FormControl(null),
      '[assignedProjects][]': new FormControl(null),
      '[creator-id][eq]': new FormControl(null),
      '[deleted]': new FormControl(null),
    });
  }

  projectTransform(project: Project) {
    return project.name;
  }

  creatorName(employee: Employee) {
    const e = new Employee(employee);
    return e.userPerson.previewName;
  }

}
