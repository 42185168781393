<div class="d-flex">
  <div class="col-6">
    <app-button
      class="bold w-100"
      (onClick)="service.discardSaving()"
      [config]="{
        size: ButtonSize.LARGE,
        type: ButtonTypes.DISCARD_BUTTON,
        text: 'Projects.discard' | translate
      }"
    ></app-button>
  </div>

  <div class="col-6">
    <app-button
      class="bold w-100"
      [disabled]="service.loading"
      [loading]="service.loading"
      (onClick)="submitForm()"
      [config]="{
        size: ButtonSize.LARGE,
        type: ButtonTypes.PRIMARY_BLUE,
        text: 'Projects.saveAndEnd' | translate,
        prependSrc: './assets/img/ic-save.svg'
      }"
    ></app-button>
  </div>
</div>
