<top-bar-progress-bar></top-bar-progress-bar>
<sidenav></sidenav>
<intilio-header *ngIf="!hideHeader && !isSignUpHeader"></intilio-header>
<sign-up-header *ngIf="isSignUpHeader"></sign-up-header>
<div
  class="base-panel"
  (scroll)="onScroll()"
  [ngClass]="{
    'base-panel--hidden-header': hideHeader,
    'base-panel--mobile': DeviceHelper.isMobileDevice,
    'base-panel--sticky-footer': isFooterSticky,
    'base-panel--ipad': DeviceHelper.isIPad
  }"
  #basePanel
>
  <custom-tour-step></custom-tour-step>

  <!-- <download-app></download-app> -->
  <router-outlet></router-outlet>
  <intilio-footer *ngIf="!hideFooter"></intilio-footer>
</div>
