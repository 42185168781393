<!-- #region  EDIT-->
<button mat-menu-item *ngIf="!row?.expanded && row?.accessToEdit" (click)="openEdit()">
  <span>{{ 'Protocols.List.edit' | translate }}</span>
</button>
<!-- #endregion -->

<!-- #region PREVIEW -->
<button *ngIf="row?.accessToPreview" mat-menu-item (click)="preview()">
  <span>{{ 'Protocols.List.preview' | translate }}</span>
</button>

<button mat-menu-item (click)="previewPDF()">
  <span>{{ 'Protocols.List.previewPDF' | translate }}</span>
</button>

<button mat-menu-item *ngIf="!row?.expanded && row?.accessToSignature" (click)="signProtocol()">
  <span>{{ 'Protocols.signProtocol' | translate }}</span>
</button>
<!-- #endregion -->

<!-- #region STUFFS -->
<button mat-menu-item (click)="openSidenav()" *ngIf="!row?.expanded && !employee.isClient">
  <span>{{ 'Protocols.List.stuffs' | translate }}</span>
</button>
<!-- #endregion -->

<!-- #region DOWNLOAD FOR VERSIONS-->
<button mat-menu-item *ngIf="row?.expanded" (click)="downloadVersion()" j>
  <span>{{ 'Protocols.List.download' | translate }}</span>
</button>
<!-- #endregion -->

<!-- #region DOWNLOAD -->
<div *ngIf="!employee.isClient && !row?.expanded">
  <button
    mat-menu-item
    #downloadMenu="matMenuTrigger"
    (click)="$event.stopPropagation()"
    [matMenuTriggerFor]="protocolDownloadMenu.previewPDF"
    *ngIf="protocolDownloadMenu && !!protocolDownloadMenu?.previewPDF"
  >
    <span>{{ 'Protocols.List.download' | translate }}</span>
  </button>

  <protocol-download-menu
    #protocolDownloadMenu
    [protocol]="row?.protocol"
    [expanded]="row?.expanded"
    [isOwnerNotFilledFullData]="isOwnerNotFilledFullData"
  ></protocol-download-menu>
</div>

<button mat-menu-item *ngIf="employee.isClient" (click)="download()">
  <span>{{ 'Protocols.List.download' | translate }}</span>
</button>
<!-- #endregion -->

<!-- #region ACCEPTANCE -->
<div
  *ngIf="row?.protocol.status === ProtocolsStatuses.STATUS_SENT && !row?.expanded && row?.accessToAcceptance"
>
  <button mat-menu-item (click)="accept()" class="hover_green green-500">
    <span>{{ 'Protocols.List.accept' | translate }}</span>
  </button>
  <button mat-menu-item (click)="decline()" class="hover_red red-500">
    <span>{{ 'Protocols.List.decline' | translate }}</span>
  </button>
</div>
<!-- #endregion -->

<!-- #region SEND -->
<button
  mat-menu-item
  (click)="send()"
  *ngIf="row?.protocol.status !== ProtocolsStatuses.STATUS_DRAFT && !row?.expanded && row?.accessToRemove"
>
  <span>{{ 'Protocols.List.send' | translate }}</span>
</button>
<!-- #endregion -->

<!-- #region  -->
<button
  *ngIf="!row?.protocol.deleted && !row?.expanded && row?.accessToRemove"
  mat-menu-item
  (click)="remove()"
  class="hover_red red-500"
>
  <span>{{ 'Protocols.List.remove' | translate }}</span>
</button>
<!-- #endregion -->
