import { Component, OnInit, Inject } from '@angular/core';
import { Config } from '@shared/configs/config';
import { SIDENAV_DATA } from '@shared/services/sidenav/sidenav.data';
import { EventSidenavService } from '../../services/event-sidenav.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'event-sidenav',
  templateUrl: './event-sidenav.component.html',
  styleUrls: ['./event-sidenav.component.scss'],
})
export class EventSidenavComponent implements OnInit {
  static componentName: string = 'EventSidenavComponent';

  constructor(
    @Inject(SIDENAV_DATA) public sideData: SIDENAV_DATA,
    public service: EventSidenavService,
    private active: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this._setEventConfig();
  }

  private _setEventConfig() {
    this.service.setInitialData(this.sideData?.data);
  }
}
