<div
  class="d-flex align-content-start align-md-content-center flex-column flex-md-row flex-wrap"
>
  <list-single-filter-range-date
    #dateRanePickerFilter
    class="w-100 w-sm-auto"
    [name]="'Protocols.List.dateRange' | translate"
    [iconUrl]="'/assets/img/ic-calendar.svg'"
    [form]="service?.filtersForm"
    [fromControlName]="'[a-created][gte]'"
    [toControlName]="'[a-created][lte]'"
    (change)="onChangeFilter()">
  </list-single-filter-range-date>

  <list-single-filter-select
    class="w-100 w-sm-auto"
    *ngIf="!employee.isClient"
    [name]="'Protocols.List.creator' | translate"
    [iconUrl]="'/assets/img/ic-person-circle-outline.svg'"
    [form]="service?.filtersForm"
    [controlName]="'[creator-id][eq]'"
    (change)="onChangeFilter()"
    [requiredIdUrl]="Config.API + '/employee/search'"
    [valueTransform]="creatorName.bind(this)"
  ></list-single-filter-select>

  <list-single-filter-select
    class="w-100 w-sm-auto"
    *ngIf="!employee.isClient && (service?.hideFilters?.indexOf('[project-id][eq]') === -1)"
    [name]="'Protocols.List.projectFilter' | translate"
    [iconUrl]="'/assets/img/ic-projects-outline.svg'"
    [form]="service?.filtersForm"
    [controlName]="'[project-id][eq]'"
    (change)="onChangeFilter()"
    [requiredIdUrl]="Config.API + '/project/search'"
    [valueTransform]="projectName.bind(this)"
  ></list-single-filter-select>

  <list-single-filter-select
    class="w-100 w-sm-auto"
    *ngIf="!employee.isClient"
    [name]="'Protocols.List.clientFilter' | translate"
    [iconUrl]="'/assets/img/ic-person-circle-outline.svg'"
    [form]="service?.filtersForm"
    [controlName]="'[mainContact-id][eq]'"
    (change)="onChangeFilter()"
    [requiredIdUrl]="Config.API + '/contact/search'"
    [valueTransform]="mainContact.bind(this)"
  ></list-single-filter-select>

  <list-single-filter-select
    class="w-100 w-sm-auto"
    [name]="'Protocols.List.statusFilter' | translate"
    [iconUrl]="'/assets/img/ic-checkmark-circle-outline.svg'"
    [form]="service?.filtersForm"
    [bindValue]="'id'"
    [bindLabel]="'name'"
    [controlName]="'[a-status][eq]'"
    (change)="onChangeFilter()"
    [localItems]="statuses"
    *ngIf="!isDraftTab"
  ></list-single-filter-select>

  <list-single-filter-checkbox
    [name]="'Protocols.List.showRemoved' | translate"
    [form]="service?.filtersForm"
    [controlName]="'[deleted]'"
    (change)="onChangeFilter()"
  ></list-single-filter-checkbox>

  <list-single-clear-filters
    [form]="service?.filtersForm"
    (change)="onChangeFilter()"
    [shouldShowClearFilters]="shouldShowClearFilters.bind(this)"
    [clearFilters]="clearFilters.bind(this)"
  >
  </list-single-clear-filters>
</div>
