<side-navigation-view
  [updateLocationParam]="true"
  [config]="configSideNavigation"
  (onTabClick)="onTabClick($event)"
  #sideNavigationViewComponent
>
  <main class="bg-white projects-list">
    <action-title-header
      [title]="'ProjectsList.title' | translate"
      [buttonText]="'ProjectsList.addProject' | translate"
      [loading]="loadingAddProject"
      (onAction)="addProject()"
      [doubleHeightOnMobile]="true"
      [stickyWithSideNavigation]="true"
      [permissionDecorator]="{ group: PermissionsGroups.PROJECTS, action: 'ADD' }"
    >
      <div class="projects-list__tabs">
        <div class="projects-list__tabs__title">
          {{ 'ProjectsList.tabsChangeView' | translate }}
        </div>
        <button-group [config]="buttonGroupConfig" class="w-100 w-sm-auto"></button-group>
      </div>
    </action-title-header>

    <div class="container-fluid--30 projects-list__content">
      <ng-select
        #selectStage
        ngSelectExtension
        class="select select-default w-100 projects-list__select-tabs select-with-title"
        [dropdownPosition]="'bottom'"
        [items]="stages"
        [clearable]="false"
        [searchable]="false"
        [bindLabel]="'text'"
        [bindValue]="'id'"
        [placeholder]="'ProjectsList.stagesTitle' | translate"
        (change)="onTabClick($event, true)"
        [tabIndex]="-1"
      >
      </ng-select>

      <app-list
        *ngIf="inited"
        [config]="config"
        [emptyTemplate]="emptyTemplate"
        [filters]="ProjectsFiltersComponent"
        [searchName]="'ProjectsList.searchProject' | translate"
        [selected]="selected"
        (activate)="activate($event)"
        [showToggleMenu]="showToggleMenu.bind(this)"
        #list
      >
      </app-list>
    </div>
  </main>
</side-navigation-view>

<ng-template #emptyTemplate>
  <div class="d-flex flex-column align-items-center mt-4 empty">
    <img draggable="false" src="/assets/img/Project.svg" alt="{{ 'ProjectsList.noProjects' | translate }}" class="mb-4 mt-4" />
    <div class="mt-2">{{ 'ProjectsList.noProjects' | translate }}</div>
    <app-button
      [permission]="{
        group: PermissionsGroups.PROJECTS,
        action: 'ADD'
      }"
      (onClick)="addProject()"
      [config]="{
        type: ButtonTypes.SECONDARY_BLUE,
        text: 'ProjectsList.addProject' | translate,
        size: ButtonSize.LARGE,
        prependSrc: './assets/img/ic-plus-add.svg'
      }"
    >
    </app-button>
  </div>
</ng-template>

<project-property-type-cell #projectPropertyTypeCell></project-property-type-cell>
<project-client-cell #projectClientCell></project-client-cell>
<project-name-cell #projectNameCell></project-name-cell>
<project-stage-cell #projectStageCellComponent></project-stage-cell>
<project-responsible-client-cell #projectResponsibleClientCell></project-responsible-client-cell>
<project-responsible-company-cell #projectResponsibleCompanyCell></project-responsible-company-cell>
<project-name-header-cell #projectNameHeaderCell></project-name-header-cell>
<project-lose-cell #projectLoseCell></project-lose-cell>
<project-address-cell #projectAddressCell></project-address-cell>
