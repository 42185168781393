import { EventCycle } from '@shared/modules/event-sidenav/enums/event-cycle.enum';
import { ECalendarTasksForm } from './../enums/calendar-tasks-form.enum';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { catchError, tap } from 'rxjs/operators';
import { Injector } from '@angular/core';
import { Calendar, FullCalendarComponent } from '@fullcalendar/angular';
import { Config } from '@shared/configs/config';
import { AppInjector } from '@shared/services/app-injector.service';
import * as moment from 'moment';
import { CalendarApiService } from '../services/calendar-api.service';
import { CalendarStorageService } from '../services/calendar-storage.service';
import { CalendarView } from '../models/calendar-view.model';
import { CalendarEvent } from '@shared/modules/event-sidenav/models/calendar-event.model';
import { Task } from '@shared/models/task.model';
import { truncateContent } from '@shared/helpers/truncate.helper';
import { of } from 'rxjs';
import { SnackBarService } from '@core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';

export class CallendarMainController {
  injector: Injector;
  apiService: CalendarApiService;
  calendarStorage: CalendarStorageService;
  fb: FormBuilder;
  s: SnackBarService;
  t: TranslateService;

  view: CalendarView;
  form: FormGroup;

  fullCalendar: FullCalendarComponent;

  get initialValues() {
    return {
      [ECalendarTasksForm.delegatedTasks]: true,
      [ECalendarTasksForm.myTasks]: true,
      [ECalendarTasksForm.otherTasks]: true,
    };
  }

  get calendarAPI(): Calendar {
    return this.fullCalendar?.getApi();
  }

  get optionsChanged() {
    return this.form.valueChanges;
  }

  get viewFields() {
    const ids = this.calendarStorage?.selectedCalendars
      .concat(this.calendarStorage?.selectedEmployeeCalendars)
      .map((i) => Number(i));

    return {
      'calendarsIds[]': [...new Set(ids)],
      startDate: moment(this.calendarAPI?.view?.activeStart).format(Config.DATE_SERVER),
      endDate: moment(this.calendarAPI?.view?.activeEnd).format(Config.DATE_SERVER),
      delegatedTasks: !!this.form.get(ECalendarTasksForm.delegatedTasks).value ? 1 : 0,
      myTasks: !!this.form.get(ECalendarTasksForm.myTasks).value ? 1 : 0,
      otherTasks: !!this.form.get(ECalendarTasksForm.otherTasks).value ? 1 : 0,
    };
  }

  get fullCalendarView() {
    return this.apiService.getCalendarView(this.viewFields).pipe(
      tap((view: CalendarView) => {
        this.resetCalendarEvents();
        this.setCalendarView(view);
      }),
      catchError((e) => {
        this.s.error(this.t.instant('Calendar.Settings.selectedCalendarError'));
        return of(e);
      }),
    );
  }

  constructor() {
    this.initializeInjectors();
    this.createForm();
  }

  private initializeInjectors() {
    this.injector = AppInjector.getInjector();
    this.fb = this.injector.get(FormBuilder);
    this.apiService = this.injector.get(CalendarApiService);
    this.calendarStorage = this.injector.get(CalendarStorageService);
    this.s = this.injector.get(SnackBarService);
    this.t = this.injector.get(TranslateService);
  }

  private createForm() {
    this.form = this.fb.group(this.initialValues);
  }

  initializeDefaultViewState() {
    this.form.setValue(this.initialValues);
  }

  registerFullCalendar(fullCalendar: FullCalendarComponent) {
    this.fullCalendar = fullCalendar;
  }

  // #region Events

  resetCalendarEvents() {
    this.calendarAPI?.removeAllEvents();
  }

  getCalendarEvents() {
    this.fullCalendarView.subscribe();
  }

  setCalendarView(view: CalendarView) {
    this.view = view;
    this.view.calendars.forEach((i, index) => this.addCalendarSingle(this.view.calendars[index]));
  }

  addCalendarSingle(row) {
    for (let i = 0; i < row?.events?.length; i++) {
      this.addSingleEvent(row.calendar, row?.events[i]);
    }

    for (let i = 0; i < row?.tasks?.length; i++) {
      this.addSingleTask(row.calendar, row?.tasks[i]);
    }
  }

  addSingleEvent(calendar, event: CalendarEvent) {
    const isExternal = calendar?.type === 'url';
    const allDay = this.isEventAllDay(event);

    const isAllDay = !!event?.isFullDay || allDay;
    const termStart = moment(event.termStart, Config.DATE_SERVER);
    const termEnd = moment(event.termEnd, Config.DATE_SERVER);

    let config = {
      id: event?.id,
      allDay: isAllDay,
      title: truncateContent(event.name),
      start: termStart.toDate(),
      end: isAllDay ? termEnd.add(1, 'day').startOf('day').toDate() : termEnd.toDate(),
      color: calendar?.calendarColor,
      calendarEvent: Object.assign(event, { isExternal }),
      editable: true,
      classNames: ['fc-event--calendar-event'],
      isExternal,
      duration: { days: 10 },
    } as any;

    if (event.cycleType) {
      let start = termStart.clone();
      let end = termEnd.clone();

      if (!!event?.isFullDay || allDay) {
        start = start.startOf('day');
        end = end.endOf('day');
      }

      config['duration'] = { milliseconds: end.valueOf() - start.valueOf() };
      config.rrule = this.getCycle(event);
    }

    const eventExists = !!this.calendarAPI.getEventById(event?.id?.toString());
    !eventExists ? this.calendarAPI?.addEvent(config) : null;
  }

  addSingleTask(calendar, task: Task) {
    const allDay = this.isEventAllDay(task);
    const ownTask = !!task.assignedTo.filter((c) => c?.defaultCalendar?.id === calendar?.id)?.length;
    let config = {
      allDay,
      id: task?.id,
      title: truncateContent(task.description),
      start: moment(task.termStart, Config.DATE_SERVER).toDate(),
      end: !!allDay
        ? moment(task.termEnd, Config.DATE_SERVER).add(1, 'day').startOf('day').toDate()
        : moment(task.termEnd, Config.DATE_SERVER).toDate(),
      task: task,
      color: calendar?.calendarColor, //ownTask ? calendar?.calendarColor : '#2A2A2A' //TODO code single task if it exists on another
      editable: true,
      classNames: ['fc-event--calendar-task'],
    } as any;
    const eventExists = !!this.calendarAPI.getEventById(task?.id?.toString());
    !eventExists ? this.calendarAPI.addEvent(config) : null;
  }

  isEventAllDay(item: any): boolean {
    if (!(item.termStart && item.termEnd)) return false;
    const cloneStart = moment(moment(item.termStart, Config.DATE_SERVER).clone());
    const start = moment(cloneStart).startOf('day').unix();
    const _start = moment(cloneStart).unix();
    const startSame = start === _start;

    const cloneEnd = moment(moment(item.termEnd, Config.DATE_SERVER).clone());
    const end = moment(cloneEnd).endOf('day').unix();
    const _end = moment(cloneEnd).unix();
    const endSame = end === _end;
    return startSame && endSame;
  }

  getCycle(event: CalendarEvent) {
    const dtstart = moment(event.termStart, Config.DATE_SERVER).format(Config.DATE_SERVER);
    const until = moment(event.termEnd, Config.DATE_SERVER).format(Config.DATE_SERVER);
    switch (event.cycleType) {
      case EventCycle.CYCLE_EVERY_DAY:
        return {
          freq: 'daily',
          dtstart,
        };
      case EventCycle.CYCLE_EVERY_MONTH:
        return {
          freq: 'monthly',
          dtstart,
        };
      case EventCycle.CYCLE_EVERY_WEEK:
        return {
          freq: 'weekly',
          dtstart,
        };
      case EventCycle.CYCLE_EVERY_YEAR:
        return {
          freq: 'yearly',
          dtstart,
        };
      case EventCycle.CYCLE_EVERY_SECOND_WEEK:
        return {
          freq: 'weekly',
          dtstart,
          interval: 2,
        };
      case EventCycle.CYCLE_EVERY_THIRD_MONTH:
        return {
          freq: 'monthly',
          dtstart,
          interval: 3,
        };
      default:
        return {};
    }
  }
  //#endregion

  goToDate(date: any) {
    this.calendarAPI.gotoDate(date);
  }
}
