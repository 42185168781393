<div class="d-flex align-content-start align-md-content-center flex-column flex-md-row flex-wrap">

  <list-single-filter-select
    class="w-100 w-sm-auto"
    [name]="'Contacts.List.function' | translate"
    [iconUrl]="'/assets/img/ic-tag.svg'"
    [form]="service?.filtersForm"
    [controlName]="'[function-name][eq]'"
    [bindLabel]="'name'"
    [bindValue]="'name'"
    [multiple]="false"
    [translateLabelPrefix]="'Contacts.Function.'"
    (change)="onChangeFilter()"
    [requiredIdUrl]="Config.API + '/tag/type?type=contact-function'"
  ></list-single-filter-select>

  <list-single-filter-select
    class="w-100 w-sm-auto"
    [name]="'Contacts.Fields.contactType' | translate"
    [iconUrl]="'/assets/img/ic-user.svg'"
    [form]="service?.filtersForm"
    [controlName]="'[a-type][eq]'"
    [bindValue]="'id'"
    [bindLabel]="'name'"
    [multiple]="false"
    (change)="onChangeFilter()"
    [localItems]="contactTypes"
  ></list-single-filter-select>

  <list-single-filter-select
    class="w-100 w-sm-auto"
    [name]="'Contacts.List.project' | translate"
    [iconUrl]="'/assets/img/ic-projects-outline.svg'"
    [form]="service?.filtersForm"
    [controlName]="'[assignedProjects][]'"
    [multiple]="true"
    [valueTransform]="projectTransform.bind(this)"
    (change)="onChangeFilter()"
    [requiredIdUrl]="Config.API + '/project/search'"
  ></list-single-filter-select>


  <list-single-filter-select
    class="w-100 w-sm-auto"
    [name]="'Contacts.List.creator' | translate"
    [iconUrl]="'/assets/img/ic-person-circle-outline.svg'"
    [form]="service?.filtersForm"
    [controlName]="'[creator-id][eq]'"
    (change)="onChangeFilter()"
    [multiple]="false"
    [searchGetParams]="'search'"
    [requiredIdUrl]="Config.API + '/employee/search'"
    [valueTransform]="creatorName.bind(this)"
  ></list-single-filter-select>


  <list-single-filter-checkbox
    [name]="'Contacts.List.showRemoved' | translate"
    [form]="service?.filtersForm"
    [controlName]="'[deleted]'"
    (change)="onChangeFilter()"
  ></list-single-filter-checkbox>

  <list-single-clear-filters
    [form]="service?.filtersForm"
    (change)="onChangeFilter()"
    [shouldShowClearFilters]="shouldShowClearFilters.bind(this)"
    [clearFilters]="clearFilters.bind(this)"
  >
  </list-single-clear-filters>
</div>
