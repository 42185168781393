<div class="ui-project__header">
  <h3 class="mb-0">{{ 'ProjectTeam.title' | translate }}</h3>

  <app-button
    [permission]="{
      group: PermissionsGroups.PROJECT_TEAM,
      action: 'ADD',
      objectCreatorId: [projectPreviewService?.project?.basicDataBox?.responsibleEmployee?.id]
    }"
    (onClick)="addNewTeamMember()"
    [config]="{
      size: ButtonSize.SMALL,
      type: ButtonTypes.PRIMARY_GREEN,
      text: WindowHelper.isMobileWidth ? '' : ('ProjectTeam.addNewTeamMember' | translate),
      prependSrc: './assets/img/ic-user-plus.svg'
    }"
  ></app-button>
</div>

<div class="container-fluid--30 pt-3">
  <app-list
    *ngIf="inited"
    [config]="config"
    [emptyTemplate]="emptyTemplate"
    [filters]="ProjectTeamFiltersComponent"
    [searchName]="'ProjectTeam.List.search' | translate"
    [selected]="selected"
    [showToggleMenu]="showToggleMenu.bind(this)"
    #list
  >
  </app-list>
</div>

<ng-template #emptyTemplate>
  <div class="d-flex flex-column align-items-center mt-4 empty">
    <img
      draggable="false"
      src="/assets/img/team-placeholder-icon.svg"
      alt="{{ 'ProjectChanges.List.noRecords' | translate }}"
      class="mb-4 mt-4"
    />
    <div class="mt-2">{{ 'ProjectTeam.List.noRecords' | translate }}</div>
    <app-button
      [permission]="{
        group: PermissionsGroups.PROJECT_TEAM,
        action: 'ADD',
        objectCreatorId: [projectPreviewService?.project?.basicDataBox?.responsibleEmployee?.id]
      }"
      (onClick)="addNewTeamMember()"
      [config]="{
        size: ButtonSize.SMALL,
        type: ButtonTypes.SECONDARY_BLUE,
        text: 'ProjectTeam.addNewTeamMember' | translate,
        prependSrc: './assets/img/ic-user-plus.svg'
      }"
    ></app-button>
  </div>
</ng-template>

<project-team-employee-cell #projectTeamEmployeeCellComponent></project-team-employee-cell>
<project-team-function-cell #projectTeamFunctionCellComponent></project-team-function-cell>
<project-team-phone-cell #projectTeamPhoneCellComponent></project-team-phone-cell>
<project-team-hourly-rate-cell #projectTeamHourlyRateCellComponent></project-team-hourly-rate-cell>
<project-team-role-cell #projectTeamRoleCellComponent></project-team-role-cell>
<project-team-role-project-cell #projectTeamRoleProjectCellComponent></project-team-role-project-cell>
<project-team-email-cell #projectTeamEmailCellComponent></project-team-email-cell>
