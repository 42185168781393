import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TabLink } from '@shared/interfaces/menu/tab-link.interface';
import { NavigateService } from '@shared/services/navigate.service';

@Component({
  selector: 'faq',
  templateUrl: './faq.component.html'
})
export class FaqComponent implements OnInit {

  tabLinks: TabLink[] = [];

  constructor(private t: TranslateService, private n: NavigateService) { }

  ngOnInit(): void {
    this.initTabLinks();
  }

  initTabLinks() {
    this.tabLinks = [
      {
        name: this.t.instant('Help.Faq.Menu.global'),
        link: this.n.getPath('faq-global')
      },
      {
        name: this.t.instant('Help.Faq.Menu.account'),
        link: this.n.getPath('faq-account')
      },
      {
        name: this.t.instant('Help.Faq.Menu.protocols'),
        link: this.n.getPath('faq-protocols')
      },
    ]
  }

}
