export const extensionIcon = (fileType: string, file?: string) => {
  const type = fileType?.toString();
  const splited = file && typeof file === 'string' ? file?.split('.') : null;

  if (type.indexOf('pdf') > -1) {
    return 'ic-pdf.svg';
  } else if (
    type.indexOf('doc') > -1 ||
    type.indexOf('docx') > -1 ||
    (splited &&
      (splited[splited.length - 1].indexOf('doc') > -1 || splited[splited.length - 1].indexOf('docx') > -1))
  ) {
    return 'ic-default-doc.svg';
  } else {
    return 'ic-default-file.svg';
  }
};
