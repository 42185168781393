import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseListFiltersComponentComponent } from '@shared/components/base-list-filters-component/base-list-filters-component.component';
import { ListParams, ListService } from '@shared/modules/list/services/list.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Config } from '@shared/configs/config';
import { Employee } from '@shared/models/employee.model';
import { TaskStatus } from '@shared/enums/task-status.enum';
import { ListTaskEvent, ListTaskEventType, ListTaskService } from '@shared/modules/list/services/list-task.service';
import { Subscription } from 'rxjs';
import { ListSingleFilterSelectComponent } from '@shared/modules/list/components/list-filter/components/list-single-filter-select/list-single-filter-select.component';
import { StorageService } from '@core/services/storage.service';
import { TaskType } from '@shared/enums/task-type.enum';

@Component({
  selector: 'project-tasks-filters',
  templateUrl: './project-tasks-filters.component.html'
})
export class ProjectTasksFiltersComponent extends BaseListFiltersComponentComponent implements OnInit, OnDestroy {
  Config = Config;

  taskStatuses: any[] = [];
  taskTypes: any[] = [];
  sub: Subscription = new Subscription();
  assigneeLoading: boolean = false;

  @ViewChild('tagFilter') tagFilter: ListSingleFilterSelectComponent;
  @ViewChild('assigneeFilter') assigneeFilter: ListSingleFilterSelectComponent;

  constructor(public service: ListService, public listTaskService: ListTaskService, private storageService: StorageService) {
    super(service);
  }

  ngOnInit(): void {
    this.createForm();
    this.setTaskStatuses();
    this.setTaskTypes();
    this.listenListTaskEvents();
  }

  listenListTaskEvents() {
    this.sub.add(this.listTaskService.emitter.subscribe((event: ListTaskEvent) => {
      switch (event.type) {
        case ListTaskEventType.ADD_TASK:
        case ListTaskEventType.UPDATE_TASK:
        case ListTaskEventType.ASSIGNEE_CHANGE:
          this.reloadAssignee();
          break;
      }
    }));
  }

  reloadTags() {
    this.tagFilter.select.getItems();
  }

  reloadAssignee() {
    if (this.assigneeLoading) {
      return;
    }
    this.assigneeLoading = true;
    this.assigneeFilter.select.getItems();
    setTimeout(() => { //blocker for quick update and assignee action
      this.assigneeLoading = false;
    }, 100);
  }

  setTaskTypes() {
    this.taskTypes = Object.values(TaskType).filter(val => val !== TaskType.TYPE_COMPANY_TASK && val !== TaskType.TYPE_CLIENT_TASK).map((type) => {
      return {
        id: type,
        name: this.t.instant('Tasks.TaskType.' + type),
      };
    });
  }


  createForm() {
    this.service.filtersForm = new FormGroup({
      '[creator][eq]': new FormControl(null),
      '[assigned][in][]': new FormControl(null),
      '[a-type][eq]': new FormControl(null),
      '[a-status][in][]': new FormControl(null),
      '[tag-name][in][]': new FormControl(null),
      '[show_completed]': new FormControl(null),
    });
  }

  setTaskStatuses() {
    this.taskStatuses = Object.values(TaskStatus).map((status) => {
      return {
        id: status,
        name: this.t.instant('Projects.TaskStatus.' + status),
      };
    });
  }

  responseTransformAssignee(res) {
    const emails = res.emails || [];
    const employees = res.employees || [];
    return emails.map((e) => {
        return {email: e, id: e};
      }
    ).concat(employees);
  }

  onChangeFilter() {
    Object.keys(this.service.filtersForm.value).forEach((key: string) => {
      let value = this.service.filtersForm.value[key];
      Array.isArray(value) && !value.length ? value = null : null;
      this.service.setFilter(key, value);
    });

    const statusesFiltrValue = this.service.filtersForm.get('[a-status][in][]').value;
    if (statusesFiltrValue && statusesFiltrValue.indexOf(TaskStatus.COMPLETED) !== -1) {
      this.service.setFilter('[show_completed]', 1);
    }

    this.service.getRows();
  }

  shouldShowClearFilters() {
    let show = false;
    Object.keys(this.service.filtersForm.value).forEach((key) => {
      if (this.service?.filtersForm?.value[key]) {
        if (key !== '[show_completed]') {
          show = true;
        }
      }
    });
    return show;
  }


  creatorName(row: Employee | { id: string, userPerson }) {
    if (typeof row === 'object' && row.userPerson) {
      const e = new Employee(row);
      return e.userPerson.previewName;
    }
    return row.id; //show email if it's only assigned email not employee
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    super.ngOnDestroy();
  }
}
