<sidenav-title [title]="'CalendarEvents.detailsEvent' | translate"></sidenav-title>
<div class="event-details" *ngIf="service.calendarEvent">
  <ng-container>
    <div class="event-details__info">
      <!-- #region Project Address -->

      <span
        *ngIf="!!service.calendarEvent?.project?.fullName"
        (click)="goToProjectSummary()"
        class="pointer event-details__info-address"
      >
        {{ service.calendarEvent?.project?.fullName }}
      </span>

      <!-- #endregion -->

      <!-- #region Title -->
      <div class="mb-2">
        <span class="event-details__info-title">{{ service.calendarEvent?.name }}</span>

        <svg-icon
          *ngIf="!service.calendarEvent?.deleted || !service?.calendarEvent.isExternal"
          [permission]="{
            group: PermissionsGroups.CALENDAR,
            action: 'EVENT_EDIT',
            objectCreatorId: [service?.calendarEvent?.participantCreator?.contact?.employeeId]
          }"
          (click)="editOpen()"
          class="ml-3 pointer"
          src="./assets/img/ic-edit-3.svg"
          [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
        ></svg-icon>
      </div>
      <!-- #endregion -->

      <!-- #region Creator -->
      <div class="event-details__info-data">
        <span class="text-capitalize event-details__info-term">
          {{
            service.calendarEvent.termStart
              | rangeFormat: service.calendarEvent.termEnd:service.calendarEvent.isFullDay
          }}
        </span>
        <span *ngIf="!!service.creatorContact" class="mx-1 d-inline-flex">|</span>
        <span *ngIf="!!service.creatorContact">
          {{ 'CalendarEvents.creator' | translate }}
          <b>{{ service.creatorContact?.fullName }}</b>
        </span>
      </div>
      <!-- #endregion -->
    </div>
    <hr class="my-3" />
  </ng-container>

  <ng-container *ngIf="!!service.calendarEvent?.description">
    <div class="event-details__description">
      {{ service.calendarEvent?.description }}
    </div>
    <hr class="my-3" />
  </ng-container>

  <ng-container>
    <div class="event-details__data">
      <event-data></event-data>
    </div>
    <hr class="my-3" />
  </ng-container>

  <ng-container>
    <div class="event-details__participants">
      <event-participants-list></event-participants-list>
    </div>
    <hr class="my-3" />
  </ng-container>

  <ng-container *ngIf="!service?.calendarEvent?.isExternal">
    <div class="event-details__protocols">
      <event-protocols-list></event-protocols-list>
    </div>
  </ng-container>
</div>
