<main class="bg-white protocols-list">
  <div class="list-tabs fixed-header">
    <tabs
      #tabsComponent
      *ngIf="buttonsGroupConfig"
      [title]="'Protocols.protocols' | translate"
      [buttonName]="'Protocols.new' | translate"
      [buttonsGroupConfig]="buttonsGroupConfig"
      [permissionDecorator]="{ group: PermissionsGroups.PROTOCOLS, action: 'ADD' }"
      [actionButtonConfig]="
        !standalone
          ? null
          : {
              type: ButtonTypes.PRIMARY_GREEN,
              text: standalone && WindowHelper?.isMobileWidth ? '' : ('Protocols.new' | translate),
              size: ButtonSize.SMALL,
              prependSrc: './assets/img/ic-plus-add.svg'
            }
      "
      (onButtonClick)="addProtocol()"
    ></tabs>
  </div>
  <mat-drawer-container [hasBackdrop]="false">
    <mat-drawer-content>
      <div class="container-fluid--30 pt-3 pb-3">
        <app-list
          *ngIf="inited"
          [filtersTourAnchor]="'protocolsList.s4.filters'"
          [listTourAnchor]="'protocolsList.s5.list'"
          [treeActionTourAnchor]="'protocolsList.s6.version'"
          [toggleMenuTourAnchor]="'protocolsList.s9.toggleMenu'"
          [config]="config"
          [hideFilters]="projectId ? ['[project-id][eq]'] : []"
          [emptyTemplate]="emptyTemplate"
          [filters]="ProtocolsFiltersComponent"
          [searchName]="'Protocols.List.searchProtocol' | translate"
          [selected]="selected"
          (activate)="activate($event)"
          [showToggleMenu]="showToggleMenu.bind(this)"
          #list
        >
        </app-list>
      </div>
    </mat-drawer-content>
    <mat-drawer
      #sidenavPreview
      [mode]="'over'"
      class="sidenav-inside scroll"
      [autoFocus]="false"
      [position]="'end'"
    >
      <protocol-quick-preview [data]="selected[0]" [sidenav]="sidenavPreview"></protocol-quick-preview>
    </mat-drawer>
  </mat-drawer-container>
</main>
<!-- #endregion -->

<!-- #region Empty template for list-->
<ng-template #emptyTemplate>
  <div class="d-flex flex-column align-items-center mt-4 empty">
    <img
      draggable="false"
      src="/assets/img/protocol-empty-list.svg"
      alt="{{ 'Protocols.new' | translate }}"
      class="mb-4 mt-4"
    />
    <div class="mt-2">{{ 'Protocols.List.emptyList' | translate }}</div>
    <button
      [permission]="{ group: PermissionsGroups.PROTOCOLS, action: 'ADD' }"
      class="btn btn-empty_blue btn_sm btn_file mt-2"
      (click)="addProtocol()"
      [title]="'Protocols.List.addNewProtocol' | translate"
    >
      {{ 'Protocols.List.addNewProtocol' | translate }}
    </button>
  </div>
</ng-template>
<!-- #endregion -->

<!-- #region Cells components -->
<protocol-number-cell #protocolNumber></protocol-number-cell>
<protocol-client #protocolClient></protocol-client>
<protocol-type-cell #protocolType></protocol-type-cell>
<protocol-creator-cell #protocolCreator></protocol-creator-cell>
<protocol-status-cell #protocolStatus></protocol-status-cell>
<protocol-stuffs-cell #protocolStuffs></protocol-stuffs-cell>
<protocol-address-cell #protocolAddress></protocol-address-cell>
<protocol-project-name-cell #protocolProjectName></protocol-project-name-cell>
<!-- #endregion -->
