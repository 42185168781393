import { CalendarComponent } from '@modules/calendar/pages/calendar/calendar.component';
import { CustomRoutes } from '@shared/consts/routes/custom-routes.const';

export const CalendarRoutes: CustomRoutes = [
  {
    path: '',
    component: CalendarComponent,
    children: [],
    name: 'calendar',
    data: {
      metaTitle: 'Calendar.calendar',
      notRestorationPosition: true,
    },
  },
];
