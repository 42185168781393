<side-navigation-view
  [config]="configSideNavigation"
  (onTabClick)="onTabClick($event)"
  #sideNavigationViewComponent>

  <div class="my-work">
    <action-title-header
      [title]="'MyWork.title' | translate"
      [buttonText]="'MyWork.newTask' | translate"
      (onAction)="createNewTask()"
      [stickyWithSideNavigation]="true"
      [permissionDecorator]="{ group: PermissionsGroups.TASKS, action: 'ADD' }">
    </action-title-header>


    <!-- #region Content -->
    <div class="my-work__content">
      <div class="my-work__content__tasks">
        <ng-select
          #selectTab
          ngSelectExtension
          class="select select-default my-work__content__select-tabs"
          [dropdownPosition]="'bottom'"
          [items]="tabs"
          [clearable]="false"
          [searchable]="false"
          [bindLabel]="'text'"
          [bindValue]="'id'"
          [placeholder]="'Projects.tabs' | translate"
          (change)="onTabClick($event)"
          [tabIndex]="-1"
        >
        </ng-select>
        <list-tasks
          #listTasksComponent
          [filters]="MyWorkFiltersComponent"
          [isMyWork]="true"
          [config]="config"
        ></list-tasks>
      </div>
    </div>
    <!-- #endregion -->

  </div>


</side-navigation-view>
