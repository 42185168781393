<div class="d-flex align-content-start align-md-content-center flex-column flex-md-row flex-wrap">
  <list-single-filter-select
    class="w-100 w-sm-auto"
    [name]="'ProjectChanges.List.creator' | translate"
    [iconUrl]="'/assets/img/ic-person-circle-outline.svg'"
    [form]="service?.filtersForm"
    [controlName]="'[a-creator][eq]'"
    (change)="onChangeFilter()"
    [multiple]="false"
    [searchGetParams]="'search'"
    [requiredIdUrl]="Config.API + '/employee/search'"
    [valueTransform]="creatorName.bind(this)"
  ></list-single-filter-select>

  <list-single-filter-select
    class="w-100 w-sm-auto"
    [name]="'ProjectChanges.List.status' | translate"
    [iconUrl]="'/assets/img/ic-checkmark-circle-outline.svg'"
    [form]="service?.filtersForm"
    [bindValue]="'id'"
    [bindLabel]="'name'"
    [controlName]="'[a-changeStatus][eq]'"
    (change)="onChangeFilter()"
    [localItems]="statuses"
  ></list-single-filter-select>

  <list-single-filter-select
    class="w-100 w-sm-auto"
    [name]="'ProjectChanges.List.source' | translate"
    [iconUrl]="'/assets/img/ic-check-square.svg'"
    [form]="service?.filtersForm"
    [bindValue]="'id'"
    [bindLabel]="'name'"
    [controlName]="'[a-source][eq]'"
    (change)="onChangeFilter()"
    [localItems]="sources"
  ></list-single-filter-select>

  <list-single-filter-select
    class="w-100 w-sm-auto"
    [name]="'ProjectChanges.List.type' | translate"
    [iconUrl]="'/assets/img/ic-check-square.svg'"
    [form]="service?.filtersForm"
    [bindValue]="'id'"
    [bindLabel]="'name'"
    [control]="typeControl"
    (change)="onTypeChange()"
    [localItems]="types"
  ></list-single-filter-select>

  <list-single-filter-select
    class="w-100 w-sm-auto"
    [name]="'ProjectChanges.List.settlementStatus' | translate"
    [iconUrl]="'/assets/img/ic-checkmark-circle-outline.svg'"
    [form]="service?.filtersForm"
    [bindValue]="'id'"
    [bindLabel]="'name'"
    [controlName]="'[a-settlementStatus][eq]'"
    (change)="onChangeFilter()"
    [localItems]="settlements"
  ></list-single-filter-select>

  <list-single-filter-range-date
    #dateRanePickerFilter
    class="w-100 w-sm-auto"
    [name]="'ProjectChanges.List.dateRange' | translate"
    [iconUrl]="'/assets/img/ic-calendar.svg'"
    [form]="service?.filtersForm"
    [fromControlName]="'[a-termStart][gte]'"
    [toControlName]="'[a-termEnd][lte]'"
    (change)="onChangeFilter()"
  >
  </list-single-filter-range-date>

  <list-single-clear-filters
    [form]="service?.filtersForm"
    (change)="onChangeFilter()"
    [shouldShowClearFilters]="shouldShowClearFilters.bind(this)"
    [clearFilters]="clearFilters.bind(this)"
  >
  </list-single-clear-filters>
</div>
