<list-filter
  [component]="filters"
  *ngIf="filters"
  [searchName]="searchName"
  [isSearch]="isSearch"
  [filtersTourAnchor]="filtersTourAnchor"
  #filtersComponent
></list-filter>

<ng-container *ngIf="config?.summaryComponent">
  <ng-container *ngComponentOutlet="config?.summaryComponent"></ng-container>
</ng-container>

<list-header
  *ngIf="service?.config?.listTitle"
  [headerTemplate]="headerTemplate"
  [title]="service?.config?.listTitle"
></list-header>

<div class="datatable-container" #listContainer>
  <list-loader></list-loader>
  <list-empty-states [emptyTemplate]="emptyTemplate"></list-empty-states>
  <ngx-datatable
    #table
    [tourAnchor]="listTourAnchor ? listTourAnchor : ''"
    [rows]="service.rows"
    [limit]="service?.config?.limit"
    [columns]="service?.config?.columns"
    [columnMode]="service?.config?.columnMode"
    [offset]="service.config?.offset"
    [rowHeight]="service.config?.rowHeight ? service.config?.rowHeight : 60"
    [headerHeight]="34"
    [footerHeight]="40"
    [sortType]="service.config?.sortType"
    [scrollbarV]="true"
    [scrollbarH]="true"
    [trackByProp]="service?.config?.trackByProp"
    [virtualization]="service?.config?.virtualization"
    [externalPaging]="true"
    [externalSorting]="true"
    [messages]="{ emptyMessage: '' }"
    [selected]="selected"
    [selectionType]="service.config?.selectionType"
    [reorderable]="false"
    [swapColumns]="false"
    [rowClass]="config?.getRowClass"
    (sort)="onSort($event)"
    (select)="select.emit($event)"
    (activate)="onActivate($event)"
    (treeAction)="onTreeAction($event)"
    [selectCheck]="singleSelectCheck.bind(this)"
    [treeFromRelation]="service.config?.treeAction?.treeFromRelation"
    [treeToRelation]="service.config?.treeAction?.treeToRelation"
  >
    <ngx-datatable-footer>
      <ng-template ngx-datatable-footer-template *ngIf="service?.rows?.length || this.service?.loading">
        <app-list-footer class="w-100"></app-list-footer>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>

  <ng-template
    let-column="column"
    let-row="row"
    #checkbox
    ngx-datatable-cell-template
    let-rowIndex="rowIndex"
  >
    <list-checkbox [row]="row"></list-checkbox>
  </ng-template>

  <ng-template
    let-column="column"
    let-row="row"
    #checkboxAll
    ngx-datatable-header-template
    let-rowIndex="rowIndex"
  >
    <list-checkbox [row]="row"></list-checkbox>
  </ng-template>

  <ng-template
    let-column="column"
    let-row="row"
    #toggleMenu
    ngx-datatable-cell-template
    let-rowIndex="rowIndex"
  >
    <app-list-toggle-menu
      class="d-block"
      [component]="column?.component"
      [row]="row"
      [showToggleMenu]="showToggleMenu"
      [tourAnchorName]="toggleMenuTourAnchor + rowIndex"
    ></app-list-toggle-menu>
  </ng-template>

  <ng-template let-cellContext="cellContext" #toggleTree ngx-datatable-tree-toggle>
    <list-tree-action
      [row]="cellContext"
      [treeDisabled]="treeDisabled.bind(this)"
      [tourAnchorName]="treeActionTourAnchor + cellContext.rowIndex"
    ></list-tree-action>
  </ng-template>
</div>
