import { TaskType } from '@shared/enums/task-type.enum';
import { SubtasksService } from './../../subtasks.service';
import { ButtonTypes, ButtonSize } from '@shared/modules/ui/components/button/button.component';
import { ChangeDetectionStrategy, Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { Task } from '@shared/models/task.model';
import { sortListByGroup } from '@shared/helpers/list-sort.helper';

@Component({
  selector: 'subtasks-manager',
  templateUrl: './subtasks-manager.component.html',
  styleUrls: ['./subtasks-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubtasksManagerComponent implements OnInit {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;

  @Input()
  set disabled(disabled: boolean) {
    this.service.disabled = disabled;
    this.changes.detectChanges();
  }

  get disabled(): boolean {
    return this.service.disabled;
  }

  @Input()
  set parentId(parentId: number) {
    this.service.parentId = parentId;
    this.changes.detectChanges();
  }

  get parentId(): number {
    return this.service.parentId;
  }

  @Input()
  set parentTask(task: Task) {
    this.service.parentTask = task;
    this.changes.detectChanges();
  }

  get parentTask(): Task {
    return this.service.parentTask;
  }

  @Input()
  set type(type: TaskType) {
    this.service.type = type;
    this.changes.detectChanges();
  }

  get type(): TaskType {
    return this.service.type;
  }

  @Input()
  set taskList(taskList: Task[]) {
    this.service.taskList = taskList ? [...taskList.filter((i) => i.type !== TaskType.TYPE_CHANGE)] : [];
    this.service.taskList = sortListByGroup(this.service.taskList);
    this.changes.detectChanges();
  }

  get taskList(): Task[] {
    return this.service.taskList;
  }

  addingTask: boolean = false;

  constructor(private changes: ChangeDetectorRef, public service: SubtasksService) {}

  ngOnInit() {}

  addTask() {
    this.addingTask = true;
    this.changes.detectChanges();
  }

  discardAdding() {
    this.addingTask = false;
    this.changes.detectChanges();
  }
}
