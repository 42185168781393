import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { NavigationEnd, ActivatedRoute, ActivationEnd, RoutesRecognized } from '@angular/router';
import { TabLink } from '@shared/interfaces/menu/tab-link.interface';
import { CompanySettingsGuard } from '@core/guards/settings/company-settings.quard';
import { CompanyTeamGuard } from '@core/guards/settings/company-team.guard';
import { ButtonSize, ButtonTypes } from '@shared/modules/ui/components/button/button.component';
import {
  StickyFooterEvent,
  StickyFooterEventType,
  StickyFooterService,
} from '@shared/services/sticky-footer.service';
import { SettingsService } from '@modules/settings/shared/services/settings.service';

@Component({
  selector: 'app-settings-panel',
  templateUrl: './settings-panel.component.html',
  styleUrls: ['./settings-panel.component.scss'],
})
export class SettingsPanelComponent extends BaseComponent implements OnInit, OnDestroy {
  ButtonTypes = ButtonTypes;
  ButtonSize = ButtonSize;
  bottomBarVisible: boolean = false;

  showProgressBar: boolean = true;
  tabLinks: TabLink[] = [];

  subSaving: Subscription;

  constructor(
    private active: ActivatedRoute,
    private ref: ChangeDetectorRef,
    private service: SettingsService,
    public location: Location,
    private footerService: StickyFooterService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.shouldShowProgressBar();
    this.initTabLinks();
    this.subscribeSaving();
  }

  startSaving() {
    this.footerService.emitter.emit({ type: StickyFooterEventType.SUBMITTED });
  }

  subscribeSaving() {
    this.subSaving = this.footerService.emitter.subscribe((event: StickyFooterEvent) => {
      switch (event.type) {
        case StickyFooterEventType.START_SAVING:
          this.loading = true;
          break;
        case StickyFooterEventType.END_SAVING:
          this.loading = false;
          break;
        default:
          break;
      }
    });
  }

  shouldShowProgressBar() {
    this.checkRouteForProgressBar();
    this.setBottomBarVisibility();
    if (event instanceof NavigationEnd) {
      this.checkRouteForProgressBar();
    }
    this.sub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setBottomBarVisibility();
      }
    });
  }

  setBottomBarVisibility() {
    this.bottomBarVisible = !!this.active?.snapshot?.firstChild?.data?.bottomBar;
  }

  checkRouteForProgressBar() {
    this.active.firstChild.snapshot.data['hideProgressBar']
      ? (this.showProgressBar = false)
      : (this.showProgressBar = true);
  }

  initTabLinks() {
    const companySettingsGuard = new CompanySettingsGuard(this.store, this.n, this.s, this.t);
    const companyTeamGuard = new CompanyTeamGuard(this.store, this.n, this.s, this.t);

    this.tabLinks.push({
      name: this.t.instant('Settings.Menu.user'),
      link: this.n.getPath('user-settings'),
    });

    if (companySettingsGuard.canActivate()) {
      this.tabLinks.push({
        name: this.t.instant('Settings.Menu.company'),
        link: this.n.getPath('company-settings'),
      });
    }

    if (companyTeamGuard.canActivate()) {
      this.tabLinks.push({
        name: this.t.instant('Settings.Menu.team'),
        link: this.n.getPath('team-settings'),
      });
    }
  }

  ngOnDestroy() {
    this.sub ? this.sub.unsubscribe() : null;
    this.subSaving ? this.subSaving.unsubscribe() : null;
  }
}
