import { Component, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '@core/services/snackbar.service';
import { AuthFilePipe } from '@shared/pipes/authFile.pipe';
import { BaseAttachmentComponent } from '@shared/modules/attachments/components/base-attachment/base-attachment.component';

@Component({
  selector: 'text-attachments-input',
  templateUrl: './text-attachments-input.component.html',
})
export class TextAttachmentsInputComponent extends BaseAttachmentComponent {
  constructor(
    public translate: TranslateService,
    public changes: ChangeDetectorRef,
    public s: SnackBarService,
    public authFilePipe: AuthFilePipe,
  ) {
    super(translate, changes, s, authFilePipe);
  }
}
