import { FormGroup } from '@angular/forms';

export abstract class BaseEntryModalComponent {
  form: FormGroup;

  constructor() {}
  abstract setFormFields(fields: any);
  abstract submit();

  abstract get formData();
}
