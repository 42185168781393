import { ProjectEmailModule } from './../../../modules/projects/modules/project-email/project-email.module';
import { CustomRoutes } from '@shared/consts/routes/custom-routes.const';
import { ProjectPanelPreviewComponent } from '@project-modules/project-preview/components/project-panel-preview/project-panel-preview.component';
import { ProjectPreviewResolver } from '@project-modules/project-preview/components/project-preview.resolver';

export const ProjectPreviewRoutes: CustomRoutes = [
  {
    path: ':projectId',
    component: ProjectPanelPreviewComponent,
    name: 'project-preview',
    resolve: {
      project: ProjectPreviewResolver,
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'summary',
      },
      {
        path: 'summary',
        name: 'project-preview-summary',
        data: {
          metaTitle: 'Projects.summary',
        },
        canActivate: [],
        loadChildren: () =>
          import(`@project-modules/project-summary/project-summary.module`).then(
            (m) => m.ProjectSummaryModule,
          ),
      },
      {
        path: 'calendar',
        name: 'project-preview-calendar',
        data: {
          metaTitle: 'Projects.summary',
        },
        canActivate: [],
        loadChildren: () =>
          import(`@project-modules/project-calendar/project-calendar.module`).then(
            (m) => m.ProjectCalendarModule,
          ),
      },
      {
        path: 'mailings',
        name: 'project-preview-email',
        data: {
          metaTitle: 'Projects.summary',
        },
        canActivate: [],
        loadChildren: () =>
          import(`@project-modules/project-email/project-email.module`).then((m) => m.ProjectEmailModule),
      },
      {
        path: 'tasks',
        name: 'project-preview-tasks',
        data: {
          metaTitle: 'Projects.tasks',
          notRestorationPosition: true,
        },
        canActivate: [],
        loadChildren: () =>
          import(`@project-modules/project-tasks/project-tasks.module`).then((m) => m.ProjectTasksModule),
      },
      {
        path: 'team',
        name: 'project-preview-team',
        data: {
          metaTitle: 'Projects.team',
          notRestorationPosition: false,
        },
        canActivate: [],
        loadChildren: () =>
          import(`@project-modules/project-team/project-team.module`).then((m) => m.ProjectTeamModule),
      },
      {
        path: 'contacts',
        name: 'project-preview-contacts',
        data: {
          metaTitle: 'Projects.contacts',
          notRestorationPosition: false,
        },
        canActivate: [],
        loadChildren: () =>
          import(`@project-modules/project-contacts/project-contacts.module`).then(
            (m) => m.ProjectContactsModule,
          ),
      },
      {
        path: 'configurator',
        name: 'project-configurator',
        data: {
          metaTitle: 'Projects.projectConfigurator',
          notRestorationPosition: true,
        },
        canActivate: [],
        loadChildren: () =>
          import(`@project-modules/projects-configurator/projects-configurator.module`).then(
            (m) => m.ProjectsConfiguratorModule,
          ),
      },
      {
        path: 'changes',
        name: 'project-preview-changes',
        data: {
          metaTitle: 'Projects.changes',
          notRestorationPosition: false,
        },
        canActivate: [],
        loadChildren: () =>
          import(`@project-modules/project-changes/project-changes.module`).then(
            (m) => m.ProjectChangesModule,
          ),
      },
      {
        path: 'protocols',
        name: 'project-preview-protocols',
        data: {
          metaTitle: 'Projects.protocols',
        },
        canActivate: [],
        loadChildren: () =>
          import(`@project-modules/project-protocols/project-protocols.module`).then(
            (m) => m.ProjectProtocolsModule,
          ),
      },
    ],
  },
];
