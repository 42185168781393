import { MediaQuery } from '@shared/configs/media-query';

export class WindowHelper {
  // #region Mobile verify
  static get isMobileWidth() {
    return window.innerWidth <= MediaQuery.SM;
  }

  static get isLessMobileWidth() {
    return window.innerWidth < MediaQuery.SM;
  }

  static get isMoreMobileWidth() {
    return window.innerWidth >= MediaQuery.SM;
  }

  static get isLargeWidth() {
    return window.innerWidth > MediaQuery.SM;
  }
  //#endregion

  // #region Tablet verify
  static get isLessMD() {
    return window.innerWidth < MediaQuery.MD;
  }

  static get isMoreLessMD() {
    return window.innerWidth <= MediaQuery.MD;
  }

  static get isLargeMD() {
    return window.innerWidth > MediaQuery.MD;
  }
  //#endregion

  // #region Laptop verify
  static get isLessXL() {
    return window.innerWidth < MediaQuery.XL;
  }
  //#endregion
}
