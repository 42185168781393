export enum EVENT_SIDENAV_FORM {
  projectId = 'projectId',
  name = 'name',
  isFullDay = 'isFullDay',
  reminderTypes = 'reminderTypes',
  cycleType = 'cycleType',
  emails = 'emails',
  description = 'description',
  address = 'address',
  termStart = 'termStart',
  termEnd = 'termEnd',
  contactsIds = 'contactsIds',

  helperStartDate = 'helperStartDate',
  helperEndDate = 'helperEndDate',
  helperStartTime = 'helperStartTime',
  helperEndTime = 'helperEndTime',
}
