<div class="d-flex align-content-start align-md-content-center flex-column flex-md-row flex-wrap">
  <list-single-filter-select
    class="w-100 w-sm-auto"
    [name]="'MyWork.Filters.createdBy' | translate"
    [iconUrl]="'/assets/img/ic-person-circle-outline.svg'"
    [form]="service?.filtersForm"
    [controlName]="'[creator][eq]'"
    (change)="onChangeFilter()"
    [searchGetParams]="'search'"
    [requiredIdUrl]="Config.API + '/employee/search'"
    [valueTransform]="creatorName.bind(this)"
  ></list-single-filter-select>

  <list-single-filter-select
    class="w-100 w-sm-auto"
    [name]="'MyWork.Filters.status' | translate"
    [iconUrl]="'/assets/img/ic-checkmark-circle-outline.svg'"
    [form]="service?.filtersForm"
    [localItems]="taskStatuses"
    [multiple]="true"
    [bindValue]="'id'"
    [bindLabel]="'name'"
    [controlName]="'[a-status][in][]'"
    (change)="onChangeFilter()"
  ></list-single-filter-select>

  <list-single-filter-select
    class="w-100 w-sm-auto"
    [name]="'MyWork.Filters.type' | translate"
    [iconUrl]="'/assets/img/ic-checkmark-circle-outline.svg'"
    [form]="service?.filtersForm"
    [localItems]="taskTypes"
    [bindValue]="'id'"
    [bindLabel]="'name'"
    [controlName]="'[a-type][eq]'"
    (change)="onChangeFilter()"
  ></list-single-filter-select>

  <list-single-filter-select
    class="w-100 w-sm-auto"
    [name]="'MyWork.Filters.projects' | translate"
    [iconUrl]="'/assets/img/ic-projects-outline.svg'"
    [form]="service?.filtersForm"
    [controlName]="'[a-project][in][]'"
    [multiple]="true"
    [valueTransform]="projectTransform.bind(this)"
    (change)="onChangeFilter()"
    [requiredIdUrl]="Config.API + '/project/search'"
  ></list-single-filter-select>

  <list-single-filter-select
    #tagFilter
    class="w-100 w-sm-auto"
    [name]="'MyWork.Filters.tags' | translate"
    [iconUrl]="'/assets/img/ic-tag.svg'"
    [form]="service?.filtersForm"
    [controlName]="'[tag-name][in][]'"
    [multiple]="true"
    (change)="onChangeFilter()"
    [bindValue]="'id'"
    [bindLabel]="'name'"
    [searchGetParams]="'query'"
    [requiredIdUrl]="Config.API + '/tag/type?type=task-tag&myTasks=1'"
  ></list-single-filter-select>

  <list-single-filter-checkbox
    [name]="'MyWork.Filters.showCompleted' | translate"
    [form]="service?.filtersForm"
    [controlName]="'[show_completed]'"
    (change)="onChangeFilter()"
  ></list-single-filter-checkbox>

  <list-single-filter-checkbox
    *ngIf="!isDelegated"
    [name]="'MyWork.Filters.showUnassigned' | translate"
    [form]="service?.filtersForm"
    [controlName]="'[showUnassigned]'"
    (change)="onChangeFilter()"
  ></list-single-filter-checkbox>

  <list-single-clear-filters
    [form]="service?.filtersForm"
    (change)="onChangeFilter()"
    [shouldShowClearFilters]="shouldShowClearFilters.bind(this)"
    [clearFilters]="clearFilters.bind(this)">
  </list-single-clear-filters>
</div>
